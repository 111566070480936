import * as R from 'ramda';

import { IElasticResult, ISearchRecord } from '../../types/search';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import React, { Fragment, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Spinner, Tab, Tabs } from 'react-bootstrap';

import AirEventTable from './map/AirEventTable';
import AirDeviationTable from './map/AirDeviationTable';
import { CeresLogger } from '../../logger';
import { Col } from 'react-bootstrap';
import { IAuthContext } from '../../context/auth/auth-context';
import { Scrollbars } from 'react-custom-scrollbars';
import { TIERS } from '../../types/tiers';
import TreeView from './TreeViewData';
import axios from 'axios';
import { searchAIEarth } from '../../services/firebase';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import withAuthContext from '../../context/auth/AuthConsumer';
import { API_BASE_URL, API_AUTH_HEADERS } from '../../util/api.util';

interface IRecordMarkerProps {
  record: ISearchRecord;
  context: IAuthContext;
  doStorageDownload: any;
  getStorageRef: any;
  downloadTriggers: any;
  setDownloadTriggers: any;
  showProgramQueryPanel: any;
  activeTabDrawer: any;
  searchTerm: string;
}

const EntityRecordListContainer = styled.div`
  // height: 160px;
  // overflow-y: scroll;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em 0em;
`;

const OLContainer = styled.ol`
  padding-inline-start: 10px !important;
  font-size: 0.6rem;
  padding-top: 7px;
  margin-bottom: 0px;
`;

const AddressText = styled.span`
  font-size: 0.6rem;
`;

const CustomTabs = styled(Tabs)`
  border-bottom: 1px solid #005fbc !important;
  font-size: 14px !important;
`;

const SpaceEvenly = styled.div`
  display: flex;
  justify-content: space-between;
`;

const getTCEQRecordUrl = (entityNumber: string) =>
  `https://www15.tceq.texas.gov/crpub/index.cfm?fuseaction=regent.validateRE&re_ref_num_txt=${entityNumber}`;

const getEPARecordUrl = (entityNumber: string) =>
  `https://ofmpub.epa.gov/frs_public2/fii_map_master.fii_retrieve?fac_search=facility_uin&fac_value=${entityNumber}&fac_search_type=Equal+To&postal_code=&location_address=&add_search_type=B&city_name=&county_name=&state_code=&epa_region_code=&cong_dist=&legis_dist=&huc_code=&fed_agency=&TribalLand=0&selectTribe=noselect&sic_type=Equal+to&sic_code_to=&naic_type=Equal+to&naic_to=&org_name=&duns_num=&contact_name=&prog_search=&int_search=&search_type=&search_type=all&all_programs=YES&sysname=&page_no=1&output_sql_switch=TRUE&report=1&database_type=FII&tribal_ind=&last_facility=&univ_search=&fac_search_term=&tribetype=&triballand=&selecttribe=&tribedistance1=`;

const fetchRecordsByRN = (rnNumber: string, searchTerm: string, searchType: string) => {
  return searchAIEarth({
    query: {
      from: 0,
      size: 50,
      sortByReleaseDate: true,
      text: searchTerm,
      type: searchType,
      terms: {
        regulated_entity_number: rnNumber,
      },
    },
  });
};

const EPALink = styled.span`
  color: #005fbc;
  cursor: pointer;
`;
const DownloadHyperlink = styled.a`
  color: #005fbc;
  cursor: pointer;
  &:hover {
    color: #ac5e08 !important;
    text-decoration: underline !important;
  }
`;

const RecordMarkerContainer = styled.div``;

/**
 * check if the file needs to be downloaded from storage bucket
 * or rely on the file url, directly.
 */

const RecordMarkerInfoWindow: React.FC<{
  routerProps: RouteComponentProps<any>;
  record: ISearchRecord;
  isLoading: boolean;
  relatedRecords: ISearchRecord[];
  authContext: IAuthContext;
  doStorageDownload: any;
  getStorageRef: any;
  downloadTriggers: any;
  setDownloadTriggers: any;
  details: any;
  airAiResults: any;
  totalEvents: any;
  isLoadingAirEmission: any;
  activeTabDrawer: any;
  deviations: any;
  setDeviations: any;
}> = (props) => {
  const authContext = props.authContext;
  const record = props.record;
  const documentsTabTitle = `Documents(${props.relatedRecords.length})`;
  const renderSpinner = () => (
    <SpinnerContainer>
      <Spinner animation="border" variant="primary" />
    </SpinnerContainer>
  );
  const getRecordTitle = (item: ISearchRecord) => `${item.title} - ${item.seriesName} - ${item.releaseDate}`;
  const renderResult = () => {
    return (
      <EntityRecordListContainer>
        <AddressText>{record.address}</AddressText>
        <h6>
          <SpaceEvenly>
            {['TCEQ', 'tceq_initial', 'tceq_novell'].includes(record.entitySource) && (
              <a target="_blank" rel="noopener noreferrer" href={getTCEQRecordUrl(record.entityNumber)}>
                {record.entityNumber}
              </a>
            )}
            {record.entitySource === 'EPA' && (
              <a target="_blank" rel="noopener noreferrer" href={getEPARecordUrl(record.entityNumber)}>
                {record.entityNumber}
              </a>
            )}
            {record.entitySource === 'TCEQ-EPA' && (
              <a target="_blank" rel="noopener noreferrer" href={getTCEQRecordUrl(record.entityNumber)}>
                {record.entityNumber}
              </a>
            )}
            {record.secondaryId && (
              <OverlayTrigger
                trigger="click"
                rootClose
                placement="bottom"
                overlay={
                  <Popover id="popover">
                    <Popover.Title>EPA IDs</Popover.Title>
                    <Popover.Content>
                      {record.secondaryId.split(',').map((epaId: any) => {
                        if (epaId.startsWith('11') && epaId.length === 12) {
                          return (
                            <p>
                              <a target="_blank" rel="noopener noreferrer" href={getEPARecordUrl(epaId)}>
                                {epaId}
                              </a>
                            </p>
                          );
                        }
                        return '';
                      })}
                    </Popover.Content>
                  </Popover>
                }
              >
                <EPALink>EPA IDs</EPALink>
              </OverlayTrigger>
            )}
          </SpaceEvenly>
        </h6>
        <CustomTabs defaultActiveKey={props.activeTabDrawer} id="uncontrolled-tab-example">
          <Tab eventKey="documents" title={documentsTabTitle}>
            <OLContainer>
              <Scrollbars
                style={{
                  height: 'calc(100vh - 305px)',
                }}
              >
                {R.compose(
                  R.map((relatedRecord: ISearchRecord) => {
                    return (
                      <li
                        style={{
                          color: relatedRecord.containsSearchTerm ? 'green' : 'none',
                        }}
                        key={relatedRecord.contentId}
                      >
                        {authContext.isTier(TIERS.FREE) && !authContext.isLubrizol() ? (
                          getRecordTitle(relatedRecord)
                        ) : (
                          <DownloadHyperlink
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (props.doStorageDownload(relatedRecord.entitySource)) {
                                const storageRef = props.getStorageRef(relatedRecord.entitySource);
                                if (R.isNil(storageRef)) {
                                  return toast.error(`Oops, could not find the file.`);
                                }
                                props.setDownloadTriggers(R.append(relatedRecord.contentId, props.downloadTriggers));
                                return storageRef
                                  .ref(relatedRecord.fileName)
                                  .getDownloadURL()
                                  .then((url: string | undefined) => window.open(url, '_blank', 'noopener,noreferrer'))
                                  .catch((err: any) => toast.error(err.message))
                                  .finally(() =>
                                    props.setDownloadTriggers(
                                      R.without([relatedRecord.contentId], props.downloadTriggers)
                                    )
                                  );
                              }
                              if (!relatedRecord.fileUrl) {
                                return toast.warn(
                                  <span>
                                    No associated file was found for the content id <b>{relatedRecord.contentId}</b>
                                  </span>
                                );
                              }
                              window.open(relatedRecord.fileUrl, '_blank');
                              // props.setRelatedDocument(relatedRecord);
                            }}
                          >
                            {getRecordTitle(relatedRecord)}
                          </DownloadHyperlink>
                        )}
                      </li>
                    );
                  }),
                  R.sort(
                    R.comparator(
                      (itemOne: ISearchRecord, itemTwo: ISearchRecord) => itemOne.releaseDate > itemTwo.releaseDate
                    )
                  )
                )(props.relatedRecords)}
                {props.relatedRecords.length < 1 ? 'No documents currently available.' : ''}
              </Scrollbars>
            </OLContainer>
          </Tab>
          <Tab eventKey="permits" title={`Permits(${props.details ? Object.keys(props.details).length : ''})`}>
            <TreeView details={props.details} />
          </Tab>
          {props.airAiResults && props.airAiResults.length > 0 && (
            <Tab
              eventKey="emissionEvents"
              title={`Emission Events(${props.totalEvents ? props.airAiResults.length : ''})`}
            >
              {props.totalEvents && (
                <>
                  <Col lg={10} sm={12} style={{ fontSize: '14px' }}>
                    Total events: {props.airAiResults.length} (since 2004) <br />
                    Total released: {props.totalEvents ? props.totalEvents.toFixed(2) : ''} lbs
                  </Col>
                  <AirEventTable events={props.airAiResults} />
                </>
              )}
            </Tab>
          )}
          {props.deviations && props.deviations.length > 0 && (
            <Tab eventKey="" title={`Deviations(${props.deviations ? Object.keys(props.deviations).length : ''})`}>
              <AirDeviationTable events={props.deviations} />
            </Tab>
          )}
        </CustomTabs>
      </EntityRecordListContainer>
    );
  };
  return (
    <Fragment>
      <h6>{record.entityName}</h6>
      {props.isLoading && renderSpinner()}
      {!props.isLoading && renderResult()}
    </Fragment>
  );
};

const AirTabDrawer: React.FC<IRecordMarkerProps & RouteComponentProps<any>> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAirEmission, setIsLoadingAirEmission] = useState(false);
  const [relatedRecords, setRelatedRecords] = useState<ISearchRecord[]>([]);
  const [details, setDetails] = useState();
  const [airAiResults, setAirAiResults] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const [deviations, setDeviations] = useState([]);
  const entityNumber = props.record.entityNumber;
  const authContext = props.context;

  useEffect(() => {
    async function programQueryDetails() {
      const requestBody = { RN: entityNumber };
      try {
        setIsLoading(true);
        axios
          .post(
            API_BASE_URL + '/api/programQueryDetails',
            // 'http://localhost:5000/ceres-platform-test/us-central1/api/document-entity/list',
            requestBody,
            {
              headers: API_AUTH_HEADERS,
            }
          )
          .then((response) => {
            const detailsJson = response.data[0].get_prgm_json_for_entity;
            setDetails(detailsJson);
          })
          .catch((err) => {
            CeresLogger.error(err);
          })
          .finally(() => setIsLoading(false));
      } catch (e) {
        CeresLogger.error(e);
      }
    }

    const fetchAirAIRNStats = (value: any) => {
      try {
        setIsLoadingAirEmission(true);
        axios
          .post(
            API_BASE_URL + '/api/fetchAirAIRNStats',
            // 'http://localhost:5000/ceres-platform-test/us-central1/api/fetchAirAIRNStats',
            { RN: value },
            {
              headers: API_AUTH_HEADERS,
            }
          )
          .then((response) => {
            if (response.data && response.data.length > 0) {
              const emissionEventsJson =
                typeof response.data[0].get_emission_events !== 'undefined'
                  ? response.data[0].get_emission_events
                  : [];
              setAirAiResults(emissionEventsJson);

              const total = emissionEventsJson.reduce((acc: number, item: any) => acc + item.amount, 0);
              setTotalAmount(total);
            }
            setIsLoadingAirEmission(false);
          })
          .catch((err) => {
            CeresLogger.error(err);
          })
          .finally(() => setIsLoadingAirEmission(false));
      } catch (e) {
        CeresLogger.error(e);
      }
    };

    const fetchDeviationRecords = () => {
      const requestBody = { RN: entityNumber };
      try {
        axios
          .post(
            API_BASE_URL + '/api/fetchDeviationRecords',
            // 'http://localhost:5000/ceres-platform-test/us-central1/api/fetchDeviationRecords',
            requestBody,
            {
              headers: API_AUTH_HEADERS,
            }
          )
          .then((response) => {
            const deviationsJson = response.data[0].aai_get_deviations_tab_json;
            setDeviations(deviationsJson);
          })
          .catch((err) => {
            CeresLogger.error(err);
          });
      } catch (e) {
        CeresLogger.error(e);
      }
    };

    async function fetchRecords() {
      let isSubscribed = true;
      const containsSearchTerm = await fetchRecordsByRN(entityNumber, props.searchTerm, 'must');
      const data = containsSearchTerm.data as IElasticResult<ISearchRecord>;
      const records = (data && data.records) || [];
      records.map((rec) => {
        rec.containsSearchTerm = true;
        return rec;
      });
      isSubscribed && setRelatedRecords(records);
      return () => {
        isSubscribed = false;
      };
    }
    if (props.showProgramQueryPanel) {
      programQueryDetails();
      fetchAirAIRNStats(entityNumber);
      fetchRecords();
      fetchDeviationRecords();
    }
    // eslint-disable-next-line
  }, [entityNumber, props.showProgramQueryPanel, setDeviations]);
  return (
    <RecordMarkerContainer>
      {
        <RecordMarkerInfoWindow
          routerProps={props}
          details={details}
          authContext={authContext}
          record={props.record}
          isLoading={isLoading}
          relatedRecords={relatedRecords}
          doStorageDownload={props.doStorageDownload}
          getStorageRef={props.getStorageRef}
          downloadTriggers={props.downloadTriggers}
          setDownloadTriggers={props.setDownloadTriggers}
          airAiResults={airAiResults}
          totalEvents={totalAmount}
          isLoadingAirEmission={isLoadingAirEmission}
          activeTabDrawer={props.activeTabDrawer}
          deviations={deviations}
          setDeviations={setDeviations}
        />
      }
    </RecordMarkerContainer>
  );
};

export default withRouter(withAuthContext(AirTabDrawer));

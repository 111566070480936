import axios from 'axios';
import * as CryptoJS from 'crypto-js';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ICustomSearchRecord, ICustomSearchResult, IElasticResult, ISearchRecord } from '../../types/search';

import { ChevronLeft } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import AdvancedSearchFilter from '../../components/search/AdvancedSearchFilter';
import EnviroSearchFilter from '../../components/search/EnviroSearchFilter';
import { customSearch, iv, search, key as secKey } from '../../services/firebase';
import { CUSTOM_SEARCH_TYPE } from '../../types/search-type';
// import EnviroAILogo from '../../assets/logo.svg';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import EnviroAiTriangle from '../../assets/enviro.svg';
import EnviroAILogo from '../../assets/enviroai_new.svg';
import SidebarOverlay from '../../components/SidebarOverlay';
import GoogleCustomSearch from '../../components/search/GoogleCustomSearch';
import SearchForm from '../../components/search/SearchForm';
import SearchResultContainer from '../../components/search/SearchResultContainer';
import SearchTypePills from '../../components/search/SearchTypePills';
import CustomSearchMapOption from '../../components/search/customType/CustomSearchMapOption';
import withAuthContext from '../../context/auth/AuthConsumer';
import { IAuthContext } from '../../context/auth/auth-context';
import { CeresStripePayments } from '../../services/stripe';
import { updateSearch } from '../../store/aiearth/action';
import { ISearch } from '../../store/aiearth/types';
import { TIERS } from '../../types/tiers';
import mediaQuery from '../../util/media-query';
import HomeTour from './HomeTour';

import 'react-chat-widget/lib/styles.css';
import MainChatBot from './MainChatBot';

declare const window: any;

let timerInterval: any = null;
interface ICeresHome {
  tier: TIERS;
}
interface ISearchType {
  match: any;
}

interface IEPNRecord {
  location: {
    lon: number;
    lat: number;
  };
  marker: string;
  name: string;
  type: string;
  details: string;
}

const HomeContainer = styled.div`
  flex: 1;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const SearchContainerItem = styled.div`
  display: flex;
  justify-content: center;
`;

const CeresLogoContainerItem = styled<any>(SearchContainerItem)`
  flex-direction: column;
  align-items: center;
  gap: 15px;
  min-height: 240px;
  margin-bottom: 0px;

  > p {
    font-family: 'Open Sans';
    font-weight: bold;
    font-size: 20px;
  }
  @media only screen and (max-width: 1400px) {
    min-height: 200px;
  }
  ${mediaQuery.phone`
    min-height: 170px;
  `}
`;

const SearchFormContainerItem = styled<any>(SearchContainerItem)`
  font-weight: 300;
  padding: 0 7rem;
  justify-content: space-between;

  ${mediaQuery.phone`
    padding: 0 1rem;
    flex-wrap: wrap-reverse;
  `}
  ${mediaQuery.tablet`
    padding: 0 1rem;
    flex-wrap: wrap-reverse;
  `}
`;

const SearchContainer = styled.div<ICeresHome>`
  justify-content: center;
  flex-direction: column;
  display: flex;
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: landscape) {
    min-height: 600px;
    justify-content: flex-start;
  }
`;
const SearchContainerMain = styled<any>(SearchContainer) <{ issearch: string }>`
  position: sticky;
  background-color: ${(props) => (props.issearch ? '#fff' : 'transparent')};
  top: 0;
  z-index: 99;
  padding-top: 15px;
`;
const SearchResultMainContainer = styled<any>(SearchContainer)`
  flex: 1;
  z-index: 1;
  background-color: #fff;
`;

const CeresBotHeroImage = styled.img`
  width: 100%;
  margin-top: 45px;
  max-width: 525px;
  @media only screen and (max-width: 1400px) {
    max-width: 35%;
  }
  ${mediaQuery.phone`
    width: auto;
    margin-top: 100px;
    max-width: 90%;
  `}
`;
const EnviroAiTriangleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;

  @media only screen and (max-width: 1400px) {
    max-width: 100%;
  }

  .logo-container {
    width: 8%;
    max-width: 90px;

    ${mediaQuery.phone`
        width: 100%;
    `}

    img {
      max-width: 100%;
    }
  }
`;
const LaunchText = styled.div`
  font-weight: 700;
  color: #5dc5c4;
`;

const ShowLogoArea = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  ${mediaQuery.phone`
    flex-direction: column-reverse;
    flex-grow: 1;
  `}
  ${mediaQuery.tablet`
      flex-direction: column-reverse;
    flex-grow: 1;
  `}
`;
const LogoArea = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  justify-content: space-between;
  ${mediaQuery.phone`
    justify-content: flex-end;
    width: 100%;
  `}
  ${mediaQuery.tablet`
     justify-content: flex-end;
     width: 100%;
  `}
  & img {
    ${mediaQuery.phone`
      width: 85px;
    `}
  }
`;
const Title = styled.img`
  width: 140px;
  margin: 10px 30px 25px;
`;
const BackLink = styled.div`
  font-weight: 700;
  color: #336195;
  padding-left: 25px;
  cursor: pointer;
  margin: -10px 0 0 0;
  ${mediaQuery.phone`
    margin: 30px 0 0;
    padding-left: 0;
  `}
`;
const Extension = styled.div`
  padding-left: 200px;
  padding-bottom: 20px;
  width: max-content;
  @media only screen and (max-width: 1200px) {
    padding-left: 50px;
  }
  @media only screen and (max-width: 991px) {
    display: flex;
    justify-content: right;
    width: 100%;
  }
  @media only screen and (max-width: 575px) {
    display: none;
  }
  & button {
    background-color: #547eaf !important;
    border-radius: 30px;
    font-family: 'Open Sans';
    font-size: 17px;
    letter-spacing: -1px;
    color: white !important;
    transition: all 0.1s ease-in-out;
    & :hover {
      cursor: pointer;
      opacity: 0.6;
      transition: all 0.1s ease-in-out;
    }
  }
  & img {
    height: 32px;
    width: 32px;
    margin: 0px 3px 2px 0px;
  }
`;

const DaypassContainer = styled.div`
  position: fixed;
  z-index: 9999999;
  top: 12%;
  left: 30%;
  @media only screen and (max-width: 1200px) {
    left: 22%;
  }
  @media only screen and (max-width: 1000px) {
    left: 18%;
  }
  @media only screen and (max-width: 900px) {
    left: 16%;
  }
  @media only screen and (max-width: 750px) {
    left: 4%;
  }
  ${mediaQuery.desktop`
    top: 18%;
  `}
  ${mediaQuery.tablet`
    top: 22%;
  `}
  ${mediaQuery.phone`
    top: 30%;
  `}
`;

const Back = styled.div`
  & img {
    position: relative;
    width: 100%;
  }
  & div.pro {
    & img {
      position: absolute;
      top: 60%;
      left: 0px;
      margin-left: 25px;
      height: 17%;
      width: 20%;
      cursor: pointer;
    }
  }
  & div {
    & img {
      position: absolute;
      top: 80%;
      margin-left: 25px;
      left: 0px;
      height: 10%;
      width: 20%;
      cursor: pointer;
    }
  }
`;

const useStyles = makeStyles({
  loaderContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },

});

const applyFilterToQuery = (query: any, filter: any = {}, type: string = '') => {
  const innerQuery = query.query || {};
  let releaseDateFilter = {};

  if (filter.rnNumber) {
    innerQuery.terms = Object.assign(innerQuery.terms || {}, {
      regulated_entity_number: filter.rnNumber,
    });
  }
  if (filter.cityName) {
    innerQuery.terms = Object.assign(innerQuery.terms || {}, {
      city_name: filter.cityName,
    });
  }
  if (filter.category) {
    if (filter.category === 'other') {
      innerQuery.mustNot = {};
      innerQuery.mustNot.matchQuery = Object.assign(
        {},
        {
          record_series_name: 'Air WS WATER WQ WST WASTE',
        }
      );
    } else {
      innerQuery.terms = Object.assign(innerQuery.terms || {}, {
        category_name: filter.category,
      });
      if (filter.recordSeries) {
        innerQuery.terms = Object.assign(innerQuery.terms || {}, {
          record_series_name: filter.recordSeries,
        });
      }
    }
  }
  if (filter.recordTitles) {
    innerQuery.terms = Object.assign(innerQuery.terms || {}, {
      title: filter.recordTitles.label,
    });
  }
  if (filter.entityName) {
    innerQuery.terms = Object.assign(innerQuery.terms || {}, {
      regulated_entity_name: filter.entityName,
    });
  }
  if (filter.zipCode) {
    innerQuery.terms = Object.assign(innerQuery.terms || {}, {
      zip_code: filter.zipCode,
    });
  }
  if (filter.hideEmissionEvents) {
    innerQuery.inclusions = Object.assign(
      {},
      {
        hideEmissionEvents: true,
      }
    );
  }
  if (filter.fromReleaseDate) {
    releaseDateFilter = Object.assign(releaseDateFilter, {
      gte: filter.fromReleaseDate.format('DD/MM/YYYY'),
      key: 'release_date',
    });
  }
  if (filter.toReleaseDate) {
    releaseDateFilter = Object.assign(releaseDateFilter, {
      lte: filter.toReleaseDate.format('DD/MM/YYYY'),
      key: 'release_date',
    });
  }
  if (Object.keys(releaseDateFilter).length > 0) {
    innerQuery.dateFilters = (innerQuery.dateFilters || []).concat([releaseDateFilter]);
  }
  return { query: innerQuery };
};

const useQuery = () => {
  const { search: searchVal } = useLocation();

  return React.useMemo(() => new URLSearchParams(searchVal), [searchVal]);
};

const CeresHome: React.FC<
  ISearchType & {
    context: IAuthContext;
  }
> = (props) => {
  const context = props.context;
  const history = useHistory();
  const queryParam = useQuery();
  const isDirectCustomSearch: any = queryParam.get('directsearch');
  const hideSearchBar: any = queryParam.get('hideSearchBar');
  const [frameSearchQueryText, setFrameSearchQueryText] = useState(
    props.match.params.query ? props.match.params.query : queryParam.get('q')
  );
  const [searchQueryText, setSearchQueryText] = useState(
    props.match.params.query ? props.match.params.query : queryParam.get('q')
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isChrome, setIsChrome] = useState(false);
  const [isExtensionInstalled, setExtensionInstalled] = useState(false);
  // const [dayPassDialog, setDayPassDialog] = useState(true);
  const [isDayPassLoading, setIsDayPassLoading] = useState(false);
  const [quickSerch, setQuickSerch] = useState<string>('');
  const [isQuickFilter, setQuickFilter] = useState(false);
  const [docSearchEPNResult, setDocSearchEPNResult] = useState<IEPNRecord[]>([]);
  const [filters, setFilter] = useState<any>();
  let filter = filters;
  const [isTourOpen, setTourOpen] = useState(localStorage.getItem('walkthrough') === 'true' ? true : false);
  localStorage.setItem('walkthrough', 'false');
  const [searchResult, setSearchResult] = useState<IElasticResult<ISearchRecord> | null>(null);
  const [customSearchResult, setCustomSearchResult] = useState<ICustomSearchResult<ICustomSearchRecord> | null>(null);
  const [searchType, setSearchType] = useState<CUSTOM_SEARCH_TYPE>(
    queryParam.get('type')
      ? (queryParam.get('type') as CUSTOM_SEARCH_TYPE)
      : context.isAuthenticated
        ? CUSTOM_SEARCH_TYPE.DEFAULT
        : CUSTOM_SEARCH_TYPE.ALL
  );
  const [sourceType, setSourceType] = useState('');
  const [customSearchType, setCustomSearchType] = useState('google');

  const routeChange = () => {
    setCustomSearchType('google');
    setSearchType(CUSTOM_SEARCH_TYPE.INTERNET);
    handleSearchTypeChange(CUSTOM_SEARCH_TYPE.INTERNET, '', 1, sort);
    // setpopup(false);
  };

  React.useEffect(() => {
    const userAgent = navigator.userAgent;
    const isChromeCheck = userAgent.match(/chrome|chromium|crios/i);
    if (isChromeCheck) {
      setIsChrome(true);
      if (window && window.chrome && window.chrome.runtime) {
        window.chrome.runtime.sendMessage(process.env.REACT_APP_CHROME_EXTENSION_ID, { message: 'version' },
          // tslint:disable-next-line:only-arrow-functions
          function(reply: any) {
            // tslint:disable-next-line:no-console
            if (reply && reply.version) {
              setExtensionInstalled(true);
            }
          });
      }
    }
  }, []);

  React.useEffect(() => {
    if (queryParam.get('q')) {
      setSearchQueryText(queryParam.get('q'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const warnAndRedirectToLogin = () => {
    toast.warn(`Please login to purchase any subscription.`);
    return history.push('/login');
  };

  React.useEffect(() => {
    if (queryParam.get('q')) {
      setSearchQueryText(queryParam.get('q'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (queryParam.get('q')) {
      setSearchQueryText(queryParam.get('q'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParam.get('q')]);

  // Set logic for savedChat agency button behaviour
  useEffect(() => {
    const setData = async () => {
      if (searchInputRef.current !== null) {
        searchInputRef.current.focus();
      }
      if (aiToCeresRN) {
        if (searchInputRef.current !== null) {
          searchInputRef.current.value = aiToCeresTerm || '';
        }
        if (searchButton && searchButton.current) {
          searchButton.current.click();
        }
        localStorage.removeItem('RnNumber');
      }
      const param = window.location.pathname;
      const index = param.indexOf('=') + 1;
      const queryString = param.substring(index);
      if (index > 1 && searchButton.current && searchInputRef.current) {
        searchInputRef.current.value = queryString;
        searchButton.current.click();
      }
      if (searchButton.current && searchQueryText) {
        searchButton.current.click();
      }
      // setIsChatFullScreen(false);
    };
    if(!!store.globalFlags.previousPage && (store.globalFlags.previousPage === 'myenviroaiToAgency' || store.globalFlags.previousPage === 'myenviroaiToInternetResults')) {
      setData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQueryText]);

  const aiToCeresRN = localStorage.getItem('RnNumber');
  const aiToCeresTerm = localStorage.getItem('searchTerm');
  const checkFilterStatus = () => {
    if (aiToCeresRN) {
      return true;
    }
    return false;
  };
  const [showFilter, setShowFilter] = useState(checkFilterStatus());
  const [showEnviroFilter, setShowEnviroFilter] = useState(false);
  const [currentQueryText, setCurrentQueryText] = useState<string>(
    (queryParam.get('q') ? queryParam.get('q') : '') as string
  );
  const searchInputRef = useRef<HTMLInputElement>(null);
  const rnInputRef = useRef<HTMLInputElement>(null);
  const applyButtonRef = React.createRef<HTMLElement>();
  const searchButton = React.createRef<HTMLElement>();

  const [sort, setSort] = useState('');
  const dispatch = useDispatch();
  const store: any = useSelector<any>((state): any => state);
  const searchState: ISearch = store.search;
  const [totalVisitor, setTotalVisitor] = useState(0);
  const [myTotalVisitor, setMyTotalVisitor] = useState(0);
  // let gramPerSecond = 0;

  const startVistorTimer = () => {
    if (timerInterval) {
      clearInterval(timerInterval);
    }
    timerInterval = setInterval(() => {
      // if(gramPerSecond) {
      //   setTotalVisitor(t => +t + +gramPerSecond);
      // }
      setTotalVisitor((t) => +t + 1);
    }, 1500);
  };

  useEffect(() => {
    if (queryParam.get('q') === null) {
      setCurrentQueryText('');
      setSearchQueryText('');
      // setCurrentQuestion('');
      setSearchResult(null);
      setCustomSearchResult(null);
    }
  }, [queryParam]);

  useEffect(() => {
    const type = queryParam.get('type') ? (queryParam.get('type') as CUSTOM_SEARCH_TYPE) : CUSTOM_SEARCH_TYPE.DEFAULT;
    setSearchType(type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParam.get('type')]);

  useEffect(() => {
    if (currentQueryText) {
      getSearchCounterLeaderBoard();
      let searchText = `?q=${currentQueryText}&type=${searchType}`;
      if (isDirectCustomSearch) {
        searchText += '&directsearch=yes';
      }

      if (hideSearchBar) {
        searchText += '&hideSearchBar=true';
      }
      history.push({
        pathname: '/search',
        search: searchText,
      });
    }
    fetchTotalVisitors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQueryText, searchType, history]);

  useEffect(() => {
    dispatch(
      updateSearch({
        isSearch: searchResult || customSearchResult || queryParam.get('q') ? true : false,
        type: queryParam.get('type') || searchType,
        customSearchType: searchType === CUSTOM_SEARCH_TYPE.DEFAULT ? 'google' : customSearchType,
      })
    );
  }, [dispatch, searchResult, customSearchResult, searchType, customSearchType, queryParam]);

  // fetch total visitors
  const fetchTotalVisitors = async () => {
    const body = { action: 'total' };
    const bodyJson = JSON.stringify(body);
    try {
      const url = process.env.REACT_APP_VISITOR_URL as string;
      const response = await axios.post(url, bodyJson);
      if (response.data) {
        const totalCounter = response.data.total_counter;
        // if(response.data.gram_per_second) {
        //   gramPerSecond = response.data.gram_per_second;
        // }
        if (totalCounter) {
          if (!totalVisitor) {
            setTotalVisitor(totalCounter * 1000);
          }
          startVistorTimer();
        }
      }
    } catch (e) {
      // console.log(e);
    }
  };

  // update search visitors
  const postSearchVisitors = async () => {
    let useridval = '';
    let leaderboardnameval = '';
    const companynameval = '';
    if (context.isAuthenticated) {
      useridval = context.getUserEmail();
      leaderboardnameval = context.getUserName() ? context.getUserName() : context.getUserDisplayName();
    } else {
      try {
        const responseIP = await axios.get('https://geolocation-db.com/json/');
        if (responseIP.data) {
          if (responseIP.data.IPv4) {
            useridval = responseIP.data.IPv4;
          }
        }
      } catch (e) {
        // console.log(e);
      }
    }
    const body = {
      action: 'add-counter',
      user_id: useridval,
      leader_board_name: leaderboardnameval,
      company_name: companynameval,
    };
    const bodyJson = JSON.stringify(body);
    try {
      if (!isDirectCustomSearch) {
        const url = process.env.REACT_APP_VISITOR_URL as string;
        const response = await axios.post(url, bodyJson);
        if (response.data) {
          getMyTotalCounters(useridval);
        }
      }
    } catch (e) {
      // console.log(e);
    }
  };

  // get my total search counters
  const getMyTotalCounters = async (useridval: any) => {
    if (useridval !== '') {
      const body = {
        action: 'user-total',
        user_id: useridval,
      };
      const bodyJson = JSON.stringify(body);
      try {
        const url = process.env.REACT_APP_VISITOR_URL as string;
        const response = await axios.post(url, bodyJson);
        if (response.data) {
          const mTCounter = response.data.user_total_counter;
          if (mTCounter) {
            setMyTotalVisitor(mTCounter);
          }
        }
      } catch (e) {
        // console.log(e);
      }
    }
  };

  // get leader board in search counter
  const getSearchCounterLeaderBoard = async () => {
    let groupbyval = '';
    if (searchType === CUSTOM_SEARCH_TYPE.DEFAULT) {
      groupbyval = 'company_name';
      // setIsProLeaderBoard(true);
    } else {
      groupbyval = 'leader_board_name';
      // setIsProLeaderBoard(false);
    }
    const body = {
      action: 'leader-board',
      group_by: groupbyval,
    };
    const bodyJson = JSON.stringify(body);
    try {
      const url = process.env.REACT_APP_VISITOR_URL as string;
      const response = await axios.post(url, bodyJson);
      if (response.data) {
        const leaderData = response.data.leader_board;
        if (leaderData) {
          // setLeaderBoards([]);
          const leaderArray: any[] = [];
          for (const oneItem of leaderData) {
            leaderArray.push(oneItem);
          }
          // setLeaderBoards(leaderArray);
        }
      }
    } catch (e) {
      // console.log(e);
    }
  };

  const handleSearchTypeChange = async (
    type: CUSTOM_SEARCH_TYPE,
    source: string = '',
    from: number = 1,
    sortVal: string = ''
  ) => {
    if (type === CUSTOM_SEARCH_TYPE.DEFAULT) {
      setSearchType(type);
      doTextSearch(currentQueryText, 0, source, false);
      return;
    }
    await handleCustomSearchTypeChange(type, currentQueryText, from, sortVal);
  };

  const handleCustomSearchTypeChange = async (
    type: CUSTOM_SEARCH_TYPE,
    text: string,
    from: number,
    sorting: string
  ) => {
    setIsLoading(true);
    setSearchType(type);
    setFrameSearchQueryText(text);
    return customSearch({
      type,
      query: text,
      from,
      sorting,
    })
      .then((result) => {
        return setCustomSearchResult(result.data);
      })
      .catch((err) => toast.error(err))
      .finally(() => {
        setIsLoading(false);
        // post search visitors
        postSearchVisitors();
      });
  };

  const handleSortOrderChange = (e: any) => {
    const value = (e && e.target && e.target.value) || '';
    setSort(value);
    handleSearchTypeChange(searchType, sourceType, 1, value);
  };

  const doTextSearch = (queryText: string = currentQueryText, from = 0, source: string, qSearch = true) => {
    if (!isDirectCustomSearch) {
      setSearchType(queryParam.get('type') ? queryParam.get('type') as CUSTOM_SEARCH_TYPE : CUSTOM_SEARCH_TYPE.DEFAULT);
    }
    // if(queryParam.get('type') === 'ALL') {
    //   handleToggle(false);
    //   WidgetClose();
    // }
    setFrameSearchQueryText(queryText);
    setIsLoading(true);
    setCustomSearchResult(null);
    // post search visitors
    // postSearchVisitors();
    return search(
      applyFilterToQuery(
        {
          query: {
            from,
            text: queryText + (qSearch ? ' ' + quickSerch : ''),
            source_type: source ? source : null,
          },
        },
        filter || {},
        CUSTOM_SEARCH_TYPE.DEFAULT
      )
    )
      .then((result) => {
        const data = result.data as IElasticResult<ISearchRecord>;
        const epnArray: any[] = [];
        data.records.map((ele: ISearchRecord) => {
          if (ele.location.geomz) {
            const decryptedData = CryptoJS.AES.decrypt(ele.location.geomz, secKey, {
              iv,
              mode: CryptoJS.mode.CBC,
            }).toString(CryptoJS.enc.Utf8);
            const latLon = decryptedData.split(',');
            ele.location.lon = parseFloat(latLon[0]);
            ele.location.lat = parseFloat(latLon[1]);
          }
          if (ele.location.geom_json) {
            for (const epnKey in ele.location.geom_json) {
              if (ele.location.geom_json.hasOwnProperty(epnKey)) {
                for (const epnInnerKey in ele.location.geom_json[epnKey]) {
                  if (ele.location.geom_json[epnKey].hasOwnProperty(epnInnerKey)) {
                    const element = ele.location.geom_json[epnKey][epnInnerKey];
                    const epnRecord: any = {};
                    if (element.geom) {
                      const decryptedData = CryptoJS.AES.decrypt(element.geom, secKey, {
                        iv,
                        mode: CryptoJS.mode.CBC,
                      }).toString(CryptoJS.enc.Utf8);
                      const latLon = decryptedData.split(',');
                      epnRecord.location = Object.assign({});
                      epnRecord.location.lon = parseFloat(latLon[0]);
                      epnRecord.location.lat = parseFloat(latLon[1]);
                      epnRecord.marker = element.marker;
                      epnRecord.name = element.name;
                      epnRecord.type = element.type;
                      epnRecord.details = element.details;
                      epnArray.push(epnRecord);
                    }
                  }
                }
              }
            }
          }
          return ele;
        });
        setSearchResult(data);
        setDocSearchEPNResult(epnArray);
      })
      .finally(() => {
        setIsLoading(false);
        if (!qSearch) {
          setQuickFilter(false);
        }
      });
  };

  useEffect(() => {
    if (currentQueryText && searchType !== 'MAP') {
      handleSearchTypeChange(searchType, sourceType, 1, sort);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCustomSearch = () => {
    if (searchType === CUSTOM_SEARCH_TYPE.DEFAULT) {
      return <></>;
    }
    if (customSearchResult || queryParam.get('q')) {
      return (
        <>
          {searchType === 'MAP' ? (
            <CustomSearchMapOption text={currentQueryText} queryParam={queryParam} />
          ) : (
            <GoogleCustomSearch
              result={customSearchResult}
              onNextClick={() => {
                if (!customSearchResult || !customSearchResult.next) {
                  return;
                }
                return handleSearchTypeChange(searchType, sourceType, customSearchResult.next.startIndex, sort);
              }}
              onPreviousClick={() => {
                if (!customSearchResult || !customSearchResult.previous) {
                  return;
                }
                return handleSearchTypeChange(searchType, sourceType, customSearchResult.previous.startIndex, sort);
              }}
              handleSortOrderChange={handleSortOrderChange}
              isLoading={isLoading}
              sort={sort}
              searchType={searchType}
              text={currentQueryText}
              tCounter={myTotalVisitor}
            />
          )}
        </>
      );
    }
  };

  const renderDefaultSearch = () => {
    return (
      <SearchResultContainer
        onPreviousClick={() => {
          if (!searchResult) {
            return;
          }
          const currentFrom = searchResult.from;
          const currentSize = searchResult.size;
          setIsLoading(true);
          return doTextSearch(currentQueryText, currentFrom - currentSize, sourceType);
        }}
        onNextClick={() => {
          if (!searchResult) {
            return;
          }
          const currentFrom = searchResult.from;
          const currentSize = searchResult.size;
          return doTextSearch(currentQueryText, currentFrom + currentSize, sourceType);
        }}
        result={searchResult}
        docSearchEPNResult={docSearchEPNResult}
        setQuickSerch={setQuickSerch}
        quickSerch={quickSerch}
        isQuickFilter={isQuickFilter}
        setQuickFilter={setQuickFilter}
        sourceType={sourceType}
        doTextSearch={doTextSearch}
        isLoading={isLoading}
        currentQueryText={currentQueryText}
        applyFilterToQuery={applyFilterToQuery}
        filter={filter}
      />
    );
  };

  useEffect(() => {
    // handleFileUploadButton();
    if (searchInputRef.current !== null) {
      searchInputRef.current.focus();
    }
    if (aiToCeresRN) {
      if (searchInputRef.current !== null) {
        searchInputRef.current.value = aiToCeresTerm || '';
      }
      if (searchButton && searchButton.current) {
        searchButton.current.click();
      }
      localStorage.removeItem('RnNumber');
    }
    const param = window.location.pathname;
    const index = param.indexOf('=') + 1;
    const queryString = param.substring(index);
    if (index > 1 && searchButton.current && searchInputRef.current) {
      searchInputRef.current.value = queryString;
      searchButton.current.click();
    }
    if (searchButton.current && searchQueryText) {
      searchButton.current.click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showSearchTypePill = () =>
    ((searchType === 'default' && context.isAuthenticated) || searchType !== 'default') &&
    (context.isLubrizol() ||
      context.isTier(TIERS.PLATINUM) ||
      context.isTier(TIERS.DAYPASS) ||
      context.isTier(TIERS.SUPER_ADMIN) ||
      searchType !== 'default') &&
    (!!searchResult || !!customSearchResult);

  const userTier = context.getUserTier();

  const backgroundImage = require('../../assets/dayPassContainerImg.png');

  const tundraProImage = require('../../assets/dayPassTundraProImg.png');

  const buyTundraPro = require('../../assets/dayPassBuyImg.png');
  const classes = useStyles();
  return (
    <>
      <HomeTour
        showTour={isTourOpen}
        onGotItClick={() => {
          localStorage.setItem('walkthrough', 'false');
        }}
        onCloseTour={() => {
          setTourOpen(false);
        }}
      />
      <HomeContainer>
        {
          (searchState.isSearch && searchType === 'default' && !isLoading && context.isTier(TIERS.FREE) && !context.isLubrizol()) ?

            <DaypassContainer>
              <Back>
                <img src={backgroundImage} alt="DayPass" />
                {
                  isDayPassLoading ?
                    <CircularProgress color="secondary" className={classes.loaderContainer} />
                    : null
                }
                <div className="pro"><img src={tundraProImage} alt="Tundra Pro" /></div>
                <div><img src={buyTundraPro} alt=" Buy Tundra Pro" onClick={() => {
                  if (!context.isAuthenticated) {
                    return warnAndRedirectToLogin();
                  }
                  if (context.isTier(TIERS.PLATINUM) || context.isLubrizol()) {
                    return toast.warn(`You are already a PLATINUM subscriber`);
                  }
                  if (context.isTier(TIERS.DAYPASS)) {
                    return toast.warn(`You are already a DAYPASS subscriber`);
                  }
                  setIsDayPassLoading(true);
                  CeresStripePayments.doCheckout(
                    'DAYPASSNEW',
                    context.getUserEmail(),
                    true
                  )
                    .then(
                      ({ error }: { error: any }) => {
                        setIsDayPassLoading(false);
                        error && toast.error(error.message);
                      }
                    )
                    .catch((error: { message: string }) => {
                      setIsDayPassLoading(false);
                      toast.error(error.message);
                    });
                }} /></div>
              </Back>
            </DaypassContainer>
            : null
        }
        <SearchContainerMain tier={userTier} issearch={searchState && searchState.isSearch ? 'yes' : ''}>
          {!(searchState && searchState.isSearch) && (
            <CeresLogoContainerItem>
              <CeresBotHeroImage src={EnviroAILogo} alt="EnviroAI" />
              <p className="mb-0">Digital Environmental Assistant</p>
             <EnviroAiTriangleContainer>
                <Link to="/myenviroai" className="logo-container">
                  <img data-tip="Ask EnviroAI" src={EnviroAiTriangle} alt="EnviroAITriangle" />
                </Link>
                <LaunchText>LAUNCH</LaunchText>
              </EnviroAiTriangleContainer>
            </CeresLogoContainerItem>
          )}
          {!hideSearchBar && <SearchFormContainerItem data-tour="ceres-first-step">
            <SearchForm
              isFilterApplied={!!filter}
              onFilterClick={() => setShowFilter(true)}
              onEnviroFilterClick={() => setShowEnviroFilter(true)}
              isLoading={searchType === 'default' ? isLoading : false}
              inputRef={searchInputRef}
              searchRef={searchButton}
              queryText={searchQueryText}
              setQueryText={setSearchQueryText}
              onSearchSubmit={(queryText: string) => {
                if (!!!queryText) {
                  return;
                }
                if (!!!queryText && !!!filter) {
                  return;
                }
                setQuickSerch('');
                setCurrentQueryText(queryText);
                if (context.isAuthenticated && queryParam.get('type') !== 'ALL') {
                  // toggleChatForSearch(queryText);
                  return doTextSearch(queryText, 0, '', false);
                }
                // toggleChatForSearch(queryText);
                doTextSearch(queryText, 0, '', false);
                return handleCustomSearchTypeChange(
                  (queryParam.get('type') ? queryParam.get('type') : CUSTOM_SEARCH_TYPE.ALL) as CUSTOM_SEARCH_TYPE,
                  queryText,
                  1,
                  sort
                );
              }}
            />
            {searchState && searchState.isSearch && !isDirectCustomSearch && (

              <ShowLogoArea>
                {searchType !== 'default' && customSearchType === 'environmental' ? (
                  <BackLink
                    onClick={() => {
                      setCustomSearchType('google');
                      setSearchType(CUSTOM_SEARCH_TYPE.ALL);
                      handleSearchTypeChange(CUSTOM_SEARCH_TYPE.ALL, '', 1, sort);
                    }}
                  >
                    <ChevronLeft /> Back to Normal Search Results
                  </BackLink>
                ) : searchType === 'default' ? (
                  <BackLink
                    onClick={() => {
                      setCustomSearchType('google');
                      setSearchType(CUSTOM_SEARCH_TYPE.ALL);
                      handleSearchTypeChange(CUSTOM_SEARCH_TYPE.ALL, '', 1, sort);
                    }}
                  >
                    <ChevronLeft /> Get Normal Search Results
                  </BackLink>
                ) : (process.env.REACT_APP_CHROME_EXTENSION_URL && isChrome && !isExtensionInstalled) ? (
                  <Extension>
                    <Button
                      onClick={() => {
                        window.open(process.env.REACT_APP_CHROME_EXTENSION_URL, '_blank');
                      }}
                    >
                      <img src="/icon/chrome.png" alt="chrome" />
                      Add EnviroAI to Chrome
                    </Button>
                  </Extension>
                ) : (
                  <div></div>
                )}
                <LogoArea>
                  {searchType === 'default' && <Title src={EnviroAILogo} alt="Enviro Logo" />}
                  {/* <BackLink
                    onClick={() => {
                      setIsCollapsed(false);
                    }}
                  >
                    <img src={Logo} alt="" />
                  </BackLink>
                  <RightSideTotalSearchDialog
                    isCollapsed={isCollapsed}
                    setIsCollapsed={setIsCollapsed}
                    tCounter={myTotalVisitor}
                    boards={leaderBoards}
                    isProLeaderBoard={isProLeaderBoard}
                    isLoggedIn={context.isAuthenticated}
                  /> */}
                </LogoArea>
              </ShowLogoArea>
            )}
            <SidebarOverlay onClick={() => setIsCollapsed((prevState) => !prevState)} isActive={!isCollapsed} />
          </SearchFormContainerItem>}
          {/* {!(searchState && searchState.isSearch) && (
            <div>
              <CeresMessage>
                <p>You can ask me questions ...</p>
                <div className="question-box">EnviroAI Questions</div>
              </CeresMessage>
              <CeresMessage>
                <p>... and you can ask me to help you do things ...</p>
                <div className="question-box">What is flares?</div>
              </CeresMessage>
            </div>
            )} */}
          {showSearchTypePill() && !isDirectCustomSearch && (
            <SearchTypePills
              active={searchType}
              isDisabled={false}
              isOnlyDocumentSearch={!!!currentQueryText}
              setSearchType={setSearchType}
              sourceType={sourceType ? sourceType : null}
              setSourceType={setSourceType}
              filters={filters}
              setFilter={setFilter}
              setQuickSerch={setQuickSerch}
              onTypeChange={(type: CUSTOM_SEARCH_TYPE, source: string) => {
                type !== 'MAP' && handleSearchTypeChange(type, source, 1, sort);
              }}
              searchType={searchType}
              customSearchType={customSearchType}
              setCustomSearchType={setCustomSearchType}
            />
          )}
        </SearchContainerMain>
        <SearchResultMainContainer tier={userTier}>
          {searchType === CUSTOM_SEARCH_TYPE.DEFAULT && renderDefaultSearch()}
          {searchType !== CUSTOM_SEARCH_TYPE.DEFAULT && renderCustomSearch()}
        </SearchResultMainContainer>
        <AdvancedSearchFilter
          rnInputRef={rnInputRef}
          applyButtonRef={applyButtonRef}
          onFilterClear={() => setFilter(null)}
          onFilterApply={(newFilter) => {
            filter = newFilter;
            setFilter(newFilter);
            setShowFilter(false);
          }}
          onClose={() => setShowFilter(false)}
          show={showFilter}
          doTextSearch={doTextSearch}
          currentQueryText={currentQueryText}
          isLoading={isLoading}
          sourceType={sourceType}
          setSourceType={setSourceType}
        />
        <EnviroSearchFilter
          show={showEnviroFilter}
          onClose={() => setShowEnviroFilter(false)}
          queryText={searchQueryText}
          setQueryText={setSearchQueryText}
        />
        {/* <CountNumber totalCounter={totalVisitor} /> */}
        {/* <Bookdemo>
          <a href="https://calendly.com/jed-enviroai" target="_blank" rel="noopener noreferrer">Book a demo for Pro</a>
        </Bookdemo> */}
      </HomeContainer>
      {
        (!context.isTier(TIERS.FREE)) && <MainChatBot isLoading={isLoading} searchQueryText={searchQueryText} frameSearchQueryText={frameSearchQueryText} context={context} routeChange={routeChange} />
      }
    </>
  );
};

export default withAuthContext(CeresHome);

import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, Form, InputGroup, Modal, Spinner } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import styled, { keyframes } from 'styled-components';
import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import EditIcon from '@material-ui/icons/Edit';
import StarIcon from '@material-ui/icons/Star';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { savePrompts } from '../../services/firebase';
import showToastMessage from '../../util/showToastMessage';
import { debounce } from 'lodash';
import { isExxonMobileEmail } from '../Validator';

const chatAnimation = keyframes`
  0% { right: -50%; }
  100% { right: 0; }
`;

const ChatSummaryContainer = styled.div`
  font-size: 22px;
  position: fixed;
  top: 0;
  right: 0;
  width: 50%;
  z-index: 997;
  height: 100%;
  background-color: #f3f3f3;
  color: white;
  animation: ${chatAnimation} 1s 1 normal;
`;

const TitleContainer = styled.div`
  display: flex;
  background-color: #2c190e !important;
  font-family: 'Sans Pro';
  padding: 7px 7px;

  .title-head {
    margin: auto;
  }

  .icon-container {
    padding-right: 20px;
  }

  .close-prompt {
    position: absolute;
    font-size: 1.5rem;
    top: 12px;
    right: 0.75rem;
    color: #ffffff;
    border: none;
    background: none;
    cursor: pointer;
    font-family: monospace;
  }
`;

const PromptsBody = styled.div`
  height: 100%;
  .card-body {
    display: flex;
    align-items: center;
    padding: 0 10px;
  }

  button {
    font-family: 'Sans Pro';
  }

  .card-title {
    margin: 1rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .drag {
    margin-bottom: 5px;
  }
  .drag-icon:hover {
    cursor: move;
  }

  .prompts-list {
    padding: 0 0 1rem 1rem;
    overflow-y: scroll;
    height: calc(100% - 11rem);
    /* width */
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #D3D3D3;
      border-radius: 10px;
    }
  }
`;

const StyledTooltip = styled(ReactTooltip)`
  max-width: 25% !important
`;

interface IChangedQuestion {
  promptIndex: number;
  questionIndex: number;
  question: string;
}

interface IDeleteItem {
  title: string;
  index: number;
}

export default function SavedPrompts({ closeSavedPrompt, onCopyText, onPromptsChange, context }: any) {
  const [prompts, setPrompts] = useState<any[]>([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isQuestionSaved, setIsQuestionSaved] = useState(false);
  const [changedQuestion, setChangedQuestion] = useState<IChangedQuestion | null>(null);
  const [newPrompt, setNewPrompt] = useState('');
  const [draggedItemFrom, setDraggedItemFrom] = useState<any>(null);
  const [totalPrompts, setTotalPrompts] = useState(0);
  const [loadMoreSpinner, setLoadMoreSpinner] = useState(false);
  const [showDeleteConfitmationPopup, setShowDeleteConfitmationPopup] = useState(false);
  const [deleteItem, setDeleteItem] = useState<IDeleteItem | null>(null);
  const [searchPrompt, setSearchPrompt] = useState<string>('');
  const [editableIndex, setEditableIndex] = useState<number | null>(null);
  const isExxonMobilUser = isExxonMobileEmail(context);

  useEffect(() => {
    // Fetch and set all prompts when the component mounts
    (async () => {
      try {
        await getAllPrompts();
      } catch (error) {
        if (error instanceof Error) {
          handleErrorMessage(error);
        }
      }
    })();
    // eslint-disable-next-line
  }, []);

  // Loadd more items loader handling
  useEffect(() => {
    // Disable more item loader when all prompts load
    if(totalPrompts === prompts.length) {
      setLoadMoreSpinner(false);
    } else {
      setLoadMoreSpinner(true);
    }
    // eslint-disable-next-line
  }, [prompts, totalPrompts]);

  async function getAllPrompts(showSuccessMessage = true) {
    try {
      setShowSpinner(showSuccessMessage ? true : false);
      const response = await savePrompts({ type: 'getAll', pageSize: 15 });

      if (response && response.data) {
        if (showSuccessMessage) { showToastMessage({type: 'info', title: 'Retrieved all prompts successfully.'}); }
        setPrompts(response.data.data);
        setTotalPrompts(response.data.totalItems);
        onPromptsChange && onPromptsChange(response.data.data);
      }

    } catch (error) {
      if (error instanceof Error) {
        handleErrorMessage(error);
      }
    } finally {
      setShowSpinner(false);
    }
  }

  /**
   * On scroll list handler
   * @param e
   * @param searchTerm
   * @returns
   */
  const scrollHandler = async (e: any, searchTerm?: string) => {
    if(searchTerm !== undefined) {
      setPrompts([]);
    }
    const promptListElement = document.getElementById('prompts-list');
    if(!!!promptListElement) {
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } = promptListElement;
    const shouldLoadMore = scrollTop + 10 >= scrollHeight - clientHeight;

    if ((shouldLoadMore && prompts.length < totalPrompts) || searchTerm !== undefined) {
      try {
        const lastItemIndex = searchTerm ? undefined : prompts.length;
        const moreData: any = await savePrompts({ type: 'getAll', lastItemIndex, searchTerm: searchTerm || searchPrompt, pageSize: 15 });

        if(searchTerm !== undefined) {
          setPrompts(moreData.data.data);
        } else {
          setPrompts(prompts.concat(moreData.data.data));
        }
        setTotalPrompts(moreData.data.totalItems);
      } catch (error) {
        if (error instanceof Error) {
          handleErrorMessage(error);
        }
      }
    }
  };

  function DeletePromptConfirmationModal(modalProps: any) {
    return (
      <Modal
        {...modalProps}
        animation={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Delete Prompt Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            Are you sure, you want to delete <b>{deleteItem ? deleteItem.title : ''}</b> named prompt?
        </Modal.Body>
        <Modal.Footer>
        <Button onClick={() => onFavoriteClick(deleteItem!.title, deleteItem!.index)}>
        Delete
          </Button>
          <Button onClick={modalProps.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  function onDelete(title: string, index: number) {
    setDeleteItem({ title, index });
    setShowDeleteConfitmationPopup(true);
  }

  async function onFavoriteClick(title: string, itemIndex: number) {
    setShowDeleteConfitmationPopup(false);
    setShowSpinner(true);
    setEditableIndex(null);

    const { payload, id } = prompts[itemIndex];

    try {
      const response: any = await savePrompts({
        type: 'remove',
        id,
        record: { promptData: payload.promptData }
      });

      if (response) {
        showToastMessage({ type: 'success', actionType: 'delete', title: 'Data removed successfully', description: `Removed prompt: ${title}` });
        setSearchPrompt('');
        getAllPrompts();
      }
    } catch (error) {
      if (error instanceof Error) {
        handleErrorMessage(error);
      }
    } finally {
      setShowSpinner(false);
      setDeleteItem(null);
    }
  }

  const updateQuestion = (promptIndex: number, questionIndex: number, question: string) => {
    setChangedQuestion({
      promptIndex,
      questionIndex,
      question
    });
  };

  const saveUpdatedQuestion = async () => {
    if (!changedQuestion) {
      return;
    }
    const { promptIndex, questionIndex, question } = changedQuestion;
    const prompt = prompts[promptIndex];

    if (!prompt) {
      // Handle the case where the prompt is not found
      return;
    }

    const updatedPromptData = prompt.payload.promptData.map((q: any, i: number) =>
      questionIndex === i ? question : q
    );

    try {
      setIsQuestionSaved(true);

      const response: any = await savePrompts({
        type: 'update',
        id: prompt.id,
        record: { promptData: updatedPromptData }
      });

      if (response) {
        showToastMessage({ type: 'success', actionType: 'update', title: 'Data updated successfully.', description: `Updated prompt: ${question}` });
        setChangedQuestion(null);
        prompt.payload.promptData = updatedPromptData;
        onPromptsChange && onPromptsChange(prompts);
      }
    } catch (error) {
      if (error instanceof Error) {
        handleErrorMessage(error);
      }
    } finally {
      setEditableIndex(null);
      setIsQuestionSaved(false);
    }
  };

  const addNewQuestion = async () => {
    try {
      setShowSpinner(true);
      const promptData = [newPrompt];

      const response = await savePrompts({
        type: 'create',
        record: {promptData}
      });

      if(response && response.data) {
        showToastMessage({ type: 'success', actionType: 'create', title: 'Data created successfully.', description: `Created new prompt: ${newPrompt}`});
        getAllPrompts();
      }
    } catch (error) {
      if (error instanceof Error) {
        handleErrorMessage(error);
      }
    } finally {
      setNewPrompt('');
      setShowSpinner(false);
    }
  };

  const onDragOver = async (index: number) => {
    const draggedOverItem = prompts[index].id;

    // over itsself, ignore;
    if(draggedItemFrom === draggedOverItem) {
      return;
    }

    // filter out the currently dragged item
    const items = prompts.filter(item => item.id !== draggedItemFrom);
    // add the dragged item after the dragged over item
    items.splice(index, 0, prompts.find(item => item.id === draggedItemFrom));
    setPrompts(items);
  };

  const onDragStart = (e: any, index: number) => {
    setDraggedItemFrom(prompts[index].id);
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/html', e.target.parentNode);
    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
  };

  const onDragEnd = async (e: any, index: number) => {
    try {
      const startIndex = prompts.findIndex(item => item.id === draggedItemFrom);
      const draggedFromData = prompts[startIndex];
      const draggedToData = prompts[index];

      // when swap with adjacent item
      if((index === startIndex + 1) || (index === startIndex - 1)) {
        await savePrompts({
          type: 'update',
          id: draggedFromData.id,
          record: { order: draggedToData.order }
        });

        await savePrompts({
          type: 'update',
          id: draggedToData.id,
          record: { order: draggedFromData.order }
        });
      } else if(startIndex > index) {   // When drag from top to down
        const promptData = [...prompts];
        const range = promptData.slice(index, startIndex + 1); // Get the range of items to update

        // Extract the orders from the range and reverse them
        const orders = range.map(item => item.order).sort().reverse();

        // Apply the reversed orders back into the original data
        range.forEach((item, i) => {
          promptData[index + i].order = orders[i]; // Set the new order
        });

        // Save the updated orders asynchronously using Promise.all
        await Promise.all(
          range.map(item =>
            savePrompts({
              type: 'update',
              id: item.id,
              record: { order: item.order }
            })
          )
        );
      } else if (startIndex < index) { // When drag from bottom to up
        const promptData = [...prompts];
        const range = promptData.slice(startIndex, index + 1); // Get the range of items to update

        // Extract the orders from the range and reverse them
        const orders = range.map(item => item.order).sort().reverse();

        // Apply the reversed orders back into the original data
        range.forEach((item, i) => {
          promptData[startIndex + i].order = orders[i]; // Set the new order
        });

        // Save the updated orders asynchronously using Promise.all
        await Promise.all(
          range.map(item =>
            savePrompts({
              type: 'update',
              id: item.id,
              record: { order: item.order }
            })
          )
        );
      }

      showToastMessage({ type: 'success', actionType: 'update', title: 'Data updated successfully.', description: `Updated prompt order for ${draggedFromData.payload.promptData[0]}`});
    } catch (error) {
      if (error instanceof Error) {
        handleErrorMessage(error);
      }
    } finally {
      setDraggedItemFrom(null);
      getAllPrompts(false);
      onPromptsChange && onPromptsChange(prompts);
    }
  };

  // Handle catch error
  function handleErrorMessage(error: Error) {
    const { name, message } = error;
    showToastMessage({ type: 'error', title: name, description: message });
  }

  // Function to handle input change
  const handleChange = (event: any) => {
    setSearchPrompt(event.target.value);
  };

  // Function to handle debounced input change
  const handleDebouncedChange = useMemo(
    () =>
      debounce((value) => {
        scrollHandler('', value);
      }, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // Function to handle input change with debounce
  const handleInputWithDebounce = (event: any) => {
    handleChange(event); // Update state immediately
    handleDebouncedChange(event.target.value); // Trigger debounce function
  };

  return (
    <ChatSummaryContainer>
      <DeletePromptConfirmationModal show={showDeleteConfitmationPopup} onHide={() => setShowDeleteConfitmationPopup(false)}/>
      <TitleContainer>
       <button id="back-btn" className="btn btn-outline-light" onClick={() => closeSavedPrompt(false)}><ArrowBackIosIcon fontSize="small"/>Back</button>
        <div className="title-head title-head-fullscreen">
          <h2>
            Saved Prompts
          </h2>
        </div>

        <div className="icon-container">
        <button className="close-prompt" onClick={() => closeSavedPrompt(false)}>X</button>
        </div>
      </TitleContainer>
      <PromptsBody>
      {!isExxonMobilUser && (
        <InputGroup className="p-3">
          <Form.Control
            className="rounded"
            placeholder="Type New Prompt..."
            name="prompt"
            value={newPrompt}
            onChange={(e: any) => setNewPrompt(e.target.value)}
          />
          <Button variant="outline-secondary" size="sm" className="ml-2" id="profile-headline" onClick={addNewQuestion} disabled={showSpinner || !!!newPrompt}>
            Add Prompt
          </Button>
        </InputGroup>
        )}
        {/* Search Input */}
        <InputGroup className="px-3 pb-3">
          <Form.Control
            className={
              isExxonMobilUser
                ? 'rounded mt-2'
                : 'rounded'
            }
            placeholder="Search Chat..."
            name="savedChat"
            value={searchPrompt}
            onChange={handleInputWithDebounce}
          />
        </InputGroup>
        <div className="prompts-list" id="prompts-list" onScroll={debounce(scrollHandler, 1000)}>
          {
            showSpinner ? (
              <div className="d-flex justify-content-center" >
                <Spinner animation="border" variant="secondary" />
              </div>
            )
            :
            <>
              { prompts.length ? prompts.map((data: any, idx: number) => (
                  data && data.payload && data.payload.promptData && data.payload.promptData.map((title: string, i: number) => (
                    <li className="d-flex mb-2 mr-1" key={idx} onDragOver={(e)=> onDragOver(idx)}>
                      <Card key={i} text="primary" className="w-100 drag" draggable onDragStart={(e: any) => onDragStart(e,idx)} onDragEnd = {(e: any) => onDragEnd(e,idx)}
                        style={{
                          borderColor: draggedItemFrom && prompts[idx].id === draggedItemFrom ? '#000' : 'white'
                        }}>
                        <Card.Body>
                          <MenuIcon color="primary" className="drag-icon"/>
                           <Card.Title
                            data-tip= {title}
                            data-for="title-tooltip"
                            className={`fs-15 ${editableIndex === idx ? 'w-100 border border-3 rounded border-dark name' : 'w-auto mr-auto'}`}
                            contentEditable={idx === editableIndex}
                            dangerouslySetInnerHTML={{ __html: title }}
                            onInput={(e: any) => updateQuestion(idx, i, e.currentTarget.textContent)}
                          />
                          <StyledTooltip id="title-tooltip" place="bottom" effect="solid"/>
                          {
                            changedQuestion && changedQuestion.promptIndex === idx && changedQuestion.questionIndex === i && (
                              <>
                                { isQuestionSaved && <div><Spinner animation="border" variant="secondary" /></div> }
                                <IconButton
                                  onClick={(e: any) => saveUpdatedQuestion()}
                                  aria-label="fav-icon"
                                  component="span"
                                >
                                  <SaveIcon style={{color: '#0f6ecd'}} />
                                </IconButton>
                              </>
                            )
                          }
                          {editableIndex !== idx &&
                            <IconButton data-tip="Edit Title" data-for="title-tooltip" onClick={() => setEditableIndex(idx)} aria-label="edit-icon" component="span">
                              <EditIcon color="primary"/>
                            </IconButton>
                          }
                          <IconButton
                            onClick={(e: any) => { onDelete(title, idx); }}
                            aria-label="fav-icon"
                            component="span"
                          >
                            <StarIcon data-tip="Remove Prompt" data-for="title-tooltip" style={{color: '#faaf00'}} />
                          </IconButton>
                        </Card.Body>
                      </Card>
                      <button
                       className="btn btn-outline-secondary ml-2 btn-sm text-nowrap"
                        onClick={() => onCopyText(title)}
                      >
                        Copy To Chat
                      </button>
                    </li>
                  ))
              )):(
                !loadMoreSpinner && !showSpinner && <div className="d-flex justify-content-center mt-5 h3 text-dark">
                  No Data found
                </div>
              )}
              {loadMoreSpinner && (<div className="d-flex justify-content-center my-3">
                <Spinner animation="border" variant="secondary" />
              </div>)}
            </>
          }
        </div>
      </PromptsBody>
    </ChatSummaryContainer>
  );
}

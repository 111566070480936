import styled from 'styled-components';

export const TableContainer = styled.div`
  margin-top: 1rem;
`;
export const TD = styled.td`
  font-size: 11px;
  text-align: center;
`;
export const TDR = styled(TD)`
  text-align: right;
`;
export const TDL = styled(TD)`
  text-align: left;
`;
export const TH = styled.th`
  font-size: 13px;
  text-align: center;
`;
export const TableHeading = styled.div`
  display: flex;
  justify-content: center;
`;

export const Emission = {
  emissionEvents: {
    background: 'rgba(75,192,192,0.4)',
    border: 'rgba(75,192,192,1)',
    pointHoverBorder: 'rgba(220,220,220,1)',
  },
  quantityReleased: {
    background: 'rgba(244,134,12,0.4)',
    border: 'rgba(244,134,12,1)',
    pointHoverBorder: 'rgba(220,220,220,1)',
  },
};

import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { CUSTOM_SEARCH_TYPE } from '../../types/search-type';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    paper: {
      marginRight: theme.spacing(2),
    },
  })
);

interface ISubMenuProps {
  sunMenu: any;
  setCustomSearchType: (src: string) => void;
  setSourceType: (type: string) => void;
  setSearchType: (type: string) => void;
  onTypeChange: (type: CUSTOM_SEARCH_TYPE, source: string) => void;
  clearFilter: any;
}

const GoogleSearchMenuMoreFilters: React.FC<ISubMenuProps> = ({
  sunMenu,
  setCustomSearchType,
  setSourceType,
  setSearchType,
  onTypeChange,
  clearFilter,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState<boolean>(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((aprevOpen) => !aprevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <div>
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          {sunMenu.icon} {sunMenu.label}
        </Button>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    {sunMenu.menus &&
                      sunMenu.menus.length > 0 &&
                      sunMenu.menus.map((menu: any, index: number) => (
                        <MenuItem
                          key={index}
                          onClick={(event: React.MouseEvent<EventTarget>) => {
                            setSourceType('');
                            clearFilter().then(() => {
                              setSearchType(menu.value);
                              onTypeChange(menu.value, '');
                              menu.label === 'Environmental Results' && setCustomSearchType('environmental');
                            });
                            handleClose(event);
                          }}
                        >
                          {menu.icon} {menu.label}
                        </MenuItem>
                      ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
};
export default GoogleSearchMenuMoreFilters;

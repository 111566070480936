export const Annual = {
    industryMedian: {
      background: 'rgba(244,134,12,0.1)',
      border: '#A9A9A9',
      pointHoverBorder: 'rgba(220,220,220,1)',
    },
    facility: {
      background: 'rgba(133,133,133,0.1)',
      border: (color: string) => color,
      pointHoverBorder: 'rgba(220,220,220,1)',
    },
  };

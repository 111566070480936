import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import GoogleMapReact, { ChangeEventValue } from 'google-map-react';
import { OverlayTrigger } from 'react-bootstrap';
import CeresBotLogo from '../../../assets/ceres-robo-map.png';
import { placeTextSearch } from '../../../services/firebase';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Rating from '@material-ui/lab/Rating';
import ReactTooltip from 'react-tooltip';
import ReactDOMServer from 'react-dom/server';
import { IPlaceResult } from '../../../types/custom-search-map-option';
const MapOption = styled.div`
  width: 100%;
  height: calc(100vh - 142px);
`;
const IconPegman = styled.img`
  width: 30px;
  cursor: pointer;
  margin: 5px auto;
  display: block;
`;
const ListI = styled(List)`
  height: calc(100vh - 142px);
  overflow-y: scroll;
  padding-top: 20px !important;
`;
const ListItemI = styled.div`
  display: block;
  margin-bottom: 30px;
  padding: 0 10px 0 30px;
  cursor: pointer;
  & h4 {
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 5px;
  }
  & p {
    font-size: 14px;
    font-weight: 300;
    margin: 0;
    color: #777;
  }
`;
const RatingArea = styled.div`
  display: flex;
  column-gap: 10px;
  font-size: 14px;
`;

const MAP_NAVIGATION_ELEMENT_ID = 'ceres-map-navigation';
export const onMapLoaded = ({ map, maps }: { map: any; maps: any }) => {
  map.setMapTypeId('roadmap');

  // Load ceres logo.
  const logoElement = document.createElement('img');
  logoElement.src = CeresBotLogo;
  logoElement.className = 'ceres-map-logo';
  map.controls[maps.ControlPosition.LEFT_BOTTOM] = [logoElement];

  // Setup navigation div.
  const navigationElement = document.createElement('div');
  navigationElement.className = 'ceres-map-navigation-main';
  navigationElement.id = MAP_NAVIGATION_ELEMENT_ID;
  map.controls[maps.ControlPosition.LEFT_CENTER] = [navigationElement];
};

const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY as string;
const Marker = ({ children }: any) => children;

const CustomSearchMapOption: React.FC<{ text: string; queryParam: any }> = ({ text, queryParam }) => {
  const [zoomLevel, setZoomLevel] = useState<number>(10);
  const [gMaps, setGMaps] = useState<typeof google.maps | null>(null);
  const [center, setSenter] = useState<{ lat: number; lng: number }>({ lat: 29.5, lng: -95.5 });
  const [results, setResults] = useState<IPlaceResult[]>([]);
  const [active, setActive] = useState<IPlaceResult | null>(null);

  useEffect(() => {
    if (gMaps && text) {
      placeTextSearch(text).then((result) => {
        if (result.data.results && result.data.results.length > 0) {
          setResults(result.data.results);
          setActive(result.data.results[0]);
        }
      });
    }
  }, [gMaps, text, queryParam]);

  useEffect(() => {
    if (active && active.geometry) {
      setSenter(active.geometry.location);
      setZoomLevel(16);
    }
  }, [active]);

  return (
    <MapOption>
      <Grid container>
        <Grid item md={3}>
          <ListI subheader={<li />}>
            {results &&
              results.length > 0 &&
              results.map((result: any, index: number) => (
                <li key={index}>
                  <ListItemI
                    onClick={() => {
                      setActive(result);
                    }}
                  >
                    <h4>{result.name}</h4>
                    <RatingArea>
                      <span>{result.rating}</span>
                      <Rating
                        name="half-rating"
                        defaultValue={result.rating}
                        precision={result.rating}
                        size="small"
                        readOnly
                      />
                      <span>({result.user_ratings_total})</span>
                    </RatingArea>
                    <p>{result.formatted_address}</p>
                  </ListItemI>
                </li>
              ))}
          </ListI>
        </Grid>
        <Grid item md={9}>
          <GoogleMapReact
            onChange={(value: ChangeEventValue) => {
              setZoomLevel(value.zoom);
            }}
            bootstrapURLKeys={{
              key: GOOGLE_MAP_KEY,
              libraries: ['places', 'drawing'],
              v: 'beta',
            }}
            onClick={() => {
              // resetMapToInitial();
            }}
            onGoogleApiLoaded={({ map, maps }: { map: any; maps: any }) => {
              onMapLoaded({ map, maps });
              setGMaps(maps);
            }}
            defaultCenter={{ lat: 29.0, lng: -95.5 }}
            center={center}
            defaultZoom={10}
            zoom={zoomLevel}
          >
            {results &&
              results.length > 0 &&
              results.map((result: any, index: any) => (
                <Marker key={index} lat={result.geometry.location.lat} lng={result.geometry.location.lng}>
                  <OverlayTrigger
                    // key={props.record.contentId}
                    trigger="hover"
                    placement="top"
                    delay={{ show: 200, hide: 1200 }}
                    overlay={
                      <ReactTooltip effect="solid" html={true} />
                    }
                  >
                    <IconPegman
                    data-tip={
                    ReactDOMServer.renderToString(
                      <div>
                        <strong style={{ cursor: 'pointer' }}>{result.name}</strong>
                        <br />
                        <small>{result.formatted_address}</small>
                        <br />
                        <small>Rating: {result.rating}</small>
                        <br />
                        <small>Business: {result.business_status}</small>
                      </div>
                    )
                  } src="/icon/marker.svg" />
                  </OverlayTrigger>
                </Marker>
              ))}
          </GoogleMapReact>
        </Grid>
      </Grid>
    </MapOption>
  );
};
export default CustomSearchMapOption;

export const tableHeadings = [
  { key: 'rank', header: 'Facility Rank' },
  { key: 'pollutant', header: 'Pollutant' },
  { key: 'amount', header: 'Amount(tons/year)' },
];

export const getEmissionOptions = (statsResult: any) => {
  const pollutants = [
    { key: 'nox', plotKey: 'nox_plot_line', medianKey: 'industry_median_nox_plot_line' },
    { key: 'voc', plotKey: 'voc_plot_line', medianKey: 'industry_median_voc_plot_line' },
    { key: 'so2', plotKey: 'so2_plot_line', medianKey: 'industry_median_so2_plot_line' },
    { key: 'co', plotKey: 'co_plot_line', medianKey: 'industry_median_co_plot_line' },
    { key: 'pm25', plotKey: 'pm25_plot_line', medianKey: 'industry_median_pm25_plot_line' },
    { key: 'pm10', plotKey: 'pm10_plot_line', medianKey: 'industry_median_pm10_plot_line' },
    { key: 'pb', plotKey: 'pb_plot_line', medianKey: 'industry_median_pb_plot_line' },
    { key: 'co2e', plotKey: 'ghg_plot_line', medianKey: 'industry_median_ghg_plot_line' },
  ];

  return pollutants.reduce((acc, { key, plotKey, medianKey }) => {
    acc[key] = {
      color: '#286ab3',
      title: statsResult[`${key}_graph`].title,
      max_y_axis: statsResult[`${key}_graph`].max_y_axis,
      fData: statsResult[`${key}_graph`].plot_lines[plotKey],
      imData: statsResult[`${key}_graph`].plot_lines[medianKey],
      labels: statsResult[`${key}_graph`].labels,
    };
    return acc;
  }, {} as Record<string, any>);
};

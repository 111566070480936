import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import styled from 'styled-components';
import { CeresLogger } from '../../logger';
import { API_BASE_URL } from '../../util/api.util';

const SwitchInfo = styled.div`
  text-align: right;
  & .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: #005fbc;
  }
  & .MuiSwitch-colorPrimary.Mui-checked {
    color: #005fbc;
  }
  & .MuiTypography-body1 {
    font-size: 1.2rem;
    font-weight: 600;
  }
`;
interface IEmailNotification {
  userEmail: any;
}

const EmailNotification: React.FC<IEmailNotification> = (props) => {
  const userEmail = props.userEmail;
  const [checked, setChecked] = useState(false);
  const [isLoad, setLoad] = useState(true);

  const toggleChecked = (event: any) => {
    const status: boolean = event.target.checked;
    setChecked(status);
    emailStatus('email_subscription_status_update', status);
  };
  const emailStatus = async (eAction: string, sStatus: boolean) => {
    setLoad(false);
    const config = {
      params: {
        email: userEmail,
        action: eAction,
        subscription_status: sStatus,
      },
    };
    try {
      await axios
        .get(
          API_BASE_URL + '/AgencyTracker',
          config
        )
        .then((response) => {
          setChecked(
            response.data.subscription_status === 'true' ? true : false
          );
        })
        .catch((error) => {
          CeresLogger.error(error);
        });
    } catch (e) {
      CeresLogger.error(e);
    }
  };
  useEffect(() => {
    if (isLoad) {
      emailStatus('email_subscription_status', false);
    }
  });

  return (
    <SwitchInfo>
      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            value="receive_email"
            control={
              <Switch
                color="primary"
                checked={checked}
                onChange={toggleChecked}
              />
            }
            label="Receive Email
"
            labelPlacement="start"
          />
        </FormGroup>
      </FormControl>
    </SwitchInfo>
  );
};
export default EmailNotification;

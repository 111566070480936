import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Table } from 'react-bootstrap';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import AppSpinner from '../../../components/AppSpinner';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import EmissionDetailsControls from './EmissionDetailsControls';
import { API_BASE_URL, API_AUTH_HEADERS } from '../../../util/api.util';

const TransitionPopup = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const ProcessTitle = styled.h4`
  font-size: 16px;
  padding: 12px;
  text-align: center;
  background-color: #000;
  color: #fff;
  text-transform: uppercase;
  border-radius: 2px;
`;
const EmissionTitle = styled.h4`
  font-size: 16px;
  padding: 12px;
  text-align: center;
  color: #000;
  background-color: #fefe33;
  text-transform: uppercase;
  border-radius: 2px;
`;
const ControlsTitle = styled.h4`
  background-color: #00bfff;
  font-size: 16px;
  padding: 12px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  border-radius: 2px;
`;

const HeatInput = styled.div`
  font-size: 16px;
`;
const FooterLink = styled.div`
  font-size: 14px;
  text-align: center;
  margin: 0 0 5px;
  display: flex;
  justify-content: center;
  width: 75%
`
;
const HeatInputTitle = styled.h4`
  font-size: 20px;
  text-align: center;
  margin-bottom: 10px;
`;
const PointNo = styled.div`
  font-size: 20px;
  margin: 20px;
  color: red;
  text-align: center;
`;
const DialogCust = styled(Dialog)`
  z-index: 9999 !important;
`;
const TH = styled.th`
  text-transform: uppercase;
`;
const ProcessEquipment = styled.div`
  text-align: center;
  line-height: 30px;
  & h5 {
    margin-bottom: 20px;
    font-size: 14px;
  }
  & h4 {
    background-color: #333332;
  }
`;
const FinInfoList = styled.div`
  text-align: left;
  & ul {
    margin: 20px 0;
    list-style: none;
    display: block;
  }
`;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: 0,
      zIndex: 9999,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface IDialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: IDialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    // justifyContent: 'center',
  },
}))(MuiDialogActions);

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: '16px !important',
    border: '0px',
    borderRadius: '4px',
  },
}))(MuiDialogContent);

interface IModalParam {
  open: boolean;
  setOpen: (open: boolean) => void;
  entityName: string;
  epn: string;
  modalTitle: string;
  setShowPopover: (show: boolean) => void;
  detailsObj: any;
  containerRef: HTMLDivElement;
}

const InfoModal: React.FC<IModalParam> = ({
  open,
  setOpen,
  entityName,
  epn,
  modalTitle,
  setShowPopover,
  detailsObj,
  containerRef,
}) => {
  const [processInfo, setProcessInfo] = useState<any>([]);
  const [isLoad, setLoad] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      setLoad(true);
      const requestBody: any = {};
      requestBody.rnNumber = entityName;
      requestBody.EPN = epn;
      axios
        .post(
          API_BASE_URL + '/api/getEmissionDetails',
          // 'http://localhost:5000/ceres-platform-test/us-central1/api/getEmissionDetails',
          requestBody,
          {
            headers: API_AUTH_HEADERS,
          }
        )
        .then((response) => {
          setProcessInfo(response.data[0].query_ceres_ed_tab);
        })
        .finally(() => setLoad(false));
    }
  }, [open, entityName, epn, setLoad]);

  const handleClose = () => {
    setOpen(false);
    setShowPopover(true);
  };

  return (
    <div>
      <DialogCust
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        TransitionComponent={TransitionPopup}
        className={modalTitle === 'Process/ Equipment Information' ? 'ProcessEquipment' : ''}
        container={() => containerRef}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Modal title
        </DialogTitle>
        <DialogContent dividers style={{ minWidth: '600px', minHeight: '250px', maxHeight: '600px' }}>
          {isLoad && processInfo && processInfo.length === 0 ? (
            <div>
              <AppSpinner />
            </div>
          ) : (
            <div>
              {modalTitle === 'Process/ Equipment Information' && (
                <>
                  <ProcessTitle>{modalTitle}</ProcessTitle>
                  <PointNo>Emission Point No., {epn}</PointNo>
                  {processInfo && processInfo.FINS && processInfo.state_code === 'TX' && (
                    <>
                      <Table striped bordered hover style={{ fontSize: '12px' }}>
                        <thead>
                          <tr>
                            <TH>FIN</TH>
                            <TH>Fin Name</TH>
                            <TH>Fin Profile</TH>
                          </tr>
                        </thead>
                        <tbody>
                          {processInfo.FINS.map((finsInfo: any, index: number) => (
                            <tr key={index}>
                              <td>{finsInfo['FIN']}</td>
                              <td>{finsInfo['FIN Name']}</td>
                              <td>{finsInfo['FIN Profile']}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <HeatInput>
                        {processInfo.equipment_details && processInfo.equipment_details.length > 0 && (
                          <>
                            <HeatInputTitle>Heat Input</HeatInputTitle>
                            <Table striped bordered hover style={{ fontSize: '12px' }}>
                              <thead>
                                <tr>
                                  <TH>Characteristic</TH>
                                  <TH>Value</TH>
                                  <TH>Units</TH>
                                </tr>
                              </thead>
                              <tbody>
                                {processInfo.equipment_details.map((equipment: any, index: number) => (
                                  <tr key={index}>
                                    <td>{equipment['Characteristic']}</td>
                                    <td>{equipment['Value']}</td>
                                    <td>{equipment['Units']}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </>
                        )}
                      </HeatInput>
                    </>
                  )}

                  {processInfo && processInfo.FINS && processInfo.state_code === 'LA' && (
                    <>
                      <ProcessEquipment>
                        <h5>{processInfo['EPN Type']}</h5>
                        <h5>{processInfo['EPN Description']}</h5>
                        {processInfo.FINS.map((finsInfo: any, index: number) => (
                          <FinInfoList key={index}>
                            {finsInfo && finsInfo.length > 0 && (
                              <ul>
                                <li>Tank Volume: {finsInfo['Tank Volume']}</li>
                              </ul>
                            )}
                            <ul>
                              <li>Normal Operating Rate = {finsInfo['Normal Operating Rate']}</li>
                              <li>Max Operating Rate = {finsInfo['Max Operating Rate']}</li>
                            </ul>
                            <ul>
                              <li>Height = {finsInfo['Height']}</li>
                              <li>Exit Gas Temperature = {finsInfo['Exit Gas Temperature']}</li>
                              <li>Diameter = {finsInfo['Diameter']}</li>
                              <li>Exit Gas Velocity = {finsInfo['Exit Gas Velocity']}</li>
                              <li>Exit Gas Flow Rate = {finsInfo['Exit Gas Flow Rate']}</li>
                            </ul>
                          </FinInfoList>
                        ))}
                      </ProcessEquipment>
                    </>
                  )}
                </>
              )}

              {modalTitle === 'Emission Details' && (
                <>
                  <EmissionTitle>{modalTitle}</EmissionTitle>
                  {processInfo &&
                    processInfo.FINS &&
                    processInfo.FINS.map((finsInfo: any, index: number) => (
                      <Table
                        striped
                        bordered
                        hover
                        key={index}
                        style={{
                          fontSize: '12px',
                          textAlign: 'center',
                          lineHeight: '14px',
                          marginTop: '1rem',
                        }}
                      >
                        <thead>
                          <tr>
                            <th colSpan={5}>{`${finsInfo['FIN']} - ${finsInfo['FIN Name']}`}</th>
                          </tr>
                          <tr>
                            <th></th>
                            <th colSpan={2}>{detailsObj.lbl_actual}</th>
                            <th colSpan={2}>{detailsObj.lbl_permitted}</th>
                          </tr>
                          <tr>
                            <th>Pollutant</th>
                            <th>
                              Hourly <br />
                              <small>lbs/hr</small>
                            </th>
                            <th>
                              Annual <br />
                              <small>tons/yr</small>
                            </th>
                            <th>
                              Hourly <br />
                              <small>lbs/hr</small>
                            </th>
                            <th>
                              Annual <br />
                              <small>tons/yr</small>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {finsInfo.emission_detail &&
                            finsInfo.emission_detail.length > 0 &&
                            finsInfo.emission_detail.map((sec: any, keys: number) => (
                              <tr key={keys}>
                                <td>{sec.pollutant}</td>
                                <td>{sec.ah}</td>
                                <td>{sec.aa}</td>
                                <td>{sec.ph}</td>
                                <td>{sec.pa}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    ))}
                </>
              )}

              {modalTitle === 'Controls' && (
                <>
                  <ControlsTitle>{modalTitle}</ControlsTitle>
                  {processInfo &&
                    processInfo.FINS &&
                    processInfo.FINS.map((finsInfo: any, index: number) => (
                      <EmissionDetailsControls finsInfo={finsInfo} />
                    ))}
                </>
              )}
            </div>
          )}
        </DialogContent>
        {!isLoad && processInfo && (
          <DialogActions>
            <FooterLink>
              <a href={processInfo.source_url} target="_blank" rel="noopener noreferrer">
                {processInfo.source_title}
              </a>
              </FooterLink>
            <div>
              <a href= {`${process.env.REACT_APP_CERES_URL}/downloadEmissionDetails?v_rn_number=${entityName}`} target="_blank" rel="noopener noreferrer">
                Download
              </a>
            </div>
          </DialogActions>
        )}
      </DialogCust>
    </div>
  );
};
export default InfoModal;

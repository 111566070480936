// @ts-ignore
import Select from 'react-virtualized-select'; // Install types for this lib and remove the ts ignore line
import styled from 'styled-components';

export const CorporateSelect = styled(Select)`
  margin-bottom: 10px;
`;

export const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.7rem;
`;

export const HeaderRow = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const CommonBgColor = {
  backgroundColor: '#005FBCeb !important'
};

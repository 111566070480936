import React, { useEffect, useState, Fragment } from 'react';
import Moment from 'moment';
import axios from 'axios';
import styled from 'styled-components';
import PaperContainer from '../../components/PaperContainer';
import { Badge, Button, Spinner, Modal, Form, OverlayTrigger } from 'react-bootstrap';
import { fetchCeresUsers, db, updateUser } from '../../services/firebase';
import * as R from 'ramda';
import { TIERS } from '../../types/tiers';
import { Edit, Trash } from 'react-feather';
import { toast } from 'react-toastify';
import DataTable, { createTheme } from 'react-data-table-component';
import ReactTooltip from 'react-tooltip';
import { API_BASE_URL, API_AUTH_HEADERS } from '../../util/api.util';

createTheme('solarized', {
  text: {
    primary: '#268bd2',
    secondary: '#2aa198',
  },
  background: {
    default: '#002b36',
  },
  context: {
    background: '#cb4b16',
    text: '#FFFFFF',
  },
  divider: {
    default: '#073642',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
});

const NotificationPageContainer = styled<any>(PaperContainer)`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const UserPanelHeader = styled.h3`
  padding: 0.75em 1em;
`;

const TierBadge = styled(Badge)``;

const ActionButton = styled(Button)`
  svg {
    width: 20px;
    height: 20px;
  }
  margin-right: 5px;
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const renderTierBadge = (tier: TIERS, BadgeComp = TierBadge) => {
  return R.cond<any, any>([
    [
      R.equals(TIERS.DAYPASS),
      () => (
        <BadgeComp pill variant="primary">
          DAYPASS
        </BadgeComp>
      ),
    ],
    [
      R.equals(TIERS.PLATINUM),
      () => (
        <BadgeComp pill variant="primary">
          PLATINUM
        </BadgeComp>
      ),
    ],
    [
      R.equals(TIERS.BASIC),
      () => (
        <BadgeComp pill variant="info">
          BASIC
        </BadgeComp>
      ),
    ],
    [
      R.equals(TIERS.FREE),
      () => (
        <BadgeComp pill variant="success">
          FREE
        </BadgeComp>
      ),
    ],
    [
      R.T,
      () => (
        <BadgeComp pill variant="danger">
          N/A
        </BadgeComp>
      ),
    ],
  ])(tier);
};

const renderLoadingSpinner = () => (
  <SpinnerContainer>
    <Spinner variant="primary" animation="border" />
  </SpinnerContainer>
);

const EditUserPanelBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const EditUserPanelItem = styled.div`
  margin-bottom: 30px;
`;
const EditUserPanel: React.FC<any> = (props: any) => {
  const user: any = props.user;
  const [currentTier, setCurrentTier] = useState<string>('');
  const [currentStatus, setCurrentStatus] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const updateUserInfo = () => {
    axios.post(
      API_BASE_URL + '/api/updateUserInfo',
      // 'http://localhost:5000/ceres-platform-test/us-central1/api/updateUserInfo',
      user,
      {
        headers: API_AUTH_HEADERS,
      }
    );
  };

  useEffect(() => {
    setCurrentTier((user && user.tier) || '');
  }, [user]);
  return (
    <Modal dialogClassName="ceres--subscription-list" show={props.show || false} onHide={props.onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit User</Modal.Title>
      </Modal.Header>
      <EditUserPanelBody>
        {!user ? (
          <></>
        ) : (
          <Form>
            <EditUserPanelItem>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Tier</Form.Label>
                <Form.Control
                  disabled={isLoading}
                  as="select"
                  value={currentTier}
                  onChange={(e: any) => setCurrentTier(e.target.value)}
                >
                  {R.map(
                    (tier: TIERS) => (
                      <option key={tier}>{tier}</option>
                    ),
                    Object.keys(TIERS).filter((c: any) => c !== TIERS.SUPER_ADMIN) as TIERS[]
                  )}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email Verified Status</Form.Label>
                <Form.Control
                  disabled={isLoading}
                  as="select"
                  value={currentStatus}
                  onChange={(e: any) => setCurrentStatus(e.target.value)}
                >
                  <option>Select Verified Status</option>
                  <option value="Verified">Verified</option>
                  <option value="Unverified">Unverified</option>
                </Form.Control>
              </Form.Group>
            </EditUserPanelItem>
          </Form>
        )}
      </EditUserPanelBody>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide} disabled={isLoading}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            setIsLoading(true);
            db.collection('users')
              .doc(user.uid)
              .update({
                tier: currentTier,
              })
              .then(() => {
                toast.success(`Saved user information successfully`);
                props.onSaveComplete({
                  ...user,
                  ...{ tier: currentTier },
                });
              })
              .catch(() => {
                toast.error(`Error while saving user information`);
              })
              .finally(() => {
                updateUserInfo();
                setIsLoading(false);
                if (currentStatus) {
                  updateUser({
                    uid: user.uid,
                    emailVerified: currentStatus === 'Verified' ? true : false,
                  }).then(() => {
                    setCurrentStatus('');
                    props.onSaveComplete({
                      ...user,
                      ...{ tier: currentTier, emailVerified: currentStatus === 'Verified' ? true : false },
                    });
                  });
                }
              });
          }}
        >
          {isLoading ? <Spinner variant="light" animation="border" /> : 'Save changes'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const TotalUsersDiv = styled.h5`
  margin: 0 1.2em 1em;
`;

const UsersPanel: React.FC = () => {
  const [currentUserList, setUserList] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentEditState, setEditState] = useState<any>(null);

  const userList = [...currentUserList];
  const columns = [
    {
      name: 'UID',
      selector: 'uid',
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
    },
    {
      name: 'Tier',
      selector: 'tier',
      cell: (row: any) => <Fragment>{renderTierBadge(row.tier)}</Fragment>,
    },
    {
      name: 'Display Name',
      selector: 'displayName',
      sortable: true,
    },
    {
      name: 'Last SignIn Time',
      selector: (row: any) => Moment(row.lastSignInTime).format(),
      cell: (row: any) => <span>{row.lastSignInTime}</span>,
      sortable: true,
    },
    {
      name: 'Email Verified Status',
      selector: (row: any) => Moment(row.lastSignInTime).format(),
      cell: (row: any) => (
        <span className={row.emailVerified ? 'badge badge-pill badge-success' : 'badge badge-pill badge-danger'}>
          {row.emailVerified ? 'Verified' : 'Unverified'}
        </span>
      ),
      sortable: true,
    },
    {
      name: 'Actions',
      selector: 'year',
      cell: (row: any) => (
        <Fragment>
           <OverlayTrigger
                overlay={<ReactTooltip place="bottom" effect="solid" />}
                >
              <ActionButton variant="secondary" onClick={() => setEditState(row)} data-tip="Edit User">
                <Edit />
              </ActionButton>
            </OverlayTrigger>
            <OverlayTrigger
                overlay={<ReactTooltip place="bottom" effect="solid" />}
                >
              <ActionButton
                variant="danger"
                data-tip="Delete User"
                onClick={() => {
                  db.collection('users')
                    .doc(row.uid)
                    .delete()
                    .then(() => {
                      setUserList(
                        R.filter((user: any) => {
                          return user.uid !== row.uid;
                        }, currentUserList)
                      );
                      toast.success(`Deleted user successfully: ${row.email}`);
                    })
                    .catch(() => toast.error(`Oops, something went wrong, while deleting the user`));
                }}
                >
                <Trash />
              </ActionButton>
            </OverlayTrigger>
        </Fragment>
      ),
    },
  ];
  const Datatable = () => <DataTable columns={columns} data={userList} pagination />;
  const loadCeresUsers = async () => {
    setIsLoading(true);
    const { data } = await fetchCeresUsers({});
    setUserList(data.records);
    setIsLoading(false);
  };
  useEffect(() => {
    loadCeresUsers();
    return;
  }, []);

  return (
    <NotificationPageContainer>
      <UserPanelHeader>Manage CERES Users</UserPanelHeader>
      {isLoading && renderLoadingSpinner()}
      <EditUserPanel
        show={!!currentEditState}
        user={currentEditState}
        onSaveComplete={(updatedUserInformation: any) => {
          const uid = updatedUserInformation.uid;
          setUserList(
            R.map((user: any) => {
              if (user.uid === uid) {
                return updatedUserInformation;
              }
              return user;
            }, currentUserList)
          );
          setEditState(null);
        }}
        onHide={() => setEditState(null)}
      />
      {!isLoading && <TotalUsersDiv>Total Users: {currentUserList.length || 0}</TotalUsersDiv>}
      {!isLoading &&
        // <Table responsive>
        //   <thead>
        //     <tr>
        //       <th>UID</th>
        //       <th>Email</th>
        //       <th>Tier</th>
        //       <th>Display Name</th>
        //       <th>Last SignIn Time</th>
        //       <th>Actions</th>
        //     </tr>
        //   </thead>
        //   <tbody>
        //     {R.map((userRecord: any) => {
        //       return (
        //         <tr key={userRecord.uid}>
        //           <td>{userRecord.uid}</td>
        //           <td>{userRecord.email}</td>
        //           <td>{renderTierBadge(userRecord.tier)}</td>
        //           <td>{userRecord.displayName}</td>
        //           <td>{userRecord.lastSignInTime}</td>
        //           <td>
        //             <ActionButton
        //               variant="secondary"
        //               onClick={() => setEditState(userRecord)}
        //             >
        //               <Edit />
        //             </ActionButton>
        //             <ActionButton
        //               variant="danger"
        //               onClick={() => {
        //                 db.collection('users')
        //                   .doc(userRecord.uid)
        //                   .delete()
        //                   .then(() => {
        //                     setUserList(
        //                       R.filter((user: any) => {
        //                         return user.uid !== userRecord.uid;
        //                       }, currentUserList)
        //                     );
        //                     toast.success(
        //                       `Deleted user successfully: ${userRecord.email}`
        //                     );
        //                   })
        //                   .catch(() =>
        //                     toast.error(
        //                       `Oops, something went wrong, while deleting the user`
        //                     )
        //                   );
        //               }}
        //             >
        //               <Trash />
        //             </ActionButton>
        //           </td>
        //         </tr>
        //       );
        //     }, currentUserList)}
        //   </tbody>
        // </Table>
        Datatable()}
      {/* { itemPaginator() }
    <Pagination>{items}</Pagination> */}
    </NotificationPageContainer>
  );
};

export default UsersPanel;

import styled from 'styled-components';

export const BenchmarkWrapper = styled.div`
  overflow: auto;
  height: 100vh;
`;

export const BenchmarkContainer = styled.div`
  margin: 4em;
`;

export const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  flex-direction: column;
`;

import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Card,
  Form,
  InputGroup,
  Modal,
  Spinner
} from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import styled, { keyframes } from 'styled-components';
import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import EditIcon from '@material-ui/icons/Edit';
import StarIcon from '@material-ui/icons/Star';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { saveLibrary } from '../../services/firebase';
import showToastMessage from '../../util/showToastMessage';
import { debounce } from 'lodash';

const chatAnimation = keyframes`
  0% { right: -50%; }
  100% { right: 0; }
`;

const ChatSummaryContainer = styled.div`
  font-size: 22px;
  position: fixed;
  top: 0;
  right: 0;
  width: 50%;
  z-index: 998;
  height: 100%;
  background-color: #f3f3f3;
  color: white;
  animation: ${chatAnimation} 1s 1 normal;
`;

const TitleContainer = styled.div`
  display: flex;
  background-color: #2c190e !important;
  font-family: 'Sans Pro';
  padding: 7px 7px;

  .title-head {
    margin: auto;
  }

  .icon-container {
    padding-right: 20px;
  }

  .close-library {
    position: absolute;
    font-size: 1.5rem;
    top: 12px;
    right: 0.75rem;
    color: #ffffff;
    border: none;
    background: none;
    cursor: pointer;
    font-family: monospace;
  }
`;

const LibraryBody = styled.div`
  height: 100%;
  .card-body {
    display: flex;
    align-items: center;
    padding: 0 10px;
  }

  button {
    font-family: 'Sans Pro';
  }

  .card-title {
    margin: 1rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .drag {
    cursor: move;
    margin-bottom: 5px;
  }
  .drag-icon:hover {
    cursor: move;
  }

  .library-list {
    padding: 0 0 1rem 1rem;
    overflow-y: scroll;
    height: calc(100% - 11rem);
    /* width */
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #d3d3d3;
      border-radius: 10px;
    }
  }
`;

const StyledTooltip = styled(ReactTooltip)`
  max-width: 25% !important;
`;

interface IChangedQuestion {
  libraryIndex: number;
  questionIndex: number;
  question: string;
}

interface IDeleteItem {
  title: string;
  index: number;
}

export default function SavedLibrary({
  closeSavedLibrary,
  onCopyText,
  libraryType,
  setLibraryFavorites,
  context
}: any) {
  const [library, setLibrary] = useState<any[]>([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isQuestionSaved, setIsQuestionSaved] = useState(false);
  const [changedQuestion, setChangedQuestion] = useState<IChangedQuestion | null>(null);
  const [newLibrary, setNewLibrary] = useState('');
  const [draggedItem, setDraggedItem] = useState<any>(null);
  const [totalLibraryItems, setTotalLibraryItems] = useState(0);
  const [loadMoreSpinner, setLoadMoreSpinner] = useState(false);
  const [showDeleteConfitmationPopup, setShowDeleteConfitmationPopup] =
    useState(false);
  const [deleteItem, setDeleteItem] = useState<IDeleteItem | null>(null);
  const [searchPrompt, setSearchPrompt] = useState<string>('');
  const [editableIndex, setEditableIndex] = useState<number | null>(null);
  const email = context.getUserEmail();

  useEffect(() => {
    // Fetch and set all library when the component mounts
    (async () => {
      try {
        await getAllLibrary();
      } catch (error) {
        if (error instanceof Error) {
          handleErrorMessage(error);
        }
      }
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Disable more item loader when all library questions load
    if (totalLibraryItems === library.length) {
      setLoadMoreSpinner(false);
    } else {
      setLoadMoreSpinner(true);
    }
    // eslint-disable-next-line
  }, [library, totalLibraryItems]);

  async function getAllLibrary(showSuccessMessage = true) {
    try {
      setShowSpinner(showSuccessMessage ? true : false);
      const response = await saveLibrary({
        type: 'getAll',
        pageSize: 15,
        libraryType,
        domain: email.split('@')[1]
      });

      if (response && response.data) {
        if (showSuccessMessage) {
          showToastMessage({
            type: 'info',
            title: 'Retrieved all library successfully.'
          });
        }
        if (response.data.totalItems > response.data.data.length) {
          setLoadMoreSpinner(true);
        }
        setLibrary(response.data.data);
        setTotalLibraryItems(response.data.totalItems);
      }
    } catch (error) {
      if (error instanceof Error) {
        handleErrorMessage(error);
      }
    } finally {
      setShowSpinner(false);
    }
  }

  /**
   * On scroll list
   * @param e
   * @param searchTerm
   * @returns
   */
  const scrollHandler = async (e: any, searchTerm?: string) => {
    if (searchTerm !== undefined) {
      setLibrary([]);
    }
    const promptListElement = document.getElementById('library-list');
    if (!!!promptListElement) {
      return;
    }
    const { scrollTop, scrollHeight, clientHeight } = promptListElement;
    const shouldLoadMore = scrollTop + 10 >= scrollHeight - clientHeight;

    if (
      (shouldLoadMore && library.length < totalLibraryItems) ||
      searchTerm !== undefined
    ) {
      try {
        const lastItemIndex = searchTerm ? undefined : library.length;
        const moreData: any = await saveLibrary({
          type: 'getAll',
          lastItemIndex,
          searchTerm: searchTerm || searchPrompt,
          pageSize: 15,
          libraryType,
          domain: email.split('@')[1]
        });

        if (moreData.data.data.length > 0) {
          setLibrary(library.concat(moreData.data.data));
        }

        if (searchTerm !== undefined) {
          setLibrary(moreData.data.data);
        } else {
          setLibrary(library.concat(moreData.data.data));
        }
        setTotalLibraryItems(moreData.data.totalItems);
      } catch (error) {
        if (error instanceof Error) {
          handleErrorMessage(error);
        }
      }
    }
  };

  async function onFavoriteClick(title: string, itemIndex: number) {
    setShowDeleteConfitmationPopup(false);
    setShowSpinner(true);
    setEditableIndex(null);

    const { payload, id, libraryType: localLibraryType  } = library[itemIndex];

    try {
      const response: any = await saveLibrary({
        type:  localLibraryType === 3 ? 'update' : 'remove',
        id,
        libraryType: localLibraryType === 3 ? (libraryType === 1 ? 2 : 1) : libraryType,
        record: { libraryData: payload.libraryData }
      });

      if (response) {
        showToastMessage({
          type: 'success',
          actionType: 'delete',
          title: 'Data removed successfully',
          description: `Removed library: ${title}`
        });
        setSearchPrompt('');
        getAllLibrary();
      }
    } catch (error) {
      if (error instanceof Error) {
        handleErrorMessage(error);
      }
    } finally {
      setShowSpinner(false);
    }
  }

  const updateQuestion = (
    libraryIndex: number,
    questionIndex: number,
    question: string
  ) => {
    setChangedQuestion({
      libraryIndex,
      questionIndex,
      question
    });
  };

  const saveUpdatedQuestion = async () => {
    if (!changedQuestion) {
      // Handle the case where changedQuestion is null
      return;
    }

    const { libraryIndex, questionIndex, question } = changedQuestion;
    const libraryPrompt = library[libraryIndex];

    if (!libraryPrompt) {
      // Handle the case where the library is not found
      return;
    }

    const updatedLibraryData = libraryPrompt.payload.libraryData.map(
      (q: any, i: number) => (questionIndex === i ? question : q)
    );

    try {
      setIsQuestionSaved(true);

      const response: any = await saveLibrary({
        type: 'update',
        id: libraryPrompt.id,
        record: { libraryData: updatedLibraryData }
      });

      if (response) {
        showToastMessage({
          type: 'success',
          actionType: 'update',
          title: 'Data updated successfully.',
          description: `Updated library: ${question}`
        });
        setChangedQuestion(null);
        libraryPrompt.payload.libraryData = updatedLibraryData;
      }
    } catch (error) {
      if (error instanceof Error) {
        handleErrorMessage(error);
      }
    } finally {
      setEditableIndex(null);
      setIsQuestionSaved(false);
    }
  };

  const addNewQuestion = async () => {
    try {
      setShowSpinner(true);
      const libraryData = [newLibrary];

      const response = await saveLibrary({
        type: 'create',
        record: { libraryData },
        domain: email.split('@')[1],
        libraryType
      });

      if (response && response.data) {
        showToastMessage({
          type: 'success',
          actionType: 'create',
          title: 'Data created successfully.',
          description: `Created new library: ${newLibrary}`
        });
        getAllLibrary();
      }
    } catch (error) {
      if (error instanceof Error) {
        handleErrorMessage(error);
      }
    } finally {
      setNewLibrary('');
      setShowSpinner(false);
    }
  };

  const onDragOver = (index: number) => {
    const draggedOverItem = library[index].id;

    // over itsself, ignore;
    if (draggedItem === draggedOverItem) {
      return;
    }
    // filter out the currently dragged item
    const items = library.filter(item => item && item.id !== draggedItem);
    // add the dragged item after the dragged over item
    items.splice(
      index,
      0,
      library.find(item => item.id === draggedItem)
    );
    setLibrary(items);
  };

  const onDragStart = (e: any, index: number) => {
    setDraggedItem(library[index].id);
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/html', e.target.parentNode);
    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
  };

  const onDragEnd = async (e: any, index: number) => {
    try {
      const startIndex = library.findIndex(item => item.id === draggedItem);
      const draggedFromData = library[startIndex];
      const draggedToData = library[index];

      // when swap with adjacent item
      if (index === startIndex + 1 || index === startIndex - 1) {
        await saveLibrary({
          type: 'update',
          id: draggedFromData.id,
          record: { order: draggedToData.order }
        });

        await saveLibrary({
          type: 'update',
          id: draggedToData.id,
          record: { order: draggedFromData.order }
        });
      } else if (startIndex > index) {
        // When drag from top to down
        const libraryData = [...library];
        const range = libraryData.slice(index, startIndex + 1); // Get the range of items to update

        // Extract the orders from the range and reverse them
        const orders = range
          .map(item => item.order)
          .sort()
          .reverse();

        // Apply the reversed orders back into the original data
        range.forEach((item, i) => {
          libraryData[index + i].order = orders[i]; // Set the new order
        });

        // Save the updated orders asynchronously using Promise.all
        await Promise.all(
          range.map(item =>
            saveLibrary({
              type: 'update',
              id: item.id,
              record: { order: item.order }
            })
          )
        );
      } else if (startIndex < index) {
        // When drag from bottom to up
        const libraryData = [...library];
        const range = libraryData.slice(startIndex, index + 1); // Get the range of items to update

        // Extract the orders from the range and reverse them
        const orders = range
          .map(item => item.order)
          .sort()
          .reverse();

        // Apply the reversed orders back into the original data
        range.forEach((item, i) => {
          libraryData[startIndex + i].order = orders[i]; // Set the new order
        });

        // Save the updated orders asynchronously using Promise.all
        await Promise.all(
          range.map(item =>
            saveLibrary({
              type: 'update',
              id: item.id,
              record: { order: item.order }
            })
          )
        );
      }

      showToastMessage({
        type: 'success',
        actionType: 'update',
        title: 'Data updated successfully.',
        description: `Updated librart order for ${draggedFromData.payload.libraryData[0]}`
      });
    } catch (error) {
      if (error instanceof Error) {
        handleErrorMessage(error);
      }
    } finally {
      getAllLibrary(false);
      setDraggedItem(null);
    }
  };

  // Handle catch error
  function handleErrorMessage(error: Error) {
    const { name, message } = error;
    showToastMessage({ type: 'error', title: name, description: message });
  }

  // Function to handle input change
  const handleChange = (event: any) => {
    setSearchPrompt(event.target.value);
  };

  // Function to handle debounced input change
  const handleDebouncedChange = useMemo(
    () =>
      debounce(value => {
        scrollHandler('', value);
      }, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Function to handle input change with debounce
  const handleInputWithDebounce = (event: any) => {
    handleChange(event); // Update state immediately
    handleDebouncedChange(event.target.value); // Trigger debounce function
  };

  function DeletePromptConfirmationModal(modalProps: any) {
    return (
      <Modal
        {...modalProps}
        animation={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Delete Library Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure, you want to delete{' '}
          <b>{deleteItem ? deleteItem.title : ''}</b> named library question?
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => onFavoriteClick(deleteItem!.title, deleteItem!.index)}
          >
            Delete
          </Button>
          <Button onClick={modalProps.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  function onDelete(title: string, index: number) {
    setDeleteItem({ title, index });
    setShowDeleteConfitmationPopup(true);
  }

  return (
    <ChatSummaryContainer>
      <DeletePromptConfirmationModal
        show={showDeleteConfitmationPopup}
        onHide={() => setShowDeleteConfitmationPopup(false)}
      />
      <TitleContainer>
        <button
          id="back-btn"
          className="btn btn-outline-light"
          onClick={() => closeSavedLibrary(false)}
        >
          <ArrowBackIosIcon fontSize="small" />
          Back
        </button>
        <div className="title-head title-head-fullscreen">
          <h2>{ libraryType === 1 ? 'Saved Library' : 'Private Library'}</h2>
        </div>

        <div className="icon-container">
          <button
            className="close-library"
            onClick={() => closeSavedLibrary(false)}
          >
            X
          </button>
        </div>
      </TitleContainer>
      <LibraryBody>
          <InputGroup className="p-3">
            <Form.Control
              className="rounded"
              placeholder="Type New Library..."
              name="library"
              value={newLibrary}
              onChange={(e: any) => setNewLibrary(e.target.value)}
            />
            <Button
              variant="outline-secondary"
              size="sm"
              className= "ml-2"
              id="profile-headline"
              onClick={() => {
                  addNewQuestion();
              }}
              disabled={showSpinner || !!!newLibrary}
            >
              Add Library
            </Button>
          </InputGroup>
        {/* Search input */}
        <InputGroup
          className= "px-3 pb-3"
        >
          <Form.Control
            className="rounded"
            placeholder="Search Chat..."
            name="savedChat"
            value={searchPrompt}
            onChange={handleInputWithDebounce}
          />
        </InputGroup>
        <div
          className="library-list"
          id="library-list"
          onScroll={debounce(scrollHandler, 1000)}
        >
          {showSpinner ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <>
              {library.length
                ? library.map(
                    (data: any, idx: number) =>
                      data &&
                      data.payload &&
                      data.payload.libraryData &&
                      data.payload.libraryData.map(
                        (title: string, i: number) => (
                          <li
                            className="d-flex mb-2 mr-1"
                            key={idx}
                            onDragOver={() => onDragOver(idx)}
                          >
                            <Card
                              key={i}
                              text="primary"
                              className="w-100"
                              draggable
                              onDragStart={(e: any) => onDragStart(e, idx)}
                              onDragEnd={(e: any) => onDragEnd(e, idx)}
                              style={{
                                borderColor:
                                  draggedItem && library[idx].id === draggedItem
                                    ? '#000'
                                    : 'white'
                              }}
                            >
                              <Card.Body>
                                <MenuIcon
                                  color="primary"
                                  className="drag-icon"
                                />
                                <Card.Title
                                  data-tip={title}
                                  data-for="title-tooltip"
                                  className={`fs-15 ${
                                    editableIndex === idx
                                      ? 'w-100 border border-3 rounded border-dark name'
                                      : 'w-auto mr-auto'
                                  }`}
                                  contentEditable={idx === editableIndex}
                                  dangerouslySetInnerHTML={{ __html: title }}
                                  onInput={(e: any) =>
                                    updateQuestion(idx, i, e.target.textContent)
                                  }
                                />
                                <StyledTooltip id="title-tooltip" place="bottom" effect="solid" />
                                {changedQuestion &&
                                  changedQuestion.libraryIndex === idx &&
                                  changedQuestion.questionIndex === i && (
                                    <>
                                      {isQuestionSaved && (
                                        <div>
                                          <Spinner
                                            animation="border"
                                            variant="secondary"
                                          />
                                        </div>
                                      )}
                                      <IconButton
                                        onClick={(e: any) =>
                                          saveUpdatedQuestion()
                                        }
                                        aria-label="fav-icon"
                                        component="span"
                                      >
                                        <SaveIcon
                                          style={{ color: '#0f6ecd' }}
                                        />
                                      </IconButton>
                                    </>
                                  )}
                                {editableIndex !== idx && (
                                  <IconButton
                                    data-tip="Edit Title"
                                    data-for="title-tooltip"
                                    onClick={() => setEditableIndex(idx)}
                                    aria-label="edit-icon"
                                    component="span"
                                  >
                                    <EditIcon color="primary" />
                                  </IconButton>
                                )}
                                <IconButton
                                  onClick={(e: any) => {
                                    onDelete(title, idx);
                                  }}
                                  aria-label="fav-icon"
                                  component="span"
                                >
                                  <StarIcon
                                    data-tip="Remove Library"
                                    data-for="title-tooltip"
                                    style={{ color: '#faaf00' }}
                                  />
                                </IconButton>
                              </Card.Body>
                            </Card>
                            <button
                              className="btn btn-outline-secondary ml-2 btn-sm text-nowrap"
                              onClick={() => onCopyText(title)}
                            >
                              Copy To Chat
                            </button>
                          </li>
                        )
                      )
                  )
                : !loadMoreSpinner &&
                  !showSpinner && (
                    <div className="d-flex justify-content-center mt-5 h3 text-dark">
                      No Data found
                    </div>
                  )}
              {loadMoreSpinner && (
                <div className="d-flex justify-content-center my-3">
                  <Spinner animation="border" variant="secondary" />
                </div>
              )}
            </>
          )}
        </div>
      </LibraryBody>
    </ChatSummaryContainer>
  );
}

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router-dom';
import { IAuthContext } from '../context/auth/auth-context';
import withAuthContext from '../context/auth/AuthConsumer';
import PaperContainer from '../components/PaperContainer';
import { Doughnut } from 'react-chartjs-2';
import axios from 'axios';
import { CeresLogger } from '../logger';
// import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// @ts-ignore
import createFilterOptions from 'react-select-fast-filter-options';
import './react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import { Row, Col } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import queryString from 'query-string';
import { CorporateSelect, HeaderRow, ResultContainer } from '../components/custom-styled-components/CommonStyledComponents';
import { API_BASE_URL, API_AUTH_HEADERS } from '../util/api.util';
// import any from 'ramda/es/any';

const GeneratorWrapper = styled.div`
  width: 98%;
`;

const GeneratorContainer = styled.div`
  margin: 6em;
`;

const StyledDataTable = styled(DataTable)`
  & > * {
    height: auto;
  }
  & .rdt_TableCell {
    font-size: 10px;
    padding: 3px;
    justify-content: center;
  }
  & .rdt_TableCol {
    justify-content: center;
  }
  /* & .sc-kxynE:first-child {
    background-color: orange ;
  } */
  & .rdt_TableBody {
    overflow-y: initial;
  }
  & .rdt_TableHeader {
    min-height: 0px;
  }
  & .rdt_TableRow {
    min-height: 30px;
  }
  & .rdt_TableHeadRow {
    min-height: 30px;
    background-color: lavender;
  }
  & .rdt_Pagination {
    justify-content: center;
  }
`;
const TableContainer = styled.div`
  margin-top: 1rem;
  width: 92vw;
`;

const TableHeading = styled.div`
  display: flex;
  justify-content: center;
  background-color: lightgray;
`;

const H3 = styled.div`
  text-align: center;
  text-transform: capitalize;
  font-weight: bold;
`;

const ERC = styled.div`
  text-align: center;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 2em;
`;

const H1 = styled.div`
  text-align: left;
  text-transform: capitalize;
  font-weight: bold;
`;

const Header = styled.div`
  display: flex;
  padding-bottom: 1%;
  font-size: 20px;
`;

const GeneratorNotes = styled.div`
  margin: 2em 10em;
  padding-bottom: 0%;
  font-size: 11px;
  font-weight: bold;
`;

const P = styled.div`
  margin-bottom: 0.1rem;
`;

const Generator: React.FC<
  RouteComponentProps<any> & {
    context: IAuthContext;
  }
> = (props) => {
  const vocObjects = [
    'voc_fugitive_controlled_table',
    'voc_stack_uncontrolled_table',
    'voc_fugitive_uncontrolled_table',
  ];
  const noxObjects = [
    'nox_boilers_heaters_controlled_table',
    'nox_engines_turbines_controlled_table',
    'nox_boilers_heaters_uncontrolled_table',
  ];
  const vocLabels = ['VOC Controlled', 'VOC Uncontrolled Stack', 'VOC Uncontrolled Fugutive'];
  const noxLabels = ['NOx Controlled Burners', 'NOx Engines & Turbines', 'NOx Uncontrolled Burners'];
  const [chartType, setChartType] = useState();
  const [activeData, setActiveData] = useState([]);
  const [chartIndex, setChartIndex] = useState();
  const [options, setOptions] = useState();
  const [entity, setEntity] = useState();
  const [filter, setFilter] = useState();
  const [statsResult, setStatsResult] = useState();
  const [isDisabled, setIsDisabled] = useState(true);
  const getGenNames = () => {
    try {
      axios
        .get(
          API_BASE_URL + '/api/eventEmissions/genNames',
          // 'http://localhost:5000/ceres-platform-test/us-central1/api/eventEmissions/genNames',

          {
            headers: API_AUTH_HEADERS,
          }
        )
        .then((response) => {
          const resultRecords = response.data[0].jsonlistb;
          setFilter(() => createFilterOptions({ options: resultRecords }));
          setOptions(resultRecords);
          const filterData: any = queryString.parse(props.history.location.search);
          if (filterData.rn) {
            const entityFilterRecord = searchParam(resultRecords, filterData.rn);
            setEntity(entityFilterRecord);
            fetchGenStats(entityFilterRecord.value);
          }
          setIsDisabled(false);
        })
        .catch((err) => {
          CeresLogger.error(err);
        })
        .finally();
    } catch (e) {
      CeresLogger.error(e);
    }
  };
  const searchParam = (data: any, value: any): any => {
    for (const aData of data) {
      if (aData.value === value) {
        return aData;
      }
    }
  };
  let vocEvents = {};
  let noxEvents = {};
  let dataOptions = {};
  const fetchGenStats = (value: any) => {
    setIsDisabled(true);
    try {
      axios
        .post(
          API_BASE_URL + '/api/fetchGenStats',
          // 'http://localhost:5000/ceres-platform-test/us-central1/api/fetchGenStats',
          { RN: value },
          {
            headers: API_AUTH_HEADERS,
          }
        )
        .then((response) => {
          setActiveData([]);
          setStatsResult(response.data[0].ero_json_2018);
          setIsDisabled(false);
        })
        .catch((err) => {
          CeresLogger.error(err);
        })
        .finally();
    } catch (e) {
      CeresLogger.error(e);
    }
  };

  const chartCalculations = (first: number, second: number, third: number): any => {
    if (first < 1 || second < 1 || third < 1) {
      const maxNum = Math.max(first, second, third);
      if (maxNum > 0) {
        if (first < 1 && first > 0) {
          first = 1;
        }
        if (second < 1 && second > 0) {
          second = 1;
        }
        if (third < 1 && third > 0) {
          third = 1;
        }
        if (first === maxNum) {
          first = 100 - second - third;
        } else if (second === maxNum) {
          second = 100 - first - third;
        } else if (third === maxNum) {
          third = 100 - first - second;
        }
      }
    }
    return { first, second, third };
  };

  const Chart = require('react-chartjs-2').Chart;
  const originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
  Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
    draw() {
      originalDoughnutDraw.apply(this, arguments);

      const chart = this.chart;
      const width = chart.width;
      const height = chart.chartArea.bottom;
      const ctx = chart.ctx;
      const fontSize = (height / 500).toFixed(2);
      ctx.font = `${fontSize}em sans-serif`;
      ctx.textBaseline = 'middle';
      ctx.color = '#000';
      const total = chart.config.data.total;
      const text = total + ` TONS`;
      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 2;

      ctx.fillText(text, textX, textY);
    },
  });
  if (statsResult) {
    const vocPercentage = chartCalculations(
      statsResult.voc_pie.voc_controlled_percentage,
      statsResult.voc_pie.voc_stack_uncontrolled_percentage,
      statsResult.voc_pie.voc_fugitive_uncontrolled_percentage
    );

    vocEvents = {
      labels: ['Controlled', 'Uncontrolled Stack', 'Uncontrolled Fugutive'],
      total: statsResult.voc_pie.voc_total_tons,
      original: [
        statsResult.voc_pie.voc_controlled_percentage,
        statsResult.voc_pie.voc_stack_uncontrolled_percentage,
        statsResult.voc_pie.voc_fugitive_uncontrolled_percentage,
      ],
      datasets: [
        {
          label: 'Voc Events',
          backgroundColor: ['#00BFFF', '#FF0000', '#FF6961'],
          hoverBorderColor: 'Black',
          hoverBorderWidth: '5px',
          weight: 9,
          data: [vocPercentage.first, vocPercentage.second, vocPercentage.third],
        },
      ],
    };

    const noxPercentage = chartCalculations(
      statsResult.nox_pie.nox_boilers_heaters_controlled_percentage,
      statsResult.nox_pie.nox_engines_turbines_controlled_percentage,
      statsResult.nox_pie.nox_boilers_heaters_uncontrolled_percentage
    );

    noxEvents = {
      labels: ['Controlled Burners', 'Engines & Turbines', 'Uncontrolled Burners'],
      total: statsResult.nox_pie.nox_total_tons,
      original: [
        statsResult.nox_pie.nox_boilers_heaters_controlled_percentage,
        statsResult.nox_pie.nox_engines_turbines_controlled_percentage,
        statsResult.nox_pie.nox_boilers_heaters_uncontrolled_percentage,
      ],
      datasets: [
        {
          label: 'Nox Events',
          backgroundColor: ['#00BFFF', '#808080', '#FF0000'],
          hoverBorderColor: 'Black',
          hoverBorderWidth: '5px',
          weight: 9,
          data: [noxPercentage.first, noxPercentage.second, noxPercentage.third],
        },
      ],
    };
    dataOptions = {
      responsive: true,
      maintainAspectRatio: true,
      cutoutPercentage: 40,
      legend: {
        position: 'bottom',
        onClick: null,
      },
      tooltips: {
        bodyAlign: 'center',
        bodyFontSize: 15,
        bodySpacing: 10,
        callbacks: {
          title: (tooltipItem: any, data: any) => {
            return '';
          },
          label: (tooltipItem: any, data: any) => {
            return data.labels[tooltipItem.index];
          },
          afterLabel: (tooltipItem: any, data: any) => {
            const percent = data.original[tooltipItem.index];
            let total: string = ((data.total / 100) * percent).toFixed(2);
            if (total === '0.00') {
              total = '< ' + 0.01;
            }
            return total + ' tons\n' + percent + '%';
          },
        },
      },
    };
  }
  useEffect(() => {
    getGenNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const vocData = [...activeData];
  const columns = [
    {
      name: 'FIN',
      selector: 'FIN',
    },
    {
      name: 'FIN Name',
      selector: 'FIN Name',
    },
    {
      name: 'FIN Group',
      selector: 'FIN Group',
    },
    {
      name: 'FIN Profile',
      selector: 'FIN Profile',
    },
    {
      name: 'EPN',
      selector: 'EPN',
    },
    {
      name: 'EPN Name',
      selector: 'EPN Name',
    },
    {
      name: 'EPN Profile',
      selector: 'EPN Profile',
    },
    {
      name: 'Year',
      selector: 'Year',
    },
    {
      name: 'VOC',
      selector: 'VOC',
    },
    {
      name: 'CIN',
      selector: 'CIN',
    },
    {
      name: 'CIN Name',
      selector: 'CIN Name',
    },
    {
      name: 'CIN Abatement Type',
      selector: 'CIN Abatement Type',
    },
    {
      name: 'Number of Units',
      selector: 'Number of Units',
    },
    {
      name: 'VOC Percent',
      selector: 'voc_percent',
    },
  ];
  const noxData = [...activeData];
  const noxColumns = [
    {
      name: 'FIN',
      selector: 'FIN',
    },
    {
      name: 'FIN Name',
      selector: 'FIN Name',
    },
    {
      name: 'FIN Group',
      selector: 'FIN Group',
    },
    {
      name: 'FIN Profile',
      selector: 'FIN Profile',
    },
    {
      name: 'EPN',
      selector: 'EPN',
    },
    {
      name: 'EPN Name',
      selector: 'EPN Name',
    },
    {
      name: 'Year',
      selector: 'Year',
    },
    {
      name: 'NOx',
      selector: 'NOx',
    },
    {
      name: 'CIN Abatement Type',
      selector: 'CIN Abatement Type',
    },
    // {
    //   name: 'NOx Percent',
    //   selector: 'nox_percent'
    // },
    {
      name: 'Heat Input',
      selector: 'Heat Input',
    },
    {
      name: 'Heat Input Units',
      selector: 'Heat Input Units',
    },
    {
      name: 'NOx Emission factor',
      selector: 'NOx Emission factor',
    },
    {
      name: 'Factor Units',
      selector: 'Factor Units',
    },
  ];

  return (
    <GeneratorWrapper>
      <GeneratorContainer>
        <Header>
          <H1>Potential Emissions Reduction Opportunities</H1>
        </Header>
        <HeaderRow>
          {!!isDisabled && (
            <>
              <div>
                <Spinner animation="border" variant="dark" size="sm" />
              </div>
            </>
          )}
        </HeaderRow>
        <PaperContainer>
          <CorporateSelect
            value={entity}
            className="basic-single"
            filterOptions={filter}
            disabled={isDisabled}
            name="corporate1"
            options={options}
            onChange={(e: any) => {
              setEntity(e);
              if (e) {
                fetchGenStats(e.value);
              }
            }}
          />
        </PaperContainer>
        <PaperContainer>
          {statsResult && (
            <ResultContainer>
              <Row>
                <Col md={12}>
                  <ERC>ERC Value: ${statsResult.erc_value.amount}* </ERC>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <H3>VOC</H3>
                  <Doughnut
                    data={vocEvents}
                    options={dataOptions}
                    onElementsClick={(elems) => {
                      if (elems[0]) {
                        setActiveData(statsResult[vocObjects[elems[0]._index]]);
                        setChartIndex(elems[0]._index);
                        setChartType('voc');
                      }
                    }}
                  />
                </Col>
                <Col md={6}>
                  <H3>NOx</H3>
                  <Doughnut
                    data={noxEvents}
                    options={dataOptions}
                    onElementsClick={(elems) => {
                      if (elems[0]) {
                        setActiveData(statsResult[noxObjects[elems[0]._index]]);
                        setChartIndex(elems[0]._index);
                        setChartType('nox');
                      }
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <GeneratorNotes>
                  <P>*Based on Texas Commission on Environmental Quality 2018 Emissions Inventory Information</P>
                  <P>
                    *Uncontrolled / Undercontrolled based on <span>&#8805;</span> 0.02 NOx control,<span>&#60;</span>
                    70% VOC stack control and <span>&#60;</span>70% VOC fugitive control
                  </P>
                  <P>
                    *ERC value based on $100k assuming additional 50% reduction of uncontrolled fugitives, 99% reduction
                    of controlled stack and 75% reduction of uncontrolled burners{' '}
                  </P>
                </GeneratorNotes>
              </Row>
            </ResultContainer>
          )}
        </PaperContainer>
        <PaperContainer>
          {activeData.length > 0 && chartType === 'voc' && (
            <ResultContainer>
              <TableHeading>
                <h6>{vocLabels[chartIndex]}</h6>
              </TableHeading>
              <TableContainer>
                <StyledDataTable
                  columns={columns}
                  data={vocData}
                  pagination
                  striped={true}
                  highlightOnHover={true}
                  responsive={true}
                />
              </TableContainer>
            </ResultContainer>
          )}
          {activeData.length > 0 && chartType === 'nox' && (
            <ResultContainer>
              <TableHeading>
                <h6>{noxLabels[chartIndex]}</h6>
              </TableHeading>
              <TableContainer>
                <StyledDataTable
                  columns={noxColumns}
                  data={noxData}
                  pagination
                  striped={true}
                  highlightOnHover={true}
                  responsive={true}
                />
              </TableContainer>
            </ResultContainer>
          )}
        </PaperContainer>
      </GeneratorContainer>
    </GeneratorWrapper>
  );
};

export default withAuthContext(Generator);

import React, { useEffect, useState } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { Table } from 'react-bootstrap';

import CompetitorPopup from '../benchmarking/CompetitorSelection'; // Import the popup component
import { ResultContainer } from '../custom-styled-components/CommonStyledComponents';
import { IEnforcement, IViolation, IEnforcementsData } from '../../types/enforcement';
import { BarHeading, TableHeading, TableContainer, TB, TR, TD } from '../custom-styled-components/EnforcementStyledComponents';
import { backgroundColors, chartOptions, enforcementChartLabels, tableColumns } from '../../util/enforcement.util';

const EnforcementEvent: React.FC<IEnforcement> = (props) => {
  const [violations, setViolations] = useState<IViolation[]>([]);
  const [enforcements, setEnforcements] = useState<IEnforcementsData | null>(null);
  const [cnStatsEnforcements, setCnStatsEnforcements] = useState<IEnforcementsData | null>(null);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [loadingApis, setLoadingApis] = useState<number>(0);
  const [loadingFacilityData, setLoadingFacilityData] = useState<boolean>(true);
  const [loadingCompanyData, setLoadingCompanyData] = useState<boolean>(true);
  const [label, setSelectedLabel] = useState<string | undefined>();

  const createEnforcementData = (enforcementList: any[]): { labels: string[], datasets: any[] } => {
    if (!Array.isArray(enforcementList) || enforcementList.length === 0) {
      return { labels: enforcementChartLabels, datasets: [] };
    }

    const sortedEnforcements = enforcementList
      .sort((a, b) => parseInt(b.Year || '0', 10) - parseInt(a.Year || '0', 10))
      .slice(0, 3);

    const datasets = sortedEnforcements.map((entry, index) => ({
      label: entry.Year || 'Unknown Year',
      backgroundColor: backgroundColors[index % backgroundColors.length],
      data: [
        parseInt(entry.NOVs || '0', 10),
        parseInt(entry.NOEs || '0', 10),
        parseInt(entry['Agreed Orders'] || '0', 10),
        parseInt(entry.Complaints || '0', 10),
      ],
    }));

    return { labels: enforcementChartLabels, datasets };
  };

  useEffect(() => {
    setLoadingApis(0);

    if (props.result && props.result.length) {
      setLoadingFacilityData(true);
      setEnforcements({ facilityData: createEnforcementData(props.result) });
      setTimeout(() => setLoadingFacilityData(false), 2000);
    }

    if (props.cnStatsResult && props.cnStatsResult.length) {
      setLoadingCompanyData(true);
      setCnStatsEnforcements({ facilityData: createEnforcementData(props.cnStatsResult) });

      const processedViolations = props.cnStatsResult.map((item: any) => ({
        CNName: props.cnStatsResult.cnName || 'Unknown',
        Year: item.Year || 'N/A',
        Complaints: parseInt(item.Complaints, 10) || 0,
        NOVs: parseInt(item.NOVs, 10) || 0,
        NOEs: parseInt(item.NOEs, 10) || 0,
        CatA: parseInt(item['Cat. A Violation'], 10) || 0,
        CatB: parseInt(item['Cat. B Violation'], 10) || 0,
        CatC: parseInt(item['Cat. C Violation'], 10) || 0,
        NumberofViolations: parseInt(item['Number of Violations'], 10) || 0,
        Orders: parseInt(item['Agreed Orders'], 10) || 0,
        assessedAmount: parseInt(item['Penalty Assessed'], 10) || 0,
      }));

      const mergedViolations = mergeViolations(processedViolations);
      setViolations(mergedViolations);
      setTimeout(() => setLoadingCompanyData(false), 2000);
    }
  }, [props.result, props.cnStatsResult]);

  const mergeViolations = (data: IViolation[]) => {
    const mergedMap = new Map<string, IViolation>();
    data.forEach((item) => {
      if (mergedMap.has(item.CNName)) {
        const existing = mergedMap.get(item.CNName)!;
        existing.Year += `, ${item.Year}`;
        existing.Complaints += item.Complaints;
        existing.NOVs += item.NOVs;
        existing.NOEs += item.NOEs;
        existing.CatA += item.CatA;
        existing.CatB += item.CatB;
        existing.CatC += item.CatC;
        existing.NumberofViolations += item.NumberofViolations;
        existing.Orders += item.Orders;
        existing.assessedAmount += item.assessedAmount;
      } else {
        mergedMap.set(item.CNName, { ...item });
      }
    });
    return Array.from(mergedMap.values());
  };

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
    setSelectedLabel(props.entity.label);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handlePopupResult = (data: any) => {
    const mergedData = mergeEnforcementData(data);
    // Extract violations data
    const newViolations = mergedData.flatMap((item: any) =>
      item.response.data.flatMap((dataItem: any) =>
        dataItem.enforcement_list.map((enforcement: any) => ({
          CNName: dataItem['CN Name'] || 'Unknown',
          Year: enforcement.Year || 'N/A',
          Complaints: enforcement.Complaints || 0,
          NOVs: enforcement.NOVs || 0,
          NOEs: enforcement.NOEs || 0,
          CatA: enforcement['Cat. A Violation'] || 0,
          CatB: enforcement['Cat. B Violation'] || 0,
          CatC: enforcement['Cat. C Violation'] || 0,
          NumberofViolations: enforcement['Number of Violations'] || 0,
          Orders: enforcement['Agreed Orders'] || 0,
          assessedAmount: enforcement['Penalty Assessed'] || 0,
        })) || []
      ) || []
    );

    setViolations(newViolations);
  };

  const mergeEnforcementData = (data: any[]) => {
    return data.map(({ rn, cn, response }) => {
      const mergedEnforcement = response.data.map((item: any) => {
        const enforcementAccumulator = item.enforcement_list.reduce(
          (acc: any, curr: any) => {
            acc.YearSet.add(curr.Year);
            Object.keys(curr).forEach((key) => {
              if (key !== 'Year') {
                acc[key] = (acc[key] || 0) + (parseInt(curr[key], 10) || 0);
              }
            });
            return acc;
          },
          {
            'YearSet': new Set<string>(),
            'Complaints': 0,
            'NOVs': 0,
            'NOEs': 0,
            'Cat. A Violation': 0,
            'Cat. B Violation': 0,
            'Cat. C Violation': 0,
            'Number of Violations': 0,
            'Agreed Orders': 0,
            'Civil Judgments': 0,
            'Penalty Assessed': 0,
          }
        );

        return {
          ...item,
          enforcement_list: [
            {
              ...enforcementAccumulator,
              Year: Array.from(enforcementAccumulator.YearSet).join(', '),
            },
          ],
        };
      });

      return { rn, cn, response: { data: mergedEnforcement } };
    });
  };

  return loadingApis > 0 ? (
    <Spinner animation="border" variant="primary" className="d-flex mx-auto" />
  ) : (
    <ResultContainer>
      <CompetitorPopup isOpen={isPopupOpen} onClose={handleClosePopup} onResult={handlePopupResult} selectedLabel={label} />
      <div style={{ padding: '20px' }}>
        <Row>
          {enforcements && (
            <Col md={6}>
              <BarHeading>Facility-Level Enforcement</BarHeading>
              <div style={{ height: '400px' }}>
                {loadingFacilityData ? (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : (
                  enforcements && <Bar data={enforcements.facilityData} options={chartOptions} />
                )}
              </div>
            </Col>
          )}
          {cnStatsEnforcements && (
            <Col md={6}>
              <BarHeading>Company-Wide Enforcement</BarHeading>
              <div style={{ height: '400px' }}>
                {loadingCompanyData ? (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : (
                  cnStatsEnforcements && <Bar data={cnStatsEnforcements.facilityData} options={chartOptions} />
                )}
              </div>
            </Col>
          )}
        </Row>
      </div>
      {loadingCompanyData ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '400px',
          }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        cnStatsEnforcements && (
          <TableContainer>
            <Row>
              <Col>
                <TableHeading>
                  <div>
                    <h5 style={{ fontSize: '30px', margin: '0px' }}>
                      Comparison with Competitors
                    </h5>
                    <p>{`(over past 3 years)`}</p>
                  </div>
                  <div>
                    <TB onClick={handleOpenPopup}>Select Competitor to Add to List</TB>
                  </div>
                </TableHeading>
                <Table bordered hover>
                  <thead>
                    <TR>
                      {tableColumns.map((col) => (
                        <th key={col.key}>{col.heading}</th>
                      ))}
                    </TR>
                  </thead>
                  <tbody>
                    {violations.map((ele, rowIndex) => {
                      const rowBgColor = rowIndex % 2 === 0 ? '#f7d5cd' : '#fbebe8';
                      return (
                        <tr key={rowIndex} style={{ backgroundColor: rowBgColor }}>
                          {tableColumns.map((col, colIndex) => (
                            <TD key={colIndex}>{ele[col.key]}</TD>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </TableContainer>
        )
      )}
    </ResultContainer>
  );
};
export default EnforcementEvent;

import { IElasticResult, ISearchRecord } from '../../types/search';
import React, { useEffect, useRef, useState } from 'react';

import AdvancedSearchFilter from '../../components/ai-earth-components/AdvancedSearchFilter';
import AiAirContainer from '../../components/ai-earth-components/AiAirContainer';
import { CeresLogger } from '../../logger';
import Footer from '../../components/Footer';
import { IAuthContext } from '../../context/auth/auth-context';
import { IDatabase } from '../../store/aiearth/types';
import ProgramQuery from '../../components/ai-earth-components/ProgramQuery';
import SearchForm from '../../components/ai-earth-components/SearchForm';
import { TIERS } from '../../types/tiers';
import axios from 'axios';
import { dummyRecord } from '../../types/search';
import { initialStateDatabase } from '../../store/aiearth/reducer';
import { search } from '../../services/firebase';
import styled from 'styled-components';
import withAuthContext from '../../context/auth/AuthConsumer';
import { API_BASE_URL, API_AUTH_HEADERS } from '../../util/api.util';

interface ICeresHome {
  tier: TIERS;
}

const SearchContainer = styled.div<ICeresHome>`
  padding-top: 0.75rem;
  justify-content: center;
  flex-direction: column;
  display: flex;
  background: ${props => {
    switch (props.tier) {
      case TIERS.PLATINUM:
        return `#fff`;
      case TIERS.FREE:
        return `var(--secondary)`;
      case TIERS.BASIC:
        return `var(--dark)`;
      default:
        break;
    }
    return `#fff`;
  }};
  }
  // For iPhone 6, 7, 8 landscape only.
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: landscape) {
    min-height: 600px;
    justify-content: flex-start;
  }
`;

const SearchResultMainContainer = styled(SearchContainer)`
  background: #fff;
  flex: 1;
  height: calc(100vh - 130px);
`;

const applyFilterToQuery = (query: any, filter: any = {}) => {
  const innerQuery = query.query || {};
  let releaseDateFilter = {};
  if (filter.rnNumber) {
    innerQuery.terms = Object.assign(innerQuery.terms || {}, {
      regulated_entity_number: filter.rnNumber
    });
  }
  if (filter.cityName) {
    innerQuery.terms = Object.assign(innerQuery.terms || {}, {
      city_name: filter.cityName
    });
  }
  if (filter.category) {
    if (filter.category === 'other') {
      innerQuery.mustNot = {};
      innerQuery.mustNot.matchQuery = Object.assign(
        {},
        {
          record_series_name: 'Air WS WATER WQ WST WASTE'
        }
      );
    } else {
      innerQuery.terms = Object.assign(innerQuery.terms || {}, {
        record_series_name: filter.category
      });
    }
  }
  if (filter.entityName) {
    innerQuery.terms = Object.assign(innerQuery.terms || {}, {
      regulated_entity_name: filter.entityName
    });
  }
  if (filter.zipCode) {
    innerQuery.terms = Object.assign(innerQuery.terms || {}, {
      zip_code: filter.zipCode
    });
  }
  if (!filter.showEmissionEvents) {
    innerQuery.inclusions = Object.assign(
      {},
      {
        showEmissionEvents: true
      }
    );
  }
  if (filter.fromReleaseDate) {
    releaseDateFilter = Object.assign(releaseDateFilter, {
      gte: filter.fromReleaseDate.format('DD/MM/YYYY'),
      key: 'release_date'
    });
  }
  if (filter.toReleaseDate) {
    releaseDateFilter = Object.assign(releaseDateFilter, {
      lte: filter.toReleaseDate.format('DD/MM/YYYY'),
      key: 'release_date'
    });
  }
  if (Object.keys(releaseDateFilter).length > 0) {
    innerQuery.dateFilters = (innerQuery.dateFilters || []).concat([releaseDateFilter]);
  }
  return { query: innerQuery };
};

const CeresHome: React.FC<{ context: IAuthContext }> = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState();
  const [masters, setMasters] = useState();
  const [programQueryResultCount, setProgramQueryResultCount] = useState();
  const [searchResult, setSearchResult] = useState<IElasticResult<ISearchRecord> | null>(null);
  const [programQueryResult, setProgramQueryResult] = useState<ISearchRecord[]>();
  const [activeMarkerRecord, setActiveMarkerRecord] = useState<ISearchRecord | null>(null);
  const [showFilter, setShowFilter] = useState(false);
  const [showPanel, setShowPanel] = useState(false);
  const [showDocPanel, setShowDocPanel] = useState(false);
  const [showProgramQueryPanel, setShowProgramQueryPanel] = useState(false);
  const [showProgramQueryDocPanel, setShowProgramQueryDocPanel] = useState(false);
  const [currentQueryText, setCurrentQueryText] = useState('');
  const [programQueryText, setProgramQueryText] = useState<IDatabase>(initialStateDatabase);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const context = props.context;
  const [airEventTableOpen, setAirEventTableOpen] = useState(false);

  const doTextSearch = (queryText: string = '', from = 0) => {
    setIsLoading(true);
    return search(applyFilterToQuery({ query: { from, text: queryText, size: 50 } }, filter || {}))
      .then(result => {
        const data = result.data as IElasticResult<ISearchRecord>;
        setSearchResult(data);
      })
      .finally(() => setIsLoading(false));
  };

  const doProgramSearch = (queryText: any, from = 0) => {
    const requestBody: { [x: string]: never[] } = {};
    Object.keys(queryText).forEach(key => {
      if (!!queryText[key]) {
        if (key === 'cityName') {
          requestBody.cities = queryText[key].split(',');
        } else if (key === 'entityName') {
          requestBody.entityNames = queryText[key].split(',');
        } else if (key === 'rnNumber') {
          requestBody.entityNumbers = queryText[key].split(',');
        } else if (key === 'zipCode') {
          requestBody.zipCodes = queryText[key].split(',');
        } else if (key === 'street') {
          requestBody.street = ('%' + queryText[key] + '%') as any;
        } else if (key === 'mapOptions') {
          requestBody.mapOptions = queryText[key];
        } else if (key === 'offset') {
          requestBody.offset = queryText[key];
        } else if (key === 'sortDirection') {
          requestBody.sortDirection = queryText[key];
        } else if (key === 'sortColumn') {
          requestBody.sortColumn = queryText[key];
        } else if (key === 'source') {
          if (!!queryText[key]) {
            const sourceArray = queryText[key].map((el: any) => {
              return el.value;
            });
            requestBody.source = sourceArray;
          }
        }
      }
    });
    try {
      setIsLoading(true);
      axios
        .post(
          API_BASE_URL + '/api/document-entity/list',
          // 'http://localhost:5000/ceres-platform-test/us-central1/api/document-entity/list',
          requestBody,
          {
          headers: API_AUTH_HEADERS,
          }
        )
        .then(response => {
          setIsLoading(false);
          const resultRecords = response.data.entityDetails;
          const programQueryRecords: ISearchRecord[] = [];
          resultRecords.forEach((record: any) => {
            const tempRecord: ISearchRecord = Object.assign({}, dummyRecord);
            tempRecord.cityName = record.city;
            tempRecord.contentId = record.id;
            tempRecord.entityName = record.entityName;
            tempRecord.entityNumber = record.entityNumber;
            tempRecord.entitySource = record.entitySource;
            tempRecord.secondaryId = record.secondaryId;
            tempRecord.address = record.address;
            // Ensure `location` has all required properties
            tempRecord.location = {
              lat: record.geom ? record.geom.coordinates[1] : 0,
              lon: record.geom ? record.geom.coordinates[0] : 0,
              geomz: record.geomz || '',
              geom_json: record.geom_json || null,
            };
            if (record.geom) {
              programQueryRecords.push(tempRecord);
            }
          });
          setProgramQueryResult(programQueryRecords);
          setProgramQueryResultCount(response.data.count);
          setShowProgramQueryPanel(true);
          setShowPanel(false);
          setShowDocPanel(false);
          setActiveMarkerRecord(null);
          setShowProgramQueryDocPanel(false);
        })
        .catch((err) => {
          CeresLogger.error(err);
          setIsLoading(false);
        })
        .finally(() => setIsLoading(false));
    } catch (e) {
      CeresLogger.error(e);
    }
  };

  const renderDefaultSearch = () => {
    return (
      <>
        <AiAirContainer
          onPreviousClick={() => {
            if (!searchResult) {
              return;
            }
            const currentFrom = searchResult.from;
            const currentSize = searchResult.size;
            setIsLoading(true);
            return doTextSearch(currentQueryText, currentFrom - currentSize);
          }}
          onNextClick={() => {
            if (!searchResult) {
              return;
            }
            const currentFrom = searchResult.from;
            const currentSize = searchResult.size;
            return doTextSearch(currentQueryText, currentFrom + currentSize);
          }}
          programQueryNextClick={() => {
            const newFilter = {
              ...programQueryText,
              offset: programQueryText.offset + 50
            };
            setProgramQueryText(newFilter);
            doProgramSearch(newFilter);
          }}
          programQueryPreviousClick={() => {
            const newFilter = {
              ...programQueryText,
              offset: programQueryText.offset - 50
            };
            setProgramQueryText(newFilter);
            doProgramSearch(newFilter);
          }}
          result={searchResult}
          programQueryResult={programQueryResult}
          programQueryResultCount={programQueryResultCount}
          activeMarkerRecord={activeMarkerRecord}
          setActiveMarkerRecord={setActiveMarkerRecord}
          isLoading={isLoading}
          showPanel={showPanel}
          setShowPanel={setShowPanel}
          showDocPanel={showDocPanel}
          setShowDocPanel={setShowDocPanel}
          airEventTableOpen={airEventTableOpen}
          setAirEventTableOpen={setAirEventTableOpen}
          currentQueryText={currentQueryText}
          showProgramQueryPanel={showProgramQueryPanel}
          setShowProgramQueryPanel={setShowProgramQueryPanel}
          showProgramQueryDocPanel={showProgramQueryDocPanel}
          setShowProgramQueryDocPanel={setShowProgramQueryDocPanel}
          programQueryText={programQueryText}
          ProgramQuery={() => (
            <ProgramQuery
              isLoading={isLoading}
              masters={masters}
              onSearchSubmit={(queryText: IDatabase) => {
                if (!!!queryText && !!!filter) {
                  return;
                }
                doProgramSearch(queryText);
              }}
            />
          )}
          handleSort={(column: any, sortDirection: any) => {
            const newFilter = {
              ...programQueryText,
              sortColumn: column.selector,
              sortDirection: sortDirection.toUpperCase()
            };
            setProgramQueryText(newFilter);
            doProgramSearch(newFilter);
          }}
          SearchForm={() => (
            <SearchForm
              isFilterApplied={filter}
              onFilterClick={() => setShowFilter(true)}
              isLoading={isLoading}
              inputRef={searchInputRef}
              onSearchSubmit={(queryText: string) => {
                if (!!!queryText && !!!filter) {
                  return;
                }
                setCurrentQueryText(queryText);
                setShowPanel(false);
                setShowDocPanel(false);
                setShowProgramQueryPanel(false);
                setShowProgramQueryDocPanel(false);
                return doTextSearch(queryText);
              }}
              currentQueryText={currentQueryText}
            />
          )}
        />
      </>
    );
  };

  const fetchLists = () => {
    try {
      setIsLoading(true);
      axios
        .get(
          API_BASE_URL + '/api/programQuery/lists',
          // 'http://localhost:5000/ceres-platform-test/us-central1/api/programQuery',
          {
            headers: API_AUTH_HEADERS,
          }
        )
        .then(response => {
          setIsLoading(false);
          const resultLists = response.data[0].jsonlistb;
          localStorage.setItem(
            'masters',
            JSON.stringify(resultLists)
          );
        })
        .catch(err => {
          CeresLogger.error(err);
          setIsLoading(false);
        })
        .finally(() => setIsLoading(false));
    } catch (e) {
      CeresLogger.error(e);
    }
  };

  useEffect(() => {
    if (searchInputRef.current !== null) {
      searchInputRef.current.focus();
    }
    const storageMasters = localStorage.getItem('masters');
    if (!storageMasters) {
      fetchLists();
    } else {
      setMasters(JSON.parse(storageMasters));
    }
  }, []);

  const userTier = context.getUserTier();

  return (
    <>
      <SearchResultMainContainer tier={userTier}>{renderDefaultSearch()}</SearchResultMainContainer>
      <AdvancedSearchFilter
        onFilterClear={() => setFilter(null)}
        onFilterApply={newFilter => {
          setFilter(newFilter);
          setShowFilter(false);
        }}
        onClose={() => setShowFilter(false)}
        show={showFilter}
      />
      <Footer />
    </>
  );
};

export default withAuthContext(CeresHome);

import React, { useEffect, useMemo, useState } from 'react'; // React in-built hooks

// External libraries
import axios, { Method } from 'axios';
import {
  Widget,
  addResponseMessage,
  addUserMessage,
  dropMessages,
  setQuickButtons,
  toggleMsgLoader,
  renderCustomComponent,
} from 'react-chat-widget';
import { toast } from 'react-toastify';
import { Form, InputGroup, OverlayTrigger, Spinner } from 'react-bootstrap';
import { IconButton, debounce } from '@material-ui/core';
import ReactTooltip from 'react-tooltip';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import PersonIcon from '@material-ui/icons/Person';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import CachedIcon from '@material-ui/icons/Cached';
import { FileCopy } from '@material-ui/icons';
import styled, { keyframes } from 'styled-components';
import moment from 'moment';
import jsPDF from 'jspdf';
import { renderToStaticMarkup } from 'react-dom/server';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Local files
import { chatBotChat, saveChats } from '../../services/firebase';
import showToastMessage from '../../util/showToastMessage';
import HeaderEnviroAILogo from '../../assets/header-enviroai-logo.svg';
import { updateGlobalFlags } from '../../store/aiearth/action';
import LOADER_MESSAGES from '../../constants/loader-messages';
import { isExxonMobileEmail } from '../Validator';

const slideInAnimation = keyframes`
  0% {
    right: -95%;
  }
  100% {
    right: 0;
  }
`;

const StyledTooltip = styled(ReactTooltip)`
  max-width: 25% !important;
  z-index: 100000 !important;
`;

const ChatSummaryContainer = styled.div`
  font-size: 16px;
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  width: 95%;
  z-index: 996;
  height: 100%;
  background-color: #f3f3f3;
  animation: ${slideInAnimation} 1s 1 normal;
`;

const SavedChatList = styled.div`
  width: 20%;
  overflow-y: scroll;
  height: 100%;
  padding: 0 5px 20px;
  background: white;

  /* width */
  &::-webkit-scrollbar {
    width: 10px;
    background-color: white;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #D3D3D3;
    border-radius: 10px;
  }

  .saved-chat-list {
    > li:hover, >li.active {
      background: #efefef;
      border-radius: 10px;
    }
    .clipped-text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .saved-chat-btn {
    position: sticky;
    top: 0;
    padding: 20px 0 10px;
    z-index: 3;
    background-color: white;
  }

  .chat-datetime {
    margin-bottom: 0;
    color: #8ebbf7;
    padding: 10px 10px 5px;
    font-weight: 500;
    font-size: 12px;
    border-bottom: 3px solid #f2f2f2;
  }
`;

const TitleContainer = styled.div`
  display: flex;

  .title-head {
    margin: 0 15% 0 16%;
  }

  // .environmental {
  //   width: 25%;
  //   font-weight: bold;
  //   align-self: center;

  //   > p {
  //     font-size: 1.2vw;
  //     margin-bottom: 0;
  //   }
  // }

  .icon-container {
    padding-right: 20px;
  }

  .close-Chat {
    position: absolute;
    font-size: 1.5rem;
    top: 20px;
    right: 0.75rem;
    color: #cbdff8;
    border: none;
    background: none;
    cursor: pointer;
    font-family: monospace;
  }
`;

// Keyframes for spinner animation
const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const growAnimation = keyframes`
  0% { max-height: var(--lineHeight); }
  100% { max-height: calc(var(--lineHeight) * var(--lines)); }
`;

const carriageReturnAnimation = keyframes`
  0% { top: 0; }
  100% { top: calc(var(--lineHeight) * var(--lines)); }
`;

const caretAnimation = keyframes`
  0% { color: var(--bgColor); }
  100% { color: black; }
`;

interface ILoaderContainerProps {
  message: string;
  author?: string;
}

const ChatBox = styled.div<ILoaderContainerProps>`
  .spinner {
    width: 20px;
    height: 20px;
    border: 3px solid;
    border-top: 3px solid #2F5597;
    border-radius: 50%;
    display: flex;
    animation: ${spinAnimation} 1s linear infinite;
    margin: 2px 0px 0px 7px;
  }

  .rcw-response > .rcw-message-text > p:first-child {
    background-color: #000000;
    color: #ffffff;
    border-radius: 20px;
    // width: 460px !important;
    text-align: center;
  }

  .rcw-message-text li > p {
    margin: -24px 0 0;
  }

  .rcw-message-text ul {
    margin-bottom: 0 !important;
  }

  .rcw-widget-container {
    width: 450px;
  }

  .rcw-conversation-container {
    justify-content: flex-end;
    box-shadow: none;

.rcw-sender {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #f5f5f5;
      border-radius: 0px 0px 20px 20px;
      padding: 8px;
      width: calc(100% - 20px); /* Adjust width to account for margins */
      margin: 0 10px 5px 10px;
      > .rcw-picker-btn {
        background: unset;
        img {
          display: none;
        }
      }

      .preview-file {
        position: relative;
        display: flex;
        align-items: center;
        border: 1px solid lightgray;
        border-radius: 10px;
        padding: 7px;
        gap: 7px;
        margin-right: 4px;
        flex-direction: row-reverse;

        p {
          color: black;
          margin-bottom: 0 !important;
          font-weight: 700;
          text-transform: capitalize;
          font-size: 12px;
        }
      }
      .preview-img {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 64px;
        height: 64px;
        min-width: 64px;
        overflow: hidden;
        position: relative;
        border: 1px solid #d3d3d3;
        border-radius: 10px;

        > img {
          width: 64px;
          display: block;
          position: absolute;
        }
      }

      .preview-img, .preview-file {
        .close-button {
          position: absolute;
          top: 10px;
          right: 10px;
          background: hsla(0, 0%, 100%, .8);
          border-radius: 50% !important;
          visibility: hidden;
          padding: 0;
          display: flex;
          justify-content: center;
          height: 20px;
        }

        &:hover .close-button {
          visibility: visible;
          opacity: 1;
          color: grey;
        }

        .rounded-close-button {
          svg {
            font-size: 1rem;
            vertical-align: text-top;
          }
        }
      }

      > .rcw-send {
        padding: 8px;
        border-radius: 5px;
        font-weight: bold !important;
        font-size: .875rem;
        line-height: 1.5;
        color: #2c190e;
        font-weight: 600;
        background-color: #ddc5b3;
        border-color: #ddc5b3;
        min-width: 75px;

        img {
          display: none;
        }
        &:before {
          content: "Send";
        }
      }
      // > .voice {
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   background-color: black;
      //   border-radius: 50%;
      //   width: 40px;  // Adjust the width to your preference
      //   height: 30px;
      //   margin-left: 10px // Adjust the height to create the oval shape
      // }
    }
  }

  .rcw-full-screen {
    z-index: 99999;
    width: 100%;
    height: 100%;
    @media only screen and (min-width: 768px) {
      width: 76%;
    }
  }

  .rcw-conversation-container.active {
    max-width: 100vw!important;
    background-color: #FFF;
  }

  .rcw-conversation-container .rcw-header {
    background-color: #2c190e;
    font-family: 'Sans Pro';
    padding: 7px 0 7px !important;
  }

  .rcw-header > .rcw-title {
    font-family: 'Sans Pro';
    padding: 6px 0px 6px 20px;
  }

  .rcw-client {
    width: 100%;
    align-items: flex-end;
  }

  .rcw-client > .rcw-message-text {
    background-color: #66ffff;
    font-size: 16px;
    color: #000000;
    width:100%;
    max-width: none
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  }

  .rcw-response > .rcw-message-text {
    max-width: 380px;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

    .enviro-label {
      width: 100% !important;
    }
  }

  .rcw-message {
    &:has(.preview-img), &:has(.preview-file) {
      width: 100% !important;
      justify-content: flex-end;
      margin-bottom: 0;
    }
    .preview-img, .preview-file {
      margin-left: auto;
      margin-bottom: 5px;
      width: max-content;
    }

      .preview-img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 64px;
      height: 64px;
      min-width: 64px;
      overflow: hidden;
      position: relative;
      // background-color: #f0f0f0;
      border: 1px solid lightgray;
      border-radius: 10px;
      > img {
        width: 64px;
        display: block;
        position: absolute;
      }
    }

    .preview-file {
      position: relative;
      display: flex;
      align-items: center;
      border: 1px solid lightgray;
      border-radius: 10px;
      padding: 7px;
      gap: 7px;
      margin-right: 4px;

      p {
        margin-bottom: 0 !important;
        font-weight: 700;
        text-transform: capitalize;
        font-size: 12px;
      }
    }
  }

  .rcw-message-client + .rcw-message > .rcw-response > .rcw-message-text {
    background-color: #e7ded1!important;
    width: 100%!important;
    color: black !important;
    border: none !important;

    > p, > ul {
      --bgColor: #e7ded1 !important;
    }
  }

  .rcw-message-client + .rcw-message + .rcw-message > .rcw-response > .rcw-message-text {
    background-color: #d1ffff;
    width: 100%;
    color: black !important;

    > p, > ul {
      --bgColor: #d1ffff;
    }
  }

  .rcw-message-client + .rcw-message + .rcw-message + .rcw-message > .rcw-response > .rcw-message-text {
    background-color: #f2f2f2;
    color: black;
    border: 1px solid black;
    width: 100%;

    > p, > ul {
      --bgColor: #f2f2f2;
    }
  }

  .rcw-message:nth-child(3n + 0), .rcw-message:nth-child(3n + 1), .rcw-message:nth-child(3n + 2) {
    > .rcw-response > .rcw-message-text {
      > p:not(.enviro-label), > ul {
        --lines: 500;
        --lineHeight: 1.5rem;
        --timePerLine: 1s;
        --widthCh: 40;
        --width: 100%;
        /* do not touch the time property!!! */
        --time: calc(var(--lines) * var(--timePerLine));
        animation: ${growAnimation} var(--time) steps(var(--lines));
        animation-fill-mode: forwards;
        line-height: var(--lineHeight);
        max-height: var(--lineHeight);
        overflow: hidden;
        position: relative;
        width: var(--width);
      }
    }
  }

  .rcw-message:nth-child(3n + 0), .rcw-message:nth-child(3n + 1), .rcw-message:nth-child(3n + 2) {
    > .rcw-response > .rcw-message-text {
      > p:not(.enviro-label)::before, > ul::before {
        content: "";
        animation:
          type var(--timePerLine) linear none,
          ${carriageReturnAnimation} var(--time) steps(var(--lines)) var(--lines),
          ${caretAnimation} 1s steps(2) infinite;
        background: var(--bgColor);
        border-left: 2px solid;
        bottom: 0;
        height: 2rem;
        position: absolute;
        right: 0;
        width: 100%;
        border-left-width: 0;
        animation:
        type var(--timePerLine) steps(var(--widthCh)) infinite,
        ${carriageReturnAnimation} var(--time) steps(var(--lines)) var(--lines),
        ${caretAnimation} 1s steps(2) infinite;
      }
    }
  }

  .rcw-launcher {
    background-color: #8ebbf7;
},

  .rcw-full-screen .rcw-messages-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: auto;
    overflow: auto;
  }

  .rcw-full-screen .rcw-messages-container .rcw-message-client {
    width: 98%;
  }

  .rcw-full-screen .rcw-messages-container .rcw-message:not(.rcw-message-client) {
    width: 31%;
    align-self: flex-start;
  }

  .rcw-full-screen .rcw-messages-container > .loader {
    height: fit-content;
    width: 31%;
  }

  .loader-container {
    display: flex;
    align-items: center;
    max-width: 100%;
    &:before {
    content: '${props => props.message}';
    margin-right: 10px;
    font-family: 'Aldo';
    font-size: 1.2rem;
    color: ${(props) => (props.author ? '#678ca2' : '#7c8f99')};
    font-style: ${(props) => (props.author ? 'italic' : 'normal')};
    }
  }

  .loader-dots {
      height: 4px;
      width: 8px;
  }

  .rcw-response {
    * {
      font-size: 16px;
      margin-bottom: 0;
      line-height: 1.5rem;
    }
  }

  .rcw-full-screen > .rcw-messages-container {
    height: 100% !important;
  }

  .rcw-full-screen .rcw-message:not(.rcw-message-client) .rcw-response {
    width: 100%;
  }

  .rcw-full-screen .rcw-response > .rcw-message-text {
    min-width: auto !important;
    max-width: none !important;
  }

  .rcw-new-message{
    color: #000000;
    text-align: left;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
    width: calc(100% - 100px);
    background-color: #f5f5f5 !important
  },

  .rcw-close-button {
    border: none;
    background: none !important;
    cursor: pointer;
    right: 5px !important;
  }

  .quick-button {
    font-family: 'Sans Pro';
    border-radius: 49px;
    color:#FFFFFF
    background-color:#2F5597
    border:0px
    display:flex
    padding: 10px 40px;

    &:disabled {
      background-color: gray;
      cursor: no-drop;
    }
  }
  .quick-buttons-container{
    padding: 10px;
    position: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 0px 0px 0px;
  }
`;

interface IEditedQuestion {
  index: number;
  title: string;
}

export default function SavedChats({context, activeSavedChatId, activeProfile, closeSavedChat, handleProfile, setShowSavedPrompts, setShowSaveLibrary, filePreviews, setFilePreviews, files, setFiles }: any) {
  const [currentChat, setCurrentChat] = useState(0);
  const [savedChats, setSavedChats]: any = useState([]);
  const [userMessage, setUserMessage] = useState('');
  const [defaultData, setDefaultData] = useState('');
  const [defaultData1, setDefaultData1] = useState('');
  const [defaultData2, setDefaultData2] = useState('');
  const [newChats, setNewChats]: any = useState([]);
  const [isEditableIndex, setIsEditableIndex] = useState<number | any>(null);
  const [editedQuestion, setEditedQuestion] = useState<IEditedQuestion | null>(null);
  const [showSpinner, setShowSpinner] = useState<string | null>(null);
  const [searchChatTitle, setSearchChatTitle] = useState<string>('');
  const [totalSavedChats, setTotalSavedChats] = useState(0);
  const [loadMoreSpinner, setLoadMoreSpinner] = useState(false);
  const [groupedSavedChat, setGroupedSavedChat] = useState<any[]>([]);
  // eslint-disable-next-line
  const [totalDraftLoad, setTotalDraftLoad] = useState(1);
  const dispatch = useDispatch();
  const history = useHistory();
  const [chatFilePreviews, setChatFilePreviews]: any[] = useState([]);
  const imageTypes = ['image/jpg','image/jpeg','image/png'];
  const [currentLoaderMessage, setCurrentLoaderMessage] = useState(() =>
    LOADER_MESSAGES[Math.floor(Math.random() * LOADER_MESSAGES.length)]
  );
  const [isWithAuthor, setIsWithAuthor] = useState<boolean>(!!currentLoaderMessage.author);

  useEffect(() => {
    const interval = setInterval(() => {
      let randomIndex = Math.floor(Math.random() * LOADER_MESSAGES.length);
      let newMessage = LOADER_MESSAGES[randomIndex];

      // Find a message that has the opposite of the current isWithAuthor state
      while (!!newMessage.author === isWithAuthor) {
        randomIndex = Math.floor(Math.random() * LOADER_MESSAGES.length);
        newMessage = LOADER_MESSAGES[randomIndex];
      }

      // Set the new message and toggle isWithAuthor for alternating behavior
      setCurrentLoaderMessage(newMessage);
      setIsWithAuthor(!!newMessage.author);

    }, 5000);

    return () => clearInterval(interval);
  }, [isWithAuthor]);

  const handleNewUserMessage = (message: string) => {
    setPreviousQuestionChat();
    if(!message) {
      return;
    }
    setTimeout(() => {
      handleFilePreview(filePreviews);
    });

    setUserMessage(message);
    searchInBot(message);
    setChatFilePreviews(filePreviews);
    setFilePreviews([]);
    setFiles([]);
  };

  // Set previous question in saved chat when search more that 1 question.
  const setPreviousQuestionChat = () => {
    if (!userMessage) { return; }

    const currentChatData = savedChats[currentChat].payload.chatData || [];
    savedChats[currentChat].payload.chatData = buildUpdatedChats(currentChatData);
  };

  async function getSavedChats(data: any) {
    try {
      // Show loading indicators
      toggleMsgLoader();
      setGroupedSavedChat([]);
      setSavedChats([]);
      setSearchChatTitle('');
      setShowSpinner('load_question_list');

      // Fetch saved chats
      const response = await saveChats({ ...data, pageSize: 15 });

      if (response) {
        const chatData = response.data.data || [];
        const successMessage = chatData.length
          ? 'Retrieved All Data Successfully.'
          : 'No Saved Chats to Display.';
        toast.success(successMessage, { position: 'top-right' });

        // Update state with retrieved chat data
        setTotalSavedChats(response.data.totalItems);
        const savedChatData = chatData.map((item: any, i: number) => ({
          ...item,
          index: i,
        }));
        setSavedChats(savedChatData);
      }

      // Hide loading indicators
      setShowSpinner(null);
      toggleMsgLoader();
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error('Error fetching saved chats:', error);
    }
  }

  useEffect(() => {
    groupedChatByDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedChats]);

  const disableDraftBtns = (isDisabled: boolean) => {
    const btnElements: any = document.getElementsByClassName('quick-button');

    btnElements.forEach((btnElement: any) => {
      if (isDisabled) {
        btnElement.setAttribute('disabled', isDisabled);
      } else {
        btnElement.removeAttribute('disabled');
      }
    });
  };

  const renderQuickBtn = () => [
    { label: 'Agency Documents', value: 'agency_documents' },
    { label: 'Internet Results', value: 'internet_results' },
    { label: 'Facilities Maps', value: 'facilities_maps' },
    { label: 'Update', value: 'update_chat' },
    { label: 'Delete', value: 'delete_chat' },
    { label: 'Print', value: 'print' },
  ];

  useEffect(() => {
    const fetchData = async () => {
      await getSavedChats({ type: 'getAll' });
    };

    fetchData();
  }, []);

  useEffect(() => {
    dropMessages();
    if(savedChats.length && savedChats[currentChat]) {
      getChatById({
        type: 'get',
        id: savedChats[currentChat].id
      });
    }

    if(!savedChats.length) {
      setQuickButtons([]);
    } else {
      setQuickButtons(renderQuickBtn());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChat, savedChats]);

  useEffect(() => {
    // Disable more item loader when all library questions load
    if(totalSavedChats === savedChats.length) {
      setLoadMoreSpinner(false);
    } else {
      setLoadMoreSpinner(true);
    }
  }, [totalSavedChats, savedChats]);

  async function getChatById(data: any) {
    try {
      const response = await saveChats(data);

      if (isValidChatResponse(response)) {
        updateSavedChats(response.data.payload.chatData);
        response.data.payload.chatData.forEach(processChat);
      }
    } catch (error) {
      handleFetchError(error);
    }
  }

  function isValidChatResponse(response: any): boolean {
    return response && response.data && Array.isArray(response.data.payload.chatData);
  }

  function updateSavedChats(chatData: any[]): void {
    savedChats[currentChat].payload.chatData = chatData;
  }

  function processChat(chats: any): void {
    delete chats.timestamp;
    renderCustomComponent(questionWithFile, chats);
    const answers = { q: chats.q};
    Object.values(answers).forEach(processAnswerData);
  }

  const handleFilePreview = (previews: any) => {
    // Get all elements with the class "rcw-client"
    const elements = document.getElementsByClassName('rcw-client');

    // Check if any elements were found
    if (elements.length > 0) {
      // Get the last element in the collection
      const lastElement = elements[elements.length - 1];
      // Merge dom of question and attach files for file preview in chat area
      lastElement.innerHTML = renderToStaticMarkup(questionWithFile({ attachFiles: previews })) + lastElement.innerHTML;
    } else {
      // tslint:disable-next-line:no-console
      console.log('No elements found with the class \'rcw-client\'');
    }
  };

  const questionWithFile = ({ attachFiles }: { attachFiles: any[] }) => {
    return (
      <div>
        {Array.isArray(attachFiles) &&
          attachFiles.map((file: any) => (
            imageTypes.includes(file.type) ? (
              <div key={file.name} className="preview-img mr-1">
                <img src={file.preview} alt={file.name} width="200" />
              </div>
            ) : (
              <div key={file.name} className="preview-file">
                <FileCopy color="primary" />
                <div>
                  <p>{file.name}</p>
                  <p>{file.type}</p>
                </div>
              </div>
            )
          ))}
      </div>
    );
  };

  function processAnswerData(answerData: any): void {
    addUserMessage(answerData.title);

    const copyAnswerData = { ...answerData };
    delete copyAnswerData.title;
    delete copyAnswerData.order;

    Object.values(copyAnswerData).forEach((answer: any, i: number) => {
      processAnswer(answer, i);
    });
  }

  function processAnswer(answer: any, index: number): void {
    if (!!answer) {
      const answerParts = answer.split('\n\n');
      const formattedAnswer = {
        tag: answerParts[0],
        text: '',
        message: answerParts.slice(1).join('\n\n')
      };

      renderCustomComponent(customResponseComponent, formattedAnswer);
    }
  }

  function handleFetchError(error: any): void {
    // tslint:disable-next-line:no-console
    console.error('Error fetching chat by id:', error);
  }

  const setSavedChat = async (id: number) => {
    if (id === currentChat) {
      return;
    }

    dropMessages();
    setCurrentChat(id);
  };

  const buttonSpinner = (show: boolean, buttonIndex: number) => {
    const btnEls = document.getElementsByClassName('quick-button');
    const btnEl = btnEls[buttonIndex];

    if (show) {
      const spinnerEl = document.createElement('div');
      spinnerEl.className = 'spinner';
      btnEl.appendChild(spinnerEl);
    } else {
      const spinnerEl = btnEl.querySelector('.spinner');
      if (spinnerEl) {
        spinnerEl.remove();
      }
    }
  };

  const searchInBot = async (text: any) => {
    try {
      if (defaultData) {
        addDefaultDataToNewChats(userMessage, defaultData, defaultData1, defaultData2);
      }

      setTotalDraftLoad(1);
      toggleMsgLoader();
      let isMlError = false;

      try {
        if (process.env.REACT_APP_ENVIROCHAT_GEMINI) {
          await handleMachineLearningResponse(text);
        } else {
          isMlError = true;
        }
      } catch (error) {
        isMlError = true;
      }

      if (isMlError) {
        await handleChatBotChat(text);
      }

      handleOtherDraft(text);
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error('chat gpt error', error);
    }
  };

  const addDefaultDataToNewChats = (title: string, a1: any, a2: any, a3: any) => {
    setNewChats([
      ...newChats,
      {
        q: {
          a1,
          a2,
          a3,
          title,
          attachFile: filePreviews,
          order: 2,
        },
      },
    ]);
  };

  const handleMachineLearningResponse = async (text: string) => {
    let queryString = `?q=${text}&chatStatus=ongoing`;

    const formData = new FormData();
    formData.append('q', text);
    formData.append('chatStatus', 'ongoing');

    if(activeProfile) {
      queryString += `&profile=${activeProfile}`;
    }
    const localUserId = localStorage.getItem('uid');
    if(localUserId) {
      queryString += `&userId=${encodeURIComponent(localUserId)}`;
      formData.append('userId', encodeURIComponent(localUserId));
    }
    if(Array.isArray(files) && files.length) {
      files.forEach((file) => {
        formData.append('files', file);
      });
      await getDraftData(`${process.env.REACT_APP_ENVIROCHAT_GEMINI}`, queryString, 'Gemini Base-Model', 'POST', formData);
    } else {
      await getDraftData(`${process.env.REACT_APP_ENVIROCHAT_GEMINI}`, queryString, 'Gemini Base-Model', 'GET');
    }
  };

  const handleChatBotChat = async (text: string) => {
    const res = await chatBotChat({ prompt: text });
    if (res.data) {
      renderCustomComponent(customResponseComponent, { tag: 'EnviroAI Model Response #1 - Gemini Base-Model', text, message: res.data });
    }
  };

  const handleOtherDraft = async (searchQuery: string) => {
    try {
      let queryString = `?q=${searchQuery}&chatStatus=ongoing`;

      const formData = new FormData();
      formData.append('q', searchQuery);
      formData.append('chatStatus', 'ongoing');

      if(activeProfile) {
        queryString += `&profile=${activeProfile}`;
      }
      const localUserId = localStorage.getItem('uid');
      if(localUserId) {
        queryString += `&userId=${localUserId}`;
        formData.append('userId', encodeURIComponent(localUserId));
      }

      if(Array.isArray(files) && files.length) {
        files.forEach((file) => {
          formData.append('files', file);
        });
        await getDraftData(`${process.env.REACT_APP_ENVIROCHAT_GPT}`, queryString, 'OpenAI Base-Model', 'POST', formData);
        await getDraftData(`${process.env.REACT_APP_ENVIROCHAT_CLAUDE}`, queryString, 'Anthropic Base-Model', 'POST', formData);
      } else {
        await getDraftData(`${process.env.REACT_APP_ENVIROCHAT_GPT}`, queryString, 'OpenAI Base-Model', 'GET');
        await getDraftData(`${process.env.REACT_APP_ENVIROCHAT_CLAUDE}`, queryString, 'Anthropic Base-Model', 'GET');
      }
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error('Error handling other drafts:', error);
    }
  };

  async function getDraftData(url: string, queryString: string, enviroLabel: string, requestType: Method, payload?: any) {
    const reqUrl = requestType === 'GET' ? `${url}${queryString}` : url;
    axios(reqUrl, { method: requestType, data: payload }).then((res: any) => {
      let responseNum = 0;
      setTotalDraftLoad(prevState => {
        responseNum = prevState;
        return prevState;
      });
      if (res && res.data) {
        const mainString = res.data.content;
        const response = `EnviroAI Model Response #${responseNum} - ${enviroLabel}\n\n${mainString}`;
        renderCustomComponent(customResponseComponent, { tag: `EnviroAI Model Response #${responseNum} - ${enviroLabel}`, message: mainString });
        switch (responseNum) {
          case 1:
            setDefaultData(response);
            break;
          case 2:
            setDefaultData1(response);
            break;
          case 3:
            setDefaultData2(response);
            break;
          default:
            break;
        }
      } else {
        addResponseMessage(`EnviroAI Model Response #${responseNum} - ${enviroLabel}\n\nData Not Found`);
      }
      if(responseNum === 3) {
        toggleMsgLoader();
      }
      setTotalDraftLoad(responseNum + 1);
    }).catch(error => {
      let responseNum = 0;
      setTotalDraftLoad(prevState => {
        responseNum = prevState;
        return prevState;
      });
      if(requestType === 'GET') {
        const dataNotFoundMessage = `EnviroAI Model Response #${responseNum} - ${enviroLabel}\n\nData Not Found`;
        addResponseMessage(dataNotFoundMessage);
        switch (responseNum) {
          case 1:
            setDefaultData(dataNotFoundMessage);
            break;
          case 2:
            setDefaultData1(dataNotFoundMessage);
            break;
          case 3:
            setDefaultData2(dataNotFoundMessage);
            break;
          default:
            break;
        }
        if(responseNum === 3) {
          toggleMsgLoader();
        }
        setTotalDraftLoad(responseNum + 1);
      } else {
        getDraftData(url, queryString, enviroLabel, 'GET');
      }
    });
  }

  const handleQuickButtonClickFn = async (type: any, searchedText?: string) => {
    try {
      const mainTitle = savedChats[currentChat].payload.mainTitle;
      if(type === 'agency_documents') {
        disableAndSpinButton(true, 0);
        axios.post(`${process.env.REACT_APP_ENVIROCHAT_PROMPT_KEYWORDS}`, { prompt: mainTitle })
        .then(res => {
          // set prev visited page to come back
          dispatch(updateGlobalFlags({previousPage : 'myenviroaiToAgency'}));
          // store chat for display when back to myenviroai page
          // dispatch(updateSavedChatData(newChat));
          history.replace(`/search?q=${res.data}&type=default`);
        }).catch(error => {
          // set prev visited page to come back
          dispatch(updateGlobalFlags({previousPage : 'myenviroaiToAgency'}));
          // store chat for display when back to myenviroai page
          // dispatch(updateSavedChatData(newChat));
          history.replace(`/search?q=${mainTitle}&type=default`);
        }).finally(() => {
          closeSavedChat(false, true);
        });
      }
      if(type === 'internet_results') {
        disableAndSpinButton(true, 1);
        axios.post(`${process.env.REACT_APP_ENVIROCHAT_PROMPT_KEYWORDS}`, { prompt: mainTitle })
        .then(res => {
          // set prev visited page to come back
          dispatch(updateGlobalFlags({previousPage : 'myenviroaiToInternetResults'}));
          // store chat for display when back to myenviroai page
          // dispatch(updateSavedChatData(newChat));
          history.replace(`/search?q=${res.data}&type=ALL`);
        }).catch(error => {
          // set prev visited page to come back
          dispatch(updateGlobalFlags({previousPage : 'myenviroaiToInternetResults'}));
          // store chat for display when back to myenviroai page
          // dispatch(updateSavedChatData(newChat));
          history.replace(`/search?q=${mainTitle}&type=ALL`);
          // tslint:disable-next-line:no-console
          console.log('error=>', error);
        }).finally(() => {
          closeSavedChat(false, true);
        });
      }
      if(type === 'facilities_maps') {
        // set prev visited page to come back
        dispatch(updateGlobalFlags({previousPage : 'myenviroaiToFacility'}));
        // // store chat for display when back to myenviroai page
        // dispatch(updateSavedChatData(newChat));
        history.push(`/my-tundra`);
      }
      if(type === 'print') {
        printHandler();
      }
      if (type === 'update_chat') {
        await handleUpdateChat();
      }

      if (type === 'delete_chat') {
        await handleDeleteChat();
      }
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error('Error handling quick button click:', error);
    }
  };

  const printHandler = () => {
    const  sanitizeText = (text: string) => {
      // Use a regular expression to remove HTML tags
      return text.replace(/<\/?[^>]+(>|$)/g, '');
    };

    const splitLines = (jspdf: jsPDF, text: string, y: number) => {
      // Define page dimensions
      const pageHeight = jspdf.internal.pageSize.getHeight();

      const textWidth = 180; // Width of the text block
      const margin = 10; // Margin from the edge of the page

      // Split text into lines based on width
      const textLines = jspdf.splitTextToSize(text, textWidth);

      const lineHeight = jspdf.getTextDimensions(textLines[0]).h; // Get the height of a single line of text

      let startY = y; // Initial Y position

      textLines.forEach((line: any) => {
        // Check if the text fits within the available space
        if (startY + lineHeight > pageHeight - margin) {
          jspdf.addPage(); // Add a new page
          startY = margin; // Reset startY for the new page
        }

        // Add the line of text to the PDF
        jspdf.text(line, margin, startY);
        startY += lineHeight; // Move the Y position for the next line
      });
      return startY;
    };

    const pdf = new jsPDF({ format: 'a4', orientation: 'portrait' });
    let pdfTitle = 'Chat transcript';

    const currentChatData = savedChats[currentChat];
    const updatedChats = buildUpdatedChats(currentChatData.payload.chatData || []);
    updatedChats.forEach((data: any, i: number) => {
      if (i === 0) {
        const words = ((data.q && data.q.title) ? data.q.title : 'Chat transcript').split(' ');  // Split title into words
        if (words.length >= 2) {
          pdfTitle = words.slice(0, 2).join(' ');
        } else {
          pdfTitle = words.join(' ');
        }
      }
      pdf.setFontSize(12);
      const questionHeight = splitLines(pdf, sanitizeText(`Q.${i+1} ${data.q.title}`), 10);
      pdf.setFontSize(10);
      splitLines(pdf, sanitizeText(`Ans.1 ${data.q.a1}`), questionHeight + 4);
      pdf.addPage();
      splitLines(pdf, sanitizeText(`Ans.2 ${data.q.a2}`), 10);
      pdf.addPage();
      splitLines(pdf, sanitizeText(`Ans.3 ${data.q.a3}`), 10);
      if((updatedChats.length - 1) !== i) {
        pdf.addPage();
      }
    });
    pdfTitle = pdfTitle.replace(/[^a-zA-Z0-9 \-_]+/g, '');
    pdf.save(`${pdfTitle}.pdf`);
  };

  const handleUpdateChat = async () => {
    try {
      const currentChatData = savedChats[currentChat];
      const updatedChats = buildUpdatedChats(currentChatData.payload.chatData || []);

      const newChat = {
        mainTitle: currentChatData.payload.mainTitle,
        chatData: updatedChats,
      };

      disableAndSpinButton(true, 3);

      const response = await saveChats({
        type: 'update',
        id: currentChatData.id,
        record: newChat,
      });

      disableAndSpinButton(false, 3);

      if (response) {
        showToast('Data updated successfully.');
      }
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error('Error updating chat:', error);
    }
  };

  const handleDeleteChat = async () => {
    try {
      disableAndSpinButton(true, 4);

      const response = await saveChats({
        type: 'remove',
        id: savedChats[currentChat].id,
      });

      disableAndSpinButton(false, 4);

      if (response) {
        await handleGetSavedChats();
        setCurrentChat(0);
        showToast('Data deleted successfully.');
      }
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error('Error deleting chat:', error);
    }
  };

  const buildUpdatedChats = (currentChats: any[]) => {
    return [
      ...(currentChats || []),
      ...[
        {
          q: {
            a1: defaultData,
            a2: defaultData1,
            a3: defaultData2,
            title: userMessage,
            order: 2,
          },
          attachFiles: chatFilePreviews ? chatFilePreviews : []
        },
      ],
    ];
  };

  const disableAndSpinButton = (disable: boolean, buttonIndex: number) => {
    disableDraftBtns(disable);
    buttonSpinner(disable, buttonIndex);
  };

  const handleGetSavedChats = async () => {
    await getSavedChats({ type: 'getAll' });
  };

  const customResponseComponent: any = ({ tag, text, message }: any) => {
    const generateHyperlink = (painText: string) => {
      // Regular expression to match URLs in plain text
      const urlRegex = /(https?:\/\/[^\s]+)/g;

      // Replace URLs with anchor tags
      const linkedText = painText.replace(urlRegex, (url: string) => `<a href="${url}" target="_blank">${url}</a>`);

      return { __html: linkedText };
    };
    return (
      <div className="rcw-response">
        <div className="rcw-message-text label-container2">
          {tag && (<><p className="enviro-label">{tag}</p><br /></>)}
          {text && (<><p>{text} :</p><br /></>)}
          <p dangerouslySetInnerHTML={generateHyperlink(message)}/>
        </div>
      </div>
    );
  };

  const updateQuestion = (questionIndex: number, questionTitle: string) => {
    setEditedQuestion({
      index: questionIndex,
      title: questionTitle,
    });
  };

  const saveQuestion = async () => {
    if (!editedQuestion) {
      return;
    }

    const currentChatData = savedChats[editedQuestion.index];

    const newChat = {
      mainTitle: editedQuestion.title,
      chatData: currentChatData.payload.chatData || [],
    };

    disableAndShowSpinner(true);

    try {
      const response = await saveChats({
        type: 'update',
        id: currentChatData.id,
        record: newChat,
      });

      disableAndShowSpinner(false);

      if (response) {
        updateLocalStorage(currentChatData);
        showToast('Data updated successfully.');
      }

      setIsEditableIndex(null);
    } catch (error) {
      handleSaveError(error);
    }
  };

  const disableAndShowSpinner = (disable: boolean) => {
    disableDraftBtns(disable);
    setShowSpinner(disable ? 'edit_question' : null);
  };

  const updateLocalStorage = (currentChatData: any) => {
    if (activeSavedChatId === currentChatData.id) {
      const defaultChatData = JSON.parse(localStorage.getItem('defaultChats') as string) || {};
      localStorage.setItem('defaultChats', JSON.stringify({ ...defaultChatData, mainTitle: editedQuestion ? editedQuestion.title : '' }));
    }
  };

  const handleSaveError = (error: any) => {
    // tslint:disable-next-line:no-console
    console.error('Error saving question:', error);
    setShowSpinner(null);
    disableDraftBtns(false);
  };

  const showToast = (message: string) => {
    toast.success(message, { position: 'top-right' });
  };

  /** Pagination scroll for saved chat title list */
  async function scrollHandler(e: any, searchTerm?: string) {
    if(searchTerm !== undefined) {
      setSavedChats([]);
    }
    const chatListElement = document.getElementById('plist');
    if(!!!chatListElement) {
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } = chatListElement;
    const shouldLoadMore = scrollTop + 10 >= scrollHeight - clientHeight;

    if ((shouldLoadMore && savedChats.length < totalSavedChats) || searchTerm !== undefined) {
      try {
        const lastItemIndex = searchTerm ? undefined : savedChats.length;
        const moreData: any = await saveChats({ type: 'getAll', lastItemIndex, searchTerm: searchTerm || searchChatTitle, pageSize: 15 });

        if (moreData.data.data.length > 0) {
          const savedChatData = moreData.data.data.map((item: any, i: number) => ({
            ...item,
            index: searchTerm !== undefined ? i : (i + savedChats.length)
          }));
          setSavedChats(searchTerm !== undefined ? savedChatData : savedChats.concat(savedChatData));
        }
        setTotalSavedChats(moreData.data.totalItems);
      } catch (error) {
        if (error instanceof Error) {
          handleErrorMessage(error);
        }
      }
    }
  }

  // Handle catch error
  function handleErrorMessage(error: Error) {
    const { name, message } = error;
    showToastMessage({ type: 'error', title: name, description: message });
  }

  // Function to handle input change
  const handleChange = (event: any) => {
    setSearchChatTitle(event.target.value);
    setCurrentChat(0);
  };

  // Function to handle debounced input change
  const handleDebouncedChange = useMemo(
    () =>
      debounce((value) => {
        scrollHandler('', value);
      }, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // Function to handle input change with debounce
  const handleInputWithDebounce = (event: any) => {
    handleChange(event); // Update state immediately
    handleDebouncedChange(event.target.value); // Trigger debounce function
  };

  // Group saved chat list by date
  const groupedChatByDate = () => {
    const groupedData = savedChats.reduce((acc: any, item: any) => {
      // Convert timestamp to Date object
      const timestamp = new Date(item.timestamp._seconds * 1000);

      // Extract day, month, and year
      const day = timestamp.toLocaleDateString('en-US', { day: 'numeric' });
      const month = timestamp.toLocaleDateString('en-US', { month: 'numeric' });
      const year = timestamp.toLocaleDateString('en-US', { year: 'numeric' });

      // Create a unique key for the day, month, and year
      const key = `${day}-${month}-${year}`;

      // Add the item to the corresponding day, month, and year group
      if (!acc[key]) {
        acc[key] = {
          dateTime: moment.unix(item.timestamp._seconds).format('DD/MM/YYYY'),
          timestamp: item.timestamp._seconds,
          data: []
        };
      }

      acc[key].data.push(item);
      return acc;
    }, {});

    const sortedGroupedData = Object.values(groupedData).sort((a: any, b: any) => b.timestamp - a.timestamp);

    setGroupedSavedChat(sortedGroupedData);
  };

  return (
    <>
      <ChatSummaryContainer>
        <SavedChatList id="plist" onScroll={debounce(scrollHandler, 1000)}>
          <div className="saved-chat-btn">
            <button
              data-tip="Click to reload saved chat questions"
              data-for="saved-chat-btn-tooltip"
              id="save-chat-btn"
              className="btn btn-outline-primary d-block mx-auto w-100"
              disabled={showSpinner === 'load_question_list'}
              onClick={() => getSavedChats({ type: 'getAll' })}
            >
              <CachedIcon /> Saved Chats
            </button>
            <StyledTooltip id="saved-chat-btn-tooltip" place="bottom" effect="solid" />
            <InputGroup className="pt-3">
              <Form.Control
                className="rounded"
                placeholder="Search Chat..."
                name="savedChat"
                value={searchChatTitle}
                onChange={handleInputWithDebounce}
              />
            </InputGroup>
          </div>
           <div>
            {groupedSavedChat.length > 0 && groupedSavedChat.map(item => (
              <>
                <p className="chat-datetime">{item.dateTime}</p>
                <ul className="list-unstyled list-group-flush saved-chat-list mb-0">
                  {item.data.map((question: any, i: number) => (
                    // question && question.payload && (
                      <li
                        key={question.id}
                        className={`d-flex py-0 align-items-center list-group-item-action ${currentChat === question.index ? 'active' : ''}`}
                        onClick={() => setSavedChat(question.index)}
                      >
                        <p
                          data-tip={question.payload.mainTitle}
                          data-for="question-tooltip"
                          className={`mb-0 p-2 w-100 ${isEditableIndex === question.index ? 'border border-3 rounded border-dark' : 'clipped-text'}`}
                          contentEditable={isEditableIndex === question.index}
                          dangerouslySetInnerHTML={{ __html: question.payload.mainTitle }}
                          onInput={(e: any) => updateQuestion(i, e.currentTarget.textContent)}
                        />
                        <StyledTooltip id="question-tooltip" place="right" effect="solid" />

                        {isEditableIndex !== null && isEditableIndex === question.index ? (
                          <>
                            {showSpinner && showSpinner === 'edit_question' && <Spinner animation="border" variant="secondary" className="m-2" />}
                            <OverlayTrigger
                              placement="bottom"
                              overlay={<ReactTooltip effect="solid" />}
                            >
                              <IconButton data-tip="Save" onClick={saveQuestion} aria-label="fav-icon" component="span">
                                <SaveIcon style={{ color: '#000' }} />
                              </IconButton>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<ReactTooltip effect="solid" />}
                          >
                            <IconButton data-tip="Edit" onClick={() => setIsEditableIndex(i)} aria-label="edit-icon" component="span">
                              <EditIcon style={{ color: '#000' }} />
                            </IconButton>
                          </OverlayTrigger>
                        )}
                      </li>
                    // )
                  ))}
                </ul>
              </>
            ))}
          </div>

          {(loadMoreSpinner || (showSpinner && showSpinner === 'load_question_list')) && (<div className="d-flex justify-content-center my-3 w-100">
            <Spinner animation="border" variant="secondary" />
          </div>)}
          { !loadMoreSpinner && !(showSpinner && showSpinner === 'load_question_list') && !groupedSavedChat.length && <h4 className="saved-chat-list d-flex align-items-center justify-content-center p-4">No Saved Chats to Display.</h4>}
        </SavedChatList>
        <ChatBox
          message={
            isWithAuthor
              ? `"${currentLoaderMessage.quote}" - ${currentLoaderMessage.author}`
              : currentLoaderMessage.quote
          }
          author={isWithAuthor ? currentLoaderMessage.author : undefined}
        >
          <Widget
            showCloseButton={false}
            fullScreenMode={true}
            handleQuickButtonClicked={handleQuickButtonClickFn}
            handleNewUserMessage={handleNewUserMessage}
            title={
              <TitleContainer>
                 {/* <OverlayTrigger
                    overlay={<ReactTooltip place="left" effect="solid" />}
                  >
                    <button data-tip= "Go back to Enviro Chat" className="btn btn-outline-light btn-sm saved-chat-btn" onClick={() => closeSavedChat(false)}>
                      Back
                    </button>
                </OverlayTrigger> */}
                <div>
                    <StyledTooltip id="header-btn-tooltip" place="bottom" effect="solid" />
                    <button data-tip="Click to open saved prompts" data-for="header-btn-tooltip" id="saved-prompts-btn" className="btn btn-outline-light mr-1" onClick={() => setShowSavedPrompts(true)}>
                      <ClearAllIcon /> Saved Prompts
                    </button>
                    <button data-tip="Click to open profiles" data-for="header-btn-tooltip" id="profile-btn" className="btn btn-outline-light mr-1" onClick={() => handleProfile(true)}>
                      <PersonIcon /> Profile
                    </button>
                    {/* <button id="profile-btn" className="btn btn-outline-light" onClick={() => setShowSaveLibrary(true)}>
                      <LibraryBooksIcon /> Library
                    </button> */}
                    <button
                      data-tip="Click to open saved library" data-for="header-btn-tooltip"
                      id="library-btn"
                      className={
                        isExxonMobileEmail(context)
                          ? 'btn btn-outline-light btn-disable'
                          : 'btn btn-outline-light'
                      }
                      onClick={() => {
                        if (!isExxonMobileEmail(context)) {
                          setShowSaveLibrary(true);
                        } else {
                          showToastMessage({
                            type: 'warn',
                            title: 'Warning',
                            description: 'This feature is disabled for ExxonMobil. Please contact the Administrator.',
                          });
                        }
                      }}
                    >
                      <LibraryBooksIcon /> Saved Library
                    </button>
                </div>
                <div className="title-head title-head-fullscreen">
                  <img src={HeaderEnviroAILogo} alt="EnviroAI Logo" />
                </div>

                {/* <div className="environmental">
                  <p>Environmentally<sup> Trained</sup> Generative AI</p>
                </div> */}

                <div className="icon-container">
                <button className="close-Chat" onClick={() => closeSavedChat(false)}>
                    X
                  </button>
                </div>
              </TitleContainer>
            }
            subtitle=""
            emojis={false}
          />
        </ChatBox>
      </ChatSummaryContainer>
    </>
  );
}

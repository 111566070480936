import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IAuthContext } from '../../context/auth/auth-context';
import withAuthContext from '../../context/auth/AuthConsumer';
import SurveyComponent from './Survey';
import { Card, Container, Spinner } from 'react-bootstrap';
import { CeresLogger } from '../../logger';
import styled from 'styled-components';
import defaultSurveyConfig, {EnvironmentalQuestionnaireJSON, anwr} from './surveyJson';
import { API_BASE_URL, API_AUTH_HEADERS } from '../../util/api.util';

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em 0em;
`;
const Invalid = styled.h4`
  text-align: center;
  padding: 30px;
  font-size: 20px;
`;

const Survey: React.FC<
RouteComponentProps<any> & {
  context: IAuthContext;
}
> = props => {
  const authContext = props.context;
  const userEmail = authContext.getUserEmail();
  const query =  new URLSearchParams(props.location.search);
  const jobsId = query.get('jobid');
  const jobName = query.get('name');
  const [isValid, setValid] = useState('false');
  const [isLoading, setIsLoading] = useState(true);

  const storeSurvey = (sJson: any) => {
    const surveyData = JSON.stringify(sJson);
    const requestBody = {name: jobName, jobId: jobsId, user: userEmail, surveyJson: surveyData};
    try {
      axios
        .post(
          API_BASE_URL + '/api/saveSurveyJobInfo',
          // 'http://localhost:5000/ceres-platform-test/us-central1/api/document-entity/list',
          requestBody,
          {
            headers: API_AUTH_HEADERS,
          }
        )
        .then((response) => {
          CeresLogger.info(response.data);
        })
        .catch((err) => {
          CeresLogger.error(err);
        });
    } catch (e) {
      CeresLogger.error(e);
    }
  };

  useEffect(() => {
    const checkValidJob = () => {
      const requestBody = {name: jobName, jobId: jobsId, user: userEmail};
      try {
        axios
          .post(
            API_BASE_URL +'/api/checkValidSurveyJob',
            // 'http://localhost:5000/ceres-platform-test/us-central1/api/document-entity/list',
            requestBody,
            {
              headers: API_AUTH_HEADERS,
            }
          )
          .then((response) => {
            if(typeof response.data[0].job_survey_get.is_valid !== 'undefined') {
              setValid(response.data[0].job_survey_get.is_valid);
            }
          })
          .catch((err) => {
            CeresLogger.error(err);
          })
          .finally(() => setIsLoading(false));
      } catch (e) {
        CeresLogger.error(e);
      }
    };
    if(isLoading) {
      checkValidJob();
    }
  }, [isLoading, setValid, jobName, jobsId, userEmail]);

  return(
    <Container>
      <Card className="mt-1">
        {isValid === 'true' ? (
          <Card.Body>
              <SurveyComponent
                  css={defaultSurveyConfig.defaultSurveyCSS}
                  json={EnvironmentalQuestionnaireJSON}
                  data={anwr}
                  onComplete={(survey: any) => {
                    storeSurvey(survey.data);
                  }}
              />
          </Card.Body>
        ) : (
          <>
            {isLoading ? (
              <SpinnerContainer>
                <Spinner animation="border" variant="primary" />
              </SpinnerContainer>
            ) : (
              <Invalid>Invalid Job Id</Invalid>
            )}
          </>
        )}
      </Card>
    </Container>
  );
};

export default withRouter(withAuthContext(Survey));

import * as CryptoJS from 'crypto-js';
import * as R from 'ramda';

import { IElasticResult, ISearchRecord } from '../../types/search';
import React, { useEffect, useRef, useState } from 'react';
import { iv, key as secKey } from '../../services/firebase';
import { resetAIEarth, resetAir, resetDatabase, resetJob, resetMap, updateDatebase } from '../../store/aiearth/action';
import { useDispatch, useSelector } from 'react-redux';

import AdvancedSearchFilter from '../../components/ai-earth-components/AdvancedSearchFilter';
import AiEarthContainer from '../../components/ai-earth-components/AiEarthContainer';
import { CeresLogger } from '../../logger';
import Footer from '../../components/Footer';
import { IAuthContext } from '../../context/auth/auth-context';
import { IDatabase, IAIEarth } from '../../store/aiearth/types';
import ProgramQuery from '../../components/ai-earth-components/ProgramQuery';
import { RouteComponentProps } from 'react-router-dom';
import SearchForm from '../../components/ai-earth-components//SearchForm';
import { TIERS } from '../../types/tiers';
import axios from 'axios';
import { dummyRecord } from '../../types/search';
import { search } from '../../services/firebase';
import styled from 'styled-components';
import withAuthContext from '../../context/auth/AuthConsumer';
import { API_BASE_URL, API_AUTH_HEADERS } from '../../util/api.util';

interface ICeresHome {
  tier: TIERS;
}

interface ILocation {
  lat: number;
  lon: number;
}

interface IEPNRecord {
  location: ILocation;
  marker?: string;
  name?: string;
  type?: string;
  details?: string;
  entityNumber?: string;
}

const SearchContainer = styled.div<ICeresHome>`
  justify-content: center;
  flex-direction: column;
  display: flex;
  background: #fff;
  // For iPhone 6, 7, 8 landscape only.
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: landscape) {
    min-height: 600px;
    justify-content: flex-start;
  }
`;

export const applyFilterToQuery = (query: any, filter: any = {}) => {
  const innerQuery = query.query || {};
  let releaseDateFilter = {};
  if (filter.rnNumber) {
    innerQuery.terms = Object.assign(innerQuery.terms || {}, {
      regulated_entity_number: filter.rnNumber,
    });
  }
  if (filter.cityName) {
    innerQuery.terms = Object.assign(innerQuery.terms || {}, {
      city_name: filter.cityName,
    });
  }
  if (filter.state) {
    innerQuery.source_type = filter.state.value;
  }
  if (filter.category) {
    if (filter.category === 'other') {
      innerQuery.mustNot = {};
      innerQuery.mustNot.matchQuery = Object.assign(
        {},
        {
          record_series_name: 'Air WS WATER WQ WST WASTE',
        }
      );
    } else {
      if (filter.recordSeries) {
        innerQuery.terms = Object.assign(innerQuery.terms || {}, {
          record_series_name: filter.recordSeries,
        });
      } else {
        innerQuery.terms = Object.assign(innerQuery.terms || {}, {
          record_series_name: filter.category,
        });
      }
    }
  }

  if (filter.recordTitles) {
    innerQuery.terms = Object.assign(innerQuery.terms || {}, {
      title: filter.recordTitles.label,
    });
  }

  if (filter.entityName) {
    innerQuery.terms = Object.assign(innerQuery.terms || {}, {
      regulated_entity_name: filter.entityName,
    });
  }
  if (filter.zipCode) {
    innerQuery.terms = Object.assign(innerQuery.terms || {}, {
      zip_code: filter.zipCode,
    });
  }
  if (!filter.showEmissionEvents) {
    innerQuery.inclusions = Object.assign(
      {},
      {
        showEmissionEvents: true,
      }
    );
  }
  if (filter.fromReleaseDate) {
    releaseDateFilter = Object.assign(releaseDateFilter, {
      gte: filter.fromReleaseDate.format('DD/MM/YYYY'),
      key: 'release_date',
    });
  }
  if (filter.toReleaseDate) {
    releaseDateFilter = Object.assign(releaseDateFilter, {
      lte: filter.toReleaseDate.format('DD/MM/YYYY'),
      key: 'release_date',
    });
  }
  if (Object.keys(releaseDateFilter).length > 0) {
    innerQuery.dateFilters = (innerQuery.dateFilters || []).concat([releaseDateFilter]);
  }
  return { query: innerQuery };
};

const CeresHome: React.FC<
  RouteComponentProps<any> & {
    context: IAuthContext;
  }
> = (props) => {
  const store: any = useSelector<any>((state): any => state);
  const databaseState: IDatabase = store.database;
  const aiEarthState: IAIEarth = store.aiearth;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState<any>({});
  const [masters, setMasters] = useState();
  const [boundingBox, setBoundingBox] = useState();
  const [programQueryResultCount, setProgramQueryResultCount] = useState();
  const [searchResult, setSearchResult] = useState<IElasticResult<ISearchRecord> | null>(null);
  const [programQueryResult, setProgramQueryResult] = useState<ISearchRecord[]>([]);
  const [programQueryMapResult, setProgramQueryMapResult] = useState<ISearchRecord[]>();
  const [programQueryEPNResult, setProgramQueryEPNResult] = useState<IEPNRecord[]>([]);
  const [activeMarkerRecord, setActiveMarkerRecord] = useState<ISearchRecord | null>(null);
  const [getKeyEvent, setKeyEvent] = useState<number>(0);
  const [showFilter, setShowFilter] = useState(false);
  const [showPanel, setShowPanel] = useState(false);
  const [showDocPanel, setShowDocPanel] = useState(false);
  const [showProgramQueryPanel, setShowProgramQueryPanel] = useState(false);
  const [showProgramQueryDocPanel, setShowProgramQueryDocPanel] = useState(false);
  const [coords, setCoords] = useState();
  const [currentQueryText, setCurrentQueryText] = useState('');
  const [showRecordsLoader, setShowRecordsLoader] = useState(false);
  const [chemPlantResults, setChemPlantResults] = useState([]);
  const [isChemPlantLoading, setIsChemPlantLoading] = useState(false);
  const [pressedKeys, setPressedKeys] = useState<string[]>([]);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const context = props.context;
  const plotLatLong = (records: ISearchRecord[]) => {
    const epnArray: IEPNRecord[] = [];  // Use the defined EPNRecord type
    R.uniqBy(R.prop('entityNumber'), records).map((ele: ISearchRecord) => {
      if (ele.location.geomz) {
        const decryptedData = CryptoJS.AES.decrypt(ele.location.geomz, secKey, {
          iv,
          mode: CryptoJS.mode.CBC,
        }).toString(CryptoJS.enc.Utf8);
        const latLon = decryptedData.split(',');
        ele.location.lon = parseFloat(latLon[0]);
        ele.location.lat = parseFloat(latLon[1]);
      }
      if (ele.location.geom_json) {
        for (const epnKey in ele.location.geom_json) {
          if (ele.location.geom_json.hasOwnProperty(epnKey)) {
            for (const epnInnerKey in ele.location.geom_json[epnKey]) {
              if (ele.location.geom_json[epnKey].hasOwnProperty(epnInnerKey)) {
                const element = ele.location.geom_json[epnKey][epnInnerKey];
                const epnRecord: IEPNRecord = { location: { lat: 0, lon: 0 } };  // Initialize location with default values
                if (element.geom) {
                  const decryptedData = CryptoJS.AES.decrypt(element.geom, secKey, {
                    iv,
                    mode: CryptoJS.mode.CBC,
                  }).toString(CryptoJS.enc.Utf8);
                  const latLon = decryptedData.split(',');
                  epnRecord.location.lon = parseFloat(latLon[0]);
                  epnRecord.location.lat = parseFloat(latLon[1]);
                  epnRecord.marker = element.marker;
                  epnRecord.name = element.name;
                  epnRecord.type = element.type;
                  epnRecord.details = element.details;
                  if (ele.entityNumber) {
                    epnRecord.entityNumber = ele.entityNumber;
                    epnArray.push(epnRecord);
                  }
                }
              }
            }
          }
        }
      }
      return ele;
    });
    setProgramQueryEPNResult(epnArray);  // Use the correctly typed epnArray
  };

  const constructChemPlantQuery = (queryText: any) => {
    let query = `v_keyword=${queryText}&`;
    if(Object.keys(filter).length === 0) {
      return query;
    }

    if (filter.rnNumber) {
      query+=`v_entity_number=${filter.rnNumber}&`;
    }
    if (filter.cityName) {
      query+=`v_city=${filter.cityName}&`;
    }
    if (filter.state && filter.state.label) {
      const stateSymbol = filter.state.label.substring(filter.state.label.indexOf('(') + 1, filter.state.label.indexOf(')'));
      query+=`v_state=${stateSymbol}&`;
    }
    if (filter.zipCode) {
      query+=`v_zip_code=${filter.zipCode}`;
    }

    return query;
  };

  const splitTextSearch = (queryText: string = '', from = 0, mergeRecords = false) => {
    if(mergeRecords) {
      setShowRecordsLoader(true);
    } else {
      setIsLoading(true);
    }
    return search(applyFilterToQuery({ query: { from, text: queryText, size: 5 } }, filter || {}))
      .then((result) => {
        const data = result.data as IElasticResult<ISearchRecord>;
        plotLatLong(data.records);
        if(mergeRecords) {
          setSearchResult((prev: any) =>  {
            return {
              ...data,
              size: prev ? prev.size + data.size : data.size,
              records: prev ? [...prev.records, ...data.records] : data.records,
            };
          });
        } else {
          setSearchResult(data);
        }
      })
      .finally(() => {setIsLoading(false); setShowRecordsLoader(false);});
  };

  const getChemPlantResults = async (queryText: string = '') => {
    setIsChemPlantLoading(true);
    const chemPlantQuery = constructChemPlantQuery(queryText);
    const chemPlantsResponse: any = await axios.get(`${process.env.REACT_APP_CERES_URL}/EntitySearch?${chemPlantQuery}&v_epn_only=1`);
    if(chemPlantsResponse.data && chemPlantsResponse.data.error !== 'No Entity Found!') {
      setChemPlantResults(chemPlantsResponse.data.entities);
    } else {
      setChemPlantResults([]);
    }
    setIsChemPlantLoading(false);
  };

  const doTextSearch = async (queryText: string = '', from = 0, isChemPlant: any = false) => {
    if(isChemPlant) {
      await getChemPlantResults(queryText);
      await splitTextSearch(queryText, from);
      await splitTextSearch(queryText, from + 5, true);
    } else {
      await splitTextSearch(queryText, from);
      await splitTextSearch(queryText, from + 5, true);
      await getChemPlantResults(queryText);
    }
  };

  const doProgramSearch = (queryData: IDatabase, from = 0) => {
    const queryText = { ...queryData, ...coords };
    const requestBody: any = {};
    Object.keys(queryText).forEach((key) => {
      if (!!queryText[key]) {
        if (key === 'cityName') {
            requestBody.cities = queryText[key];
        } else if (key === 'entityName') {
            requestBody.entity_names = queryText[key];
        } else if (key === 'rnNumber') {
            requestBody.entity_ids = queryText[key];
        } else if (key === 'programId') {
            requestBody.program_ids = queryText[key];
        } else if (key === 'zipCode') {
            requestBody.zips = queryText[key];
        } else if (key === 'street') {
            requestBody.addresses = queryText[key];
        } else if (key === 'mapOptions') {
            requestBody.geocode_status = queryText[key];
        } else if (key === 'offset') {
            requestBody.offset = queryText[key];
        } else if (key === 'limit') {
            requestBody.limit = queryText[key];
        } else if (key === 'state') {
            requestBody.state = queryText[key].value;
        } else if (key === 'boundingBox' && boundingBox) {
          requestBody.bounding_box = queryText[key];
        } else if (key === 'county') {
          requestBody.counties = queryText[key].map((el: any) => {
            return el.value;
          });
        } else if (key === 'program') {
          requestBody.programs = queryText[key].map((el: any) => {
            return el.value;
          });
        } else if (key === 'sortDirection') {
            requestBody.sort_dir = queryText[key];
        } else if (key === 'sortColumn') {
            requestBody.sort_col = queryText[key];
        } else if (key === 'source') {
          if (!!queryText[key]) {
            requestBody.source = queryText[key].value;
        }
      }
      }
      if (queryText.offset === 0) {
        requestBody.offset = 0;
      }
    });
    try {
      setIsLoading(true);
      axios
        .post(
          API_BASE_URL + '/api/programQuery',
          // 'http://localhost:5000/ceres-platform-test/us-central1/api/programQuery',
          requestBody,
          {
          headers: API_AUTH_HEADERS,
          }
        )
        .then((response) => {
          setIsLoading(false);
          const resultRecords = response.data[0].query_programs.data;
          const programQueryRecords: ISearchRecord[] = [];
          const epnArray: any[] = [];
          resultRecords.forEach((record: any) => {
            const tempRecord: ISearchRecord = { ...dummyRecord };
            tempRecord.contentId = record.id;
            tempRecord.entityName = record.nm;
            tempRecord.entityNumber = record.en;
            tempRecord.entitySource = record.es;
            tempRecord.secondaryId = record.si;
            tempRecord.address = record.ad;

            if (!!record.gm) {
              const decryptedData = CryptoJS.AES.decrypt(record.gm, secKey, {
                iv,
                mode: CryptoJS.mode.CBC,
              }).toString(CryptoJS.enc.Utf8);
              const latLon = decryptedData.split(',');
              tempRecord.location = {
                lon: parseFloat(latLon[0]),
                lat: parseFloat(latLon[1]),
                geomz: record.gm || '', // Provide default or decrypted value
                geom_json: record.geom_json || {}, // Provide default or fetched value
              };
            }

            if (record.geom_json) {
              Object.keys(record.geom_json).forEach((key) => {
                Object.keys(record.geom_json[key]).forEach((innerKey) => {
                  const element = record.geom_json[key][innerKey];
                  if (element.geom) {
                    const decryptedData = CryptoJS.AES.decrypt(element.geom, secKey, {
                      iv,
                      mode: CryptoJS.mode.CBC,
                    }).toString(CryptoJS.enc.Utf8);
                    const latLon = decryptedData.split(',');
                    epnArray.push({
                      location: {
                        lon: parseFloat(latLon[0]),
                        lat: parseFloat(latLon[1]),
                      },
                      marker: element.marker,
                      name: element.name,
                      type: element.type,
                      details: element.details,
                      entityNumber: key,
                    });
                  }
                });
              });
            }
            programQueryRecords.push(tempRecord);
          });

          setShowProgramQueryPanel(true);
          const programQueryMapRecords = programQueryRecords.filter((rec) => rec.location != null);
          setProgramQueryEPNResult(epnArray);
          setProgramQueryResult(programQueryRecords);
          setProgramQueryMapResult(programQueryMapRecords);
          setProgramQueryResultCount(resultRecords[0].fc);
          setShowPanel(false);
          setShowDocPanel(false);
          setActiveMarkerRecord(programQueryRecords[0]);
          setShowProgramQueryDocPanel(false);
          setKeyEvent(0);
        })
        .catch((err) => {
          CeresLogger.error(err);
          setIsLoading(false);
        })
        .finally(() => setIsLoading(false));
    } catch (e) {
      CeresLogger.error(e);
    }
  };

  if (pressedKeys && programQueryResultCount) {
    pressedKeys.map((e: any) => {
      let rowCount = getKeyEvent;
      setPressedKeys([]);
      if (e === 'ArrowUp' || e === 'ArrowDown') {
        if (e === 'ArrowUp') {
          if (getKeyEvent >= 0) {
            rowCount = getKeyEvent - 1;
            setKeyEvent(rowCount);
          }
        } else if (e === 'ArrowDown') {
          rowCount = getKeyEvent + 1;
          setKeyEvent(rowCount);
        }
        if (programQueryResult[rowCount]) {
          setActiveMarkerRecord(programQueryResult[rowCount]);
          setShowProgramQueryDocPanel(false);
        }
      }
      if (e === 'Enter') {
        setShowProgramQueryDocPanel(true);
      }
      return '';
    });
  }
  const sortColumnMapping: { [index: string]: any } = {
    entityName: 'entity_name',
    address: 'address',
    entitySource: 'entity_source',
  };
  const renderDefaultSearch = () => {
    return (
      <>
        <AiEarthContainer
          plotLatLong={plotLatLong}
          onPreviousClick={() => {
            if (!searchResult) {
              return;
            }
            const currentFrom = searchResult.from;
            const currentSize = searchResult.size;
            setIsLoading(true);
            return doTextSearch(currentQueryText, currentFrom - currentSize);
          }}
          onNextClick={() => {
            if (!searchResult) {
              return;
            }
            const currentFrom = searchResult.from;
            const currentSize = searchResult.size;
            return doTextSearch(currentQueryText, currentFrom + currentSize);
          }}
          programQueryNextClick={() => {
            const newFilter = {
              ...databaseState,
              offset: databaseState.offset + 50,
            };
            dispatch(updateDatebase(newFilter));
            doProgramSearch(newFilter);
          }}
          programQueryPreviousClick={() => {
            const newFilter = {
              ...databaseState,
              offset: databaseState.offset - 50,
            };
            dispatch(updateDatebase(newFilter));
            doProgramSearch(newFilter);
          }}
          result={searchResult}
          programQueryResult={programQueryResult}
          programQueryMapResult={programQueryMapResult}
          programQueryEPNResult={programQueryEPNResult}
          setProgramQueryEPNResult={setProgramQueryEPNResult}
          programQueryResultCount={programQueryResultCount}
          activeMarkerRecord={activeMarkerRecord}
          setActiveMarkerRecord={setActiveMarkerRecord}
          isLoading={isLoading}
          showPanel={showPanel}
          setShowPanel={setShowPanel}
          showDocPanel={showDocPanel}
          setShowDocPanel={setShowDocPanel}
          currentQueryText={currentQueryText}
          showProgramQueryPanel={showProgramQueryPanel}
          setShowProgramQueryPanel={setShowProgramQueryPanel}
          showProgramQueryDocPanel={showProgramQueryDocPanel}
          setShowProgramQueryDocPanel={setShowProgramQueryDocPanel}
          boundingBox={boundingBox}
          setBoundingBox={setBoundingBox}
          setKeyEvent={setKeyEvent}
          getPageRoute={props.history.location.pathname}
          showRecordsLoader={showRecordsLoader}
          chemPlantResults={chemPlantResults}
          isChemPlantLoading={isChemPlantLoading}
          onRectDrag={(boxCoords: any) => {
            setCoords(boxCoords);
          }}
          coords={coords}
          ProgramQuery={() => (
            <ProgramQuery
              isLoading={isLoading}
              setBoundingBox={setBoundingBox}
              boundingBox={boundingBox}
              masters={masters}
              getPageRoute={props.history.location.pathname}
              onSearchSubmit={(queryText: IDatabase) => {
                if (!!!queryText && !!!filter) {
                  return;
                }
                doProgramSearch(queryText);
              }}
            />
          )}
          handleSort={(column: any, sortDirection: any) => {
            const colName = column.selector;
            const newFilter = {
              ...databaseState,
              sortColumn: sortColumnMapping[colName],
              sortDirection: sortDirection.toUpperCase(),
            };
            dispatch(updateDatebase(newFilter));
            doProgramSearch(newFilter);
          }}
          SearchForm={(searchFormProps?: any) => {
            return (
            <SearchForm
              isFilterApplied={filter}
              onFilterClick={() => setShowFilter(true)}
              isLoading={isLoading}
              isChemPlant={searchFormProps.isChemPlant}
              inputRef={searchInputRef}
              onSearchSubmit={(queryText: string) => {
                if (!!!queryText && !!!filter) {
                  return;
                }
                setCurrentQueryText(queryText);
                setShowPanel(false);
                setShowDocPanel(false);
                setShowProgramQueryPanel(false);
                setShowProgramQueryDocPanel(false);
                return doTextSearch(queryText, 0, searchFormProps.isChemPlant);
              }}
              currentQueryText={currentQueryText}
            />
          );}
        }
        />
      </>
    );
  };

  const fetchLists = () => {
    try {
      setIsLoading(true);
      axios
        .get(
          API_BASE_URL + '/api/programQuery/lists',
          // 'http://localhost:5000/ceres-platform-test/us-central1/api/programQuery',
          {
            headers: API_AUTH_HEADERS,
          }
        )
        .then((response) => {
          setIsLoading(false);
          const resultLists = response.data[0].jsonlistb;
          localStorage.setItem('masters', JSON.stringify(resultLists));
          localStorage.setItem('resetMasters', 'false');
          setMasters(resultLists);
        })
        .catch((err) => {
          CeresLogger.error(err);
          setIsLoading(false);
        })
        .finally(() => setIsLoading(false));
    } catch (e) {
      CeresLogger.error(e);
    }
  };

  useEffect(() => {
    if (searchInputRef.current !== null) {
      searchInputRef.current.focus();
    }
    const storageMasters = localStorage.getItem('masters');
    const resetMasters = localStorage.getItem('resetMasters');
    if (!storageMasters || !resetMasters || resetMasters === 'true') {
      fetchLists();
    } else {
      setMasters(JSON.parse(storageMasters));
    }

    const onKeypress = (data: any) => {
      if (data.key === 'ArrowUp' || data.key === 'ArrowDown' || data.key === 'Enter') {
        setPressedKeys([data.key]);
      }
    };
    document.addEventListener('keydown', onKeypress);
    return () => {
      document.removeEventListener('keydown', onKeypress);
      dispatch(resetAir());
      dispatch(resetAIEarth());
      dispatch(resetMap());
      dispatch(resetJob());
      dispatch(resetDatabase());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userTier = context.getUserTier();

  return (
    <>
      <SearchContainer tier={userTier}>{renderDefaultSearch()}</SearchContainer>
      <AdvancedSearchFilter
        onFilterClear={() => setFilter(null)}
        onFilterApply={(newFilter) => {
          setFilter(newFilter);
          setShowFilter(false);
        }}
        onClose={() => setShowFilter(false)}
        show={showFilter}
        disableFields={aiEarthState.activeTab === 'ChemicalPlants'}
      />
      <Footer />
    </>
  );
};

export default withAuthContext(CeresHome);

import React, { useState } from 'react';
import styled from 'styled-components';
import { IAuthContext } from '../context/auth/auth-context';
import withAuthContext from '../context/auth/AuthConsumer';
import {
  Button,
  Row,
  Col,
  FormGroup,
  FormControl,
  FormText,
  Form,
  Spinner,
} from 'react-bootstrap';
import axios from 'axios';
import { CeresLogger } from '../logger';
import { toast } from 'react-toastify';
import { RouteComponentProps } from 'react-router';
import { API_BASE_URL, API_AUTH_HEADERS } from '../util/api.util';

const TrialWrapper = styled.div`
  max-width: 600px;
  width: 100%;
  margin: 35px auto;
  background: rgba(244, 134, 12, 0.75);
  padding: 35px 50px;
`;
const Title = styled.h1`
  text-align: center;
  font-size: 36px;
  margin-bottom: 30px;
  font-weight: 700;
`;
const Label = styled.label`
  font-size: 20px;
  font-weight: 500;
`;
const Submit = styled(Button)`
  border: 1px solid #005fbc;
  padding: 8px 30px;
  width: 180px;
  display: block;
  text-align: center;
  border-radius: 4px;
  color: #005fbc !important;
  background-color: #fff;
  font-size: 20px;
  font-weight: 700;
  &:hover {
    background-color: #fff;
  }
`;

interface IUserData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  location: string;
  message: string;
}

interface IIUserDatarState extends IUserData {}
const initialUserState = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  location: '',
  message: 'Please set me up with a one week trial.',
} as IIUserDatarState;

const TrialRequest: React.FC<
  RouteComponentProps<any> & { context: IAuthContext }
> = (props) => {
  const [userData, setUserData] = useState<IIUserDatarState>(initialUserState);
  const [isLoading, setLoading] = useState<boolean>(false);
  const handleSubmit = (data: IIUserDatarState) => {
    setLoading(true);
    try {
      axios
        .post(
          API_BASE_URL + '/api/trial-request',
          //  'http://localhost:5000/ceres-platform-test/us-central1/api/trial-request',
          data,
          {
            headers: API_AUTH_HEADERS,
          }
        )
        .then((response) => {
          if (response.data === 'success') {
            toast.success('You have successfully send request!');
            return props.history.push('/');
          } else {
            toast.error('Value missing in required field.');
          }
        })
        .catch((err) => {
          CeresLogger.error(err);
        })
        .finally(() => setLoading(false));
    } catch (e) {
      CeresLogger.error(e);
    }
  };
  return (
    <TrialWrapper>
      <Title>Get 1 Week Free Trial</Title>
      <Form
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault();
          handleSubmit(userData);
        }}
      >
        <FormGroup>
          <Label>Name</Label>
          <Row>
            <Col md={6}>
              <FormControl
                onChange={(e: any) => {
                  const newData = { ...userData, firstName: e.target.value };
                  setUserData(newData);
                }}
                value={userData.firstName}
                name="firstName"
                required
              />
              <FormText>First Name</FormText>
            </Col>
            <Col md={6}>
              <FormControl
                onChange={(e: any) => {
                  const newData = { ...userData, lastName: e.target.value };
                  setUserData(newData);
                }}
                value={userData.lastName}
                name="lastName"
                required
              />
              <FormText>Last Name</FormText>
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Label>
            Company <small>(optional)</small>
          </Label>
          <FormControl
            onChange={(e: any) => {
              const newData = { ...userData, location: e.target.value };
              setUserData(newData);
            }}
            value={userData.location}
            name="location"
            required
          />
        </FormGroup>
        <FormGroup>
          <Label>Email</Label>
          <FormControl
            onChange={(e: any) => {
              const newData = { ...userData, email: e.target.value };
              setUserData(newData);
            }}
            value={userData.email}
            name="email"
            type="email"
            required
          />
        </FormGroup>
        <FormGroup>
          <Label>Phone Number</Label>
          <FormControl
            onChange={(e: any) => {
              const newData = { ...userData, phone: e.target.value };
              setUserData(newData);
            }}
            value={userData.phone}
            name="phone"
            required
          />
        </FormGroup>
        <FormGroup>
          <Label>
            Message <small>(optional)</small>
          </Label>
          <FormControl
            onChange={(e: any) => {
              const newData = { ...userData, message: e.target.value };
              setUserData(newData);
            }}
            value={userData.message}
            name="message"
            as="textarea"
            rows="5"
          />
        </FormGroup>
        <FormGroup>
          <Submit type="submit">
            {isLoading && (
              <Spinner
                animation="border"
                size="sm"
                style={{
                  color: '#d07209',
                  height: '20px',
                  width: '20px',
                  marginRight: '10px',
                }}
              />
            )}
            Submit
          </Submit>
        </FormGroup>
      </Form>
    </TrialWrapper>
  );
};

export default withAuthContext(TrialRequest);

import React, { useState } from 'react';
import { ListGroup, Modal, Spinner, Tabs, Tab } from 'react-bootstrap';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import axios from 'axios';
import { IAuthContext } from '../../context/auth/auth-context';
import withAuthContext from '../../context/auth/AuthConsumer';
import NOTIFICATION_TYPE from '../../types/notification-type';
import { CeresLogger } from '../../logger';
import { API_BASE_URL } from '../../util/api.util';

const LoadingSpinner = styled(Spinner)`
  && {
    align-self: center;
  }
`;
const EntitySubscriptionsListBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const SubscriptionListGroup = styled(ListGroup)`
  flex: 1;
  padding-top: 10px;
`;

const SubscriptionListItem = styled(ListGroup.Item)``;

const SubscriptionTab = styled(Tab)``;
const SubscriptionTabs = styled(Tabs)``;

const renderSubscriptionList = (subscriptionList: any, unSubscribe: any) => {
  const nType: any[] = Object.values(NOTIFICATION_TYPE);
  const nTypeKeys: any[] = Object.keys(NOTIFICATION_TYPE);

  return (
    <SubscriptionTabs defaultActiveKey="substab_0" id="subslist-tabs">
      {nType.map((key, i) => {
        return (
          <SubscriptionTab
            eventKey={'substab_' + i}
            key={'substab_' + i}
            title={nTypeKeys[i]}
          >
            <SubscriptionListGroup>
              {typeof subscriptionList !== 'undefined' &&
                subscriptionList
                  .filter((subscription: any) => {
                    return subscription.document_source === key;
                  })
                  .map((subscription: any, index: number) => {
                    return (
                      <SubscriptionListItem key={'item_' + i + '_' + index}>
                        {subscription.keyword + ''}
                        <button
                          type="button"
                          className="close"
                          aria-label="Close"
                          onClick={() =>
                            unSubscribe(subscription.subscription_id)
                          }
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </SubscriptionListItem>
                    );
                  })}
            </SubscriptionListGroup>
          </SubscriptionTab>
        );
      })}
    </SubscriptionTabs>
  );
};

const EntitySubscriptionsList: React.FC<{
  show: boolean;
  isLoadData: boolean;
  setLoadData: any;
  onHide: () => void;
  context: IAuthContext;
  getSubjectTrack: any;
  setShowFrom: any;
  subscriptionsList: any;
  setSubscriptionsList: any;
}> = (props) => {
  const context = props.context;
  const { subscriptionsList, setSubscriptionsList } = props;
  const userEmail = context.getUserEmail();

  const [isLoading, setIsLoading] = useState(false);

  const unSubscribe = async (subscriptionId: any) => {
    const config = {
      params: {
        email: userEmail,
        action: 'subscription_delete',
        subscription_id: subscriptionId,
      },
    };
    try {
      await axios
        .get(
          API_BASE_URL + '/AgencyTracker',
          config
        )
        .then((response) => {
          props.setShowFrom();
          props.getSubjectTrack();
          const newData = subscriptionsList.filter((subscription: any) => {
            return subscription.subscription_id !== subscriptionId;
          });
          setSubscriptionsList(newData);
        })
        .catch((error) => {
          CeresLogger.error(error);
          toast.error(`Failed to load subscription.`);
        })
        .finally(() => setIsLoading(false));
    } catch (e) {
      CeresLogger.error(e);
    }
    return null;
  };

  return (
    <Modal
      dialogClassName="ceres--subscription-list"
      backdropClassName="coust-backdrop"
      style={{ zIndex: 10001 }}
      show={props.show || false}
      onHide={props.onHide}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Words/Subjects to Track List</Modal.Title>
      </Modal.Header>
      <EntitySubscriptionsListBody>
        {isLoading && <LoadingSpinner variant="primary" animation="border" />}
        {!isLoading && renderSubscriptionList(subscriptionsList, unSubscribe)}
      </EntitySubscriptionsListBody>
    </Modal>
  );
};

export default withAuthContext(EntitySubscriptionsList);

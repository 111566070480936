export const emissionTypeConstants = {
  EMISSIONS_EVENTS: 'emissions_events',
  ANNUAL_FACILITY_EMISSIONS: 'annual_facility_emissions',
  ENFORCEMENT: 'enforcement'
};

export const emissionType = [
  {
    label: 'Emissions Events',
    value: emissionTypeConstants.EMISSIONS_EVENTS,
  },
  {
    label: 'Annual Facility Emissions',
    value: emissionTypeConstants.ANNUAL_FACILITY_EMISSIONS,
  },
  {
    label: 'Enforcement',
    value: emissionTypeConstants.ENFORCEMENT,
  },
];

import * as JSZip from 'jszip';

import { IAIEarth, IActiveJob, IJob, IMap } from '../../store/aiearth/types';
import React, { useEffect, useState } from 'react';
import { buffer, centroid, multiLineString, multiPolygon, polygon } from '@turf/turf';
import { updateAIEarth, updateJob, updateMap } from '../../store/aiearth/action';
import { useDispatch, useSelector } from 'react-redux';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Backdrop from '@material-ui/core/Backdrop';
import { CeresLogger } from '../../logger';
import DeleteIcon from '@material-ui/icons/Delete';
import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';
import MUIButton from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Moment from 'moment';
import PaperContainer from '../../components/PaperContainer';
import PinDropIcon from '@material-ui/icons/PinDrop';
import PlacesSearch from './map/PlacesSearch';
import SaveIcon from '@material-ui/icons/Save';
import { Scrollbars } from 'react-custom-scrollbars';
import { Spinner, Table, Col, Row, InputGroup, Button, FormControl as FormControl2, OverlayTrigger } from 'react-bootstrap';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import TextField from '@material-ui/core/TextField';
import { Upload, Search } from 'react-feather';
import axios from 'axios';
import { activeJobInitial as jobDataInitial } from '../../store/aiearth/reducer';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import ReactTooltip from 'react-tooltip';
import { API_BASE_URL, API_AUTH_HEADERS } from '../../util/api.util';

interface IJobFilter {
  JobFilter: string;
  jobStatusFilter: string;
}
const JobTypeContainer = styled.div`
  width: 20rem;
  margin-left: 0.5rem;
  background-color: #a3bcf1;
  border-radius: 4px;
`;

const TD = styled.td`
  padding: 0.5rem !important;
  vertical-align: middle !important;
  & svg {
    cursor: pointer;
    margin-left: 0.5rem;
  }
`;
const CustomFormControl = styled(FormControl)`
  width: 100%;
  border-radius: 4px;
`;
const SearchBarInputContainer = styled.div`
  width: 100%;
`;
const JobCreationControls = styled.div`
  margin-top: 15px;
`;

const JobContainer = styled.div`
  margin: 10px 0;
`;
const StepContent = styled.div`
  display: flex;
  justify-content: center;
`;
const JobButton = styled(MUIButton)`
  margin: 0.2rem !important;
`;
const CustomTextField = styled.div`
  margin: 1rem 0 !important;
`;
const WizardContainer = styled.div`
  margin-bottom: 0.4rem !important;
`;

const FileDropText = styled.p`
  font-size: 14px;
  padding-top: 2%;
  text-align: center;
`;

const FileDropIcon = styled.div`
  color: #005fbc;
  color: var(--primary);
  text-align: center;
  padding: 2%;
`;

const Jobs: React.FC<any> = (props) => {
  const { gMap, gMaps, context, setJobReport, setSubjectPropertyEsaData, resetJobMap } = props;
  const getSteps = () => {
    return ['Job Name', 'Address or Place', 'Job Type', 'GW Gradient', 'Boundary'];
  };

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      top: '4rem !important',
      bottom: 'unset !important',
      left: '4rem !important',
      right: 'unset !important',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));
  const JobFilter: IJobFilter = {
    JobFilter: '',
    jobStatusFilter: 'Active',
  };
  const classes = useStyles();
  const dispatch = useDispatch();
  const store: any = useSelector<any>((state): any => state);
  const aiEarthState: IAIEarth = store.aiearth;
  const mapState: IMap = store.map;
  const jobState: IJob = store.job;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [jobJson, setJobJson] = useState<any>();
  const [jobData, setJobData] = useState(jobDataInitial);
  const [jobs, setJobs] = useState<any>([]);
  const [fetchFlag, setFetchFlag] = useState<boolean>(false);
  const [errorName, setErrorName] = useState<boolean>(false);
  const [jobFilter, setJobFilter] = useState<IJobFilter>(JobFilter);
  const steps = getSteps();
  const userEmail = context.getUserEmail();

  const extractPolygons = async (plainText: any) => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(plainText, 'text/html');
    const googlePolygons = [];
    if (xmlDoc.getElementsByTagName('kml').length > 0) {
      for (const item of xmlDoc.getElementsByTagName('Placemark') as any) {
        const polygons = item.getElementsByTagName('Polygon');
        for (const polygonCoords of polygons) {
          const coords = polygonCoords.getElementsByTagName('coordinates')[0].childNodes[0].nodeValue.trim();
          const points = coords.split(' ');
          const googlePolygonsPaths = [];
          for (const point of points) {
            const coord = point.split(',');
            googlePolygonsPaths.push([parseFloat(coord[0]), parseFloat(coord[1])]);
          }
          googlePolygons.push(googlePolygonsPaths);
        }
      }
    }
    return { polygons: googlePolygons };
  };

  const readKML = (KMFile: any, name: string) => {
    if (KMFile && mapState.gMaps && name === 'kml') {
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        const result: any = await extractPolygons(e.target.result);
        const polygonObj: any = polygon(result.polygons);
        mapState.subjectPropertyShowLayer.addGeoJson(polygonObj);
        setJobJson(polygonObj);
        dispatch(
          updateJob({
            ...jobState,
            ...{
              jobCreate: 'createJob',
              jobJson: polygonObj,
              wizardOpen: false,
            },
          })
        );
        const centroidObj = centroid(polygonObj).geometry;
        if (centroidObj) {
          const latlng = new gMaps.LatLng(centroidObj.coordinates[1], centroidObj.coordinates[0]);
          gMap.panTo(latlng);
          gMap.setZoom(18);
        }
      };
      reader.readAsText(KMFile);
    }
  };

  const onDrop = async (acceptedFiles: any) => {
    let KMFile = acceptedFiles[0];
    let name = KMFile.name.split('.').pop();
    if (name === 'kmz') {
      const zip = await JSZip.loadAsync(KMFile);
      Object.keys(zip.files).forEach(async (filename: string) => {
        const match = filename.match(/.kml$/);
        if (match) {
          const blob = await zip!.file(filename)!.async('blob');
          KMFile = new File([blob], filename);
          name = 'kml';
          readKML(KMFile, name);
        }
      });
    } else if (name === 'kml') {
      readKML(KMFile, name);
    }
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const removeSubjectProperty = () => {
    if (mapState.subjectPropertyShowLayer) {
      mapState.subjectPropertyShowLayer.forEach((feature: any) => {
        mapState.subjectPropertyShowLayer.remove(feature);
      });
    }
    if (mapState.oilGasJobMaps) {
      mapState.oilGasJobMaps.forEach((feature: any) => {
        mapState.oilGasJobMaps.remove(feature);
      });
    }
    if (mapState.adjoiningPropertiesBoundaries) {
      mapState.adjoiningPropertiesBoundaries.forEach((feature: any) => {
        mapState.adjoiningPropertiesBoundaries.remove(feature);
      });
    }
    if (mapState.pipeLinesJobMaps) {
      mapState.pipeLinesJobMaps.forEach((feature: any) => {
        mapState.pipeLinesJobMaps.remove(feature);
      });
    }
    if (mapState.adjoiningPropertyLayer) {
      mapState.adjoiningPropertyLayer.forEach((feature: any) => {
        mapState.adjoiningPropertyLayer.remove(feature);
      });
    }
    if (mapState.waterWellsPointer) {
      mapState.waterWellsPointer.forEach((feature: any) => {
        mapState.waterWellsPointer.remove(feature);
      });
    }
    if (mapState.monitorWellsPointer) {
      mapState.monitorWellsPointer.forEach((feature: any) => {
        mapState.monitorWellsPointer.remove(feature);
      });
    }
    if (mapState.pipelineLayerRef) {
      mapState.pipelineLayerRef.forEach((feature: any) => {
        mapState.pipelineLayerRef.remove(feature);
      });
    }
    if (mapState.soilsPropertyLayer) {
      mapState.soilsPropertyLayer.forEach((feature: any) => {
        mapState.soilsPropertyLayer.remove(feature);
      });
    }
  };
  const setSubjectProperty = (subjectPropertyData: any) => {
    if (subjectPropertyData) {
      removeSubjectProperty();
      let polygonObj: any = {};
      const subjectPropJson = subjectPropertyData.geoJson;
      const adjoiningPropJsonArray = subjectPropertyData.adjoiningProperties;
      if (subjectPropJson) {
        if (subjectPropJson.type === 'Polygon') {
          polygonObj = polygon(subjectPropJson.coordinates);
          mapState.subjectPropertyShowLayer.addGeoJson(polygonObj);
        } else if (subjectPropJson.type === 'MultiPolygon') {
          polygonObj = multiPolygon(subjectPropJson.coordinates);
          mapState.subjectPropertyShowLayer.addGeoJson(polygonObj);
        } else if (subjectPropJson.type === 'MultiLineString') {
          polygonObj = multiLineString(subjectPropJson.coordinates);
          mapState.subjectPropertyShowLayer.addGeoJson(polygonObj);
        }

        if (adjoiningPropJsonArray.length > 0) {
          adjoiningPropJsonArray.forEach((adjPropJson: any) => {
            const geojson = adjPropJson.geojson;
            if (geojson.type === 'Polygon') {
              polygonObj = polygon(geojson.coordinates);
              mapState.adjoiningPropertyLayer.addGeoJson(polygonObj);
            } else if (geojson.type === 'MultiPolygon') {
              polygonObj = multiPolygon(geojson.coordinates);
              mapState.adjoiningPropertyLayer.addGeoJson(polygonObj);
            } else if (geojson.type === 'MultiLineString') {
              polygonObj = multiLineString(geojson.coordinates);
              mapState.adjoiningPropertyLayer.addGeoJson(polygonObj);
            }
          });
        }
        const centroidObj = centroid(polygonObj).geometry;
        if (centroidObj) {
          const latlng = new gMaps.LatLng(centroidObj.coordinates[1], centroidObj.coordinates[0]);
          gMap.panTo(latlng);
          gMap.setZoom(18);
        }
      }
    }
  };

  const cancelJob = () => {
    removeSubjectProperty();
    setJobData(jobDataInitial);
    dispatch(
      updateJob({
        ...jobState,
        ...{
          jobCreate: 'cancel',
          jobAction: true,
          jobInit: false,
          taskWizard: false,
          activeStep: 0,
          taskWizardStep: 0,
          adjoiningProperties: [],
          activeJob: jobDataInitial,
        },
      })
    );
    mapState.drawingManager.setDrawingMode(null);
  };

  const fetchJobs = () => {
    const requestBody: any = {
      userId: userEmail,
      jobName: jobFilter.JobFilter,
      status: jobFilter.jobStatusFilter,
    };
    try {
      setIsLoading(true);
      axios
        .post(
          API_BASE_URL + '/api/getJobs',
          //  'http://localhost:5000/ceres-platform-test/us-central1/api/getJobs',
          requestBody,
          {
            headers: API_AUTH_HEADERS,
          }
        )
        .then((response) => {
          setJobReport(true);
          setJobs(response.data);
          dispatch(
            updateJob({
              ...jobState,
              ...{
                jobCreate: 'neutral',
                jobsData: response.data,
              },
            })
          );
          setIsLoading(false);
        })
        .catch((err) => {
          CeresLogger.error(err);
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
          dispatch(
            updateJob({
              ...jobState,
              ...{
                jobInit: false,
              },
            })
          );
        });
    } catch (e) {
      CeresLogger.error(e);
    }
  };

  const createJobReport = (jobId: any) => {
    const requestBody: any = { jobId };
    try {
      setIsLoading(true);
      axios
        .post(
          API_BASE_URL + '/api/jobReport',
          //  'http://localhost:5000/ceres-platform-test/us-central1/api/jobReport',
          requestBody,
          {
            headers: API_AUTH_HEADERS,
          }
        )
        .then((response) => {
          if (response.data === 'ok') {
            setJobReport(true);
          }
        })
        .catch((err) => {
          CeresLogger.error(err);
        });
    } catch (e) {
      CeresLogger.error(e);
    }
  };

  const deleteJob = (jobId: any) => {
    const requestBody: any = { jobId };
    try {
      setIsLoading(true);
      axios
        .post(
          API_BASE_URL + '/api/deleteJob',
          //  'http://localhost:5000/ceres-platform-test/us-central1/api/deleteJob',
          requestBody,
          {
            headers: API_AUTH_HEADERS,
          }
        )
        .then((response) => {
          cancelJob();
          fetchJobs();
        })
        .catch((err) => {
          CeresLogger.error(err);
        });
    } catch (e) {
      CeresLogger.error(e);
    }
  };

  const saveJob = () => {
    const requestBody: any = {
      jobJson: {},
      active: true,
      zip: jobState.activeJob.zip,
      state: jobState.activeJob.state,
      city: jobState.activeJob.city,
      address: jobState.activeJob.jobId !== '' ? jobState.activeJob.address : mapState.places.description,
      name: jobState.activeJob.name,
      jobType: jobState.activeJob.jobType,
      userId: userEmail,
      jobId: jobState.activeJob.jobId !== '' ? jobState.activeJob.jobId : uuidv4(),
      actionType: jobState.activeJob.jobId !== '' ? 'update' : 'save',
    };
    const jobJsonData: any = {};

    if (jobState.activeJob.jobId !== '' && jobState.activeJob.jobJson) {
      jobJsonData.geoJson = jobState.activeJob.jobJson;
    } else if (jobState.jobJson && jobState.jobJson.geometry) {
      jobJsonData.geoJson = jobState.jobJson.geometry;
    }

    if (jobState.activeJob.keySiteManagers) {
      jobJsonData.keySiteManagers = jobState.activeJob.keySiteManagers;
    }
    if (jobState.activeJob.users) {
      jobJsonData.users = jobState.activeJob.users;
    }
    if (jobState.adjoiningProperties) {
      jobJsonData.adjoiningProperties = jobState.adjoiningProperties;
    }
    if (jobState.activeJob.status) {
      jobJsonData.status = jobState.activeJob.status;
    }
    if (jobState.activeJob.numberOfDays) {
      jobJsonData.numberOfDays = jobState.activeJob.numberOfDays;
    }
    if (jobState.activeJob.jobStartDate) {
      jobJsonData.jobStartDate = Moment(jobState.activeJob.jobStartDate).format('MM-DD-YYYY');
    }
    if (jobState.activeJob.jobCompleteDate) {
      jobJsonData.jobCompleteDate = Moment(jobState.activeJob.jobCompleteDate).format('MM-DD-YYYY');
    }
    if (jobState.activeJob.jobActualCompleteDate) {
      jobJsonData.jobActualCompleteDate = Moment(jobState.activeJob.jobActualCompleteDate).format('MM-DD-YYYY');
    }
    jobJsonData.facilityName = jobState.activeJob.facilityName;
    jobJsonData.jobNotes = jobState.activeJob.jobNotes;
    jobJsonData.gwGradient = jobState.activeJob.gwGradient;
    requestBody.jobJson = JSON.stringify(jobJsonData);

    try {
      setIsLoading(true);
      axios
        .post(
          API_BASE_URL + '/api/saveJob',
          //  'http://localhost:5000/ceres-platform-test/us-central1/api/saveJob',
          requestBody,
          {
            headers: API_AUTH_HEADERS,
          }
        )
        .then(async (response) => {
          if (response.data) {
            await createJobReport(response.data);
            const esaParams = {
              jobId: response.data,
              gwGradient: jobState.activeJob.gwGradient,
            };
            await generateEsadata(esaParams);
            // if (requestBody.actionType === 'save') {
            await createTopos(response.data);
            await createAerials(response.data, 300);
            // }
          }
          fetchJobs();
          mapState.drawingManager.setDrawingMode(null);
          setJobData(jobDataInitial);
          jobState.activeJob.jobId = response.data;
          dispatch(
            updateJob({
              ...jobState,
              ...{
                jobJson: null,
                jobInit: true,
                jobCreate: 'save',
                jobAction: true,
                taskWizardStep: 0,
              },
            })
          );
          if (Object.keys(jobState.jobsData.esadata).length > 3) {
            setSubjectPropertyEsaData({ geoJson: jobState.jobsData.esadata });
          } else {
            setSubjectProperty({ geoJson: jobState.jobJson.geometry });
          }
        })
        .catch((err) => {
          CeresLogger.error(err);
          setIsLoading(false);
        });
    } catch (e) {
      CeresLogger.error(e);
    }
  };

  const createTopos = (jobId: string) => {
    axios
      .get(`${process.env.REACT_APP_CERES_URL}/CreateJobTopos?jid=${jobId}&lng=0&lat=0`)
      .then((response) => {
        CeresLogger.error(response.data);
      })
      .catch((err) => {
        CeresLogger.error(err);
      });
  };

  const createAerials = (jobId: string, pixelWidth: number) => {
    axios
      .get(`${process.env.REACT_APP_CERES_URL}/CreateJobAerials?jid=${jobId}&image_width_pixels=${pixelWidth}`)
      .then((response) => {
        CeresLogger.error(response.data);
      })
      .catch((err) => {
        CeresLogger.error(err);
      });
  };

  const generateEsadata = (esaParams: any) => {
    const requestBody: any = {
      jobId: esaParams.jobId,
      gwGradient: esaParams.gwGradient,
    };
    jobState.esaData = {};
    jobState.isLoadEsaData = false;
    dispatch(
      updateJob({
        ...jobState,
        ...{
          isLoadEsaData: false,
        },
      })
    );
    try {
      axios
        .post(
          API_BASE_URL + '/api/generateEsadata',
          //  'http://localhost:5000/ceres-platform-test/us-central1/api/generateEsadata',
          requestBody,
          {
            headers: API_AUTH_HEADERS,
          }
        )
        .then((response) => {
          jobState.activeJob.execute = true;
          dispatch(updateJob(jobState));
        })
        .catch((err) => {
          CeresLogger.error(err);
        });
    } catch (e) {
      CeresLogger.error(e);
    }
  };

  const checkJobName = async (name: string) => {
    const requestBody: any = { jobName: name, userId: userEmail };
    try {
      await axios
        .post(
          API_BASE_URL + '/api/checkJobName',
          //  'http://localhost:5000/ceres-platform-test/us-central1/api/checkJobName',
          requestBody,
          {
            headers: API_AUTH_HEADERS,
          }
        )
        .then((response) => {
          setErrorName(Object.keys(response.data).length ? true : false);
        })
        .catch((err) => {
          CeresLogger.error(err);
        });
    } catch (e) {
      CeresLogger.error(e);
    }
  };

  const getStepContent = (stepIndex: any) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <TextField
              label="Name"
              variant="outlined"
              value={jobData.name}
              autoFocus
              size="small"
              onChange={(e: any) => {
                const jobName: string = e.target.value;
                setJobData({ ...jobData, name: jobName });
                setErrorName(jobName ? false : true);
                if (jobName) {
                  checkJobName(jobName);
                }
              }}
              error={errorName}
              helperText={jobData.name && errorName === true ? 'Name already exist!' : ''}
            />
          </>
        );
      case 1:
        return <PlacesSearch value={mapState.places} gMap={gMap} gMaps={gMaps} />;
      case 2:
        return (
          <>
            <JobTypeContainer>
              <Autocomplete
                options={['ASTM Phase 1']}
                getOptionLabel={(option) => option}
                getOptionSelected={(option: string, value: string) => option === value}
                id="job-type"
                value={jobData.jobType}
                onChange={(event: any, newValue: any) => {
                  setJobData({ ...jobData, jobType: newValue });
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Choose Job Type" variant="outlined" size="small" />
                )}
              />
            </JobTypeContainer>
          </>
        );
      case 3:
        return (
          <JobTypeContainer>
            <Autocomplete
              options={[
                'NOT_DETERMINED',
                'N',
                'NNE',
                'NE',
                'ENE',
                'E',
                'ESE',
                'SE',
                'SSE',
                'S',
                'SSW',
                'SW',
                'WSW',
                'W',
                'WNW',
                'NW',
                'NNW',
              ]}
              value={jobData.gwGradient}
              onChange={(event: any, newValue: any) => {
                setJobData({ ...jobData, gwGradient: newValue });
              }}
              getOptionSelected={(option: string, value: string) => option === value}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField {...params} label="Choose Groundwater Gradient" variant="outlined" size="small" />
              )}
            />
          </JobTypeContainer>
        );
      case 4:
        return (
          <JobTypeContainer>
            <Autocomplete
              options={[
                'Parcel Select',
                'Polygon Draw',
                'Multi Polygon Draw',
                'Rectangle Draw',
                'Corridor',
                'Import KML/KMZ boundary',
              ]}
              value={jobData.selectionType}
              onChange={(event: any, newValue: any) => {
                setJobData({ ...jobData, selectionType: newValue });
                const subjectPropertyShowLayer = mapState.subjectPropertyShowLayer;
                if (subjectPropertyShowLayer) {
                  subjectPropertyShowLayer.forEach((feature: any) => {
                    subjectPropertyShowLayer.remove(feature);
                  });
                }
                const parcelSelectionLayer = mapState.parcelSelectionLayer;
                if (parcelSelectionLayer) {
                  parcelSelectionLayer.forEach((feature: any) => {
                    parcelSelectionLayer.remove(feature);
                  });
                }
                mapState.drawingManager.setDrawingMode(null);
              }}
              getOptionSelected={(option: string, value: string) => option === value}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  autoFocus
                  {...params}
                  label="Select tool to create boundary"
                  id="job-selection-type"
                  variant="outlined"
                  size="small"
                />
              )}
            />
            {jobData.selectionType === 'Corridor' && (
              <CustomTextField>
                <TextField
                  label="Corridor Width (ft)"
                  variant="outlined"
                  value={jobData.corridorWidth}
                  size="small"
                  onChange={(e: any) => {
                    setJobData({ ...jobData, corridorWidth: e.target.value });
                  }}
                />
              </CustomTextField>
            )}
            {jobData.selectionType === 'Import KML/KMZ boundary' && (
              <CustomTextField>
                <PaperContainer {...getRootProps()}>
                  <input {...getInputProps()} />
                  <FileDropText>
                    {isDragActive ? 'Drop ' : 'Drag and Drop '}
                    the kml file
                  </FileDropText>
                  <FileDropIcon>
                    {isLoading ? <Spinner variant="secondary" animation="border" /> : <Upload size="50" />}
                  </FileDropIcon>
                </PaperContainer>
              </CustomTextField>
            )}
          </JobTypeContainer>
        );
      default:
        return 'Unknown stepIndex';
    }
  };

  useEffect(() => {
    if (!fetchFlag) {
      fetchJobs();
      setFetchFlag(true);
    }
    dispatch(
      updateJob({
        ...jobState,
        ...{ jobJson },
      })
    );
    // eslint-disable-next-line
  }, [jobJson, jobFilter]);

  useEffect(() => {
    if (jobState.activeJob) {
      setJobData(jobState.activeJob);
    }
  }, [jobState.activeJob]);

  return (
    <>
      <WizardContainer>
        {jobState.jobCreate === 'neutral' && (
          <JobContainer>
            <JobButton
              variant="contained"
              color="primary"
              size="small"
              startIcon={<AddLocationIcon />}
              onClick={() => {
                dispatch(
                  updateJob({
                    ...jobState,
                    ...{
                      jobJson: null,
                      wizardOpen: true,
                      jobInit: false,
                      activeJob: jobDataInitial,
                      activeStep: 0,
                    },
                  })
                );
                removeSubjectProperty();
              }}
            >
              Create
            </JobButton>
          </JobContainer>
        )}
        {['create', 'createJob', 'createAdjoiningProp', 'edit'].includes(jobState.jobCreate) && (
          <>
            <JobContainer>
              <JobButton
                variant="contained"
                color="primary"
                size="small"
                startIcon={<SaveIcon />}
                onClick={() => {
                  dispatch(
                    updateJob({
                      ...jobState,
                      ...{ jobCreate: 'save', jobAction: true },
                    })
                  );
                  saveJob();
                }}
              >
                Save
              </JobButton>
              <JobButton
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                size="small"
                onClick={() => {
                  resetJobMap();
                }}
              >
                Close
              </JobButton>
            </JobContainer>
          </>
        )}
        <Row>
          <Col className="pr-1">
            <SearchBarInputContainer>
              <InputGroup>
                <FormControl2
                  value={jobFilter.JobFilter}
                  onChange={(e: any) => {
                    setFetchFlag(false);
                    setJobFilter({ ...jobFilter, JobFilter: e.target.value });
                  }}
                  placeholder="Job Search"
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                />
                <InputGroup.Append>
                  <Button color="primary" style={{ padding: '5px' }} disabled>
                    <Search />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </SearchBarInputContainer>
          </Col>
          <Col className="pl-1">
            <CustomFormControl size="small" variant="outlined">
              <Autocomplete
                options={['Active', 'Closed', 'Cancelled', 'Proposal', 'Proposal Not Awarded']}
                value={jobFilter.jobStatusFilter}
                getOptionLabel={(option: any) => option}
                getOptionSelected={(option: string, value: string) => option === value}
                fullWidth
                id="job-status"
                onChange={async (event: any, newValue: any) => {
                  setFetchFlag(false);
                  setJobFilter({ ...jobFilter, jobStatusFilter: newValue });
                }}
                disableClearable
                renderInput={(params: any) => (
                  <TextField {...params} label="Job Status" variant="outlined" size="small" />
                )}
              />
            </CustomFormControl>
          </Col>
        </Row>
        <Modal
          open={jobState.wizardOpen}
          onClose={() => {
            dispatch(
              updateJob({
                ...jobState,
                ...{
                  wizardOpen: false,
                },
              })
            );
          }}
          className={classes.modal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{ zIndex: 10001 }}
        >
          <Fade in={jobState.wizardOpen}>
            <div className={classes.paper}>
              <h2>Create Job</h2>
              <Stepper activeStep={jobState.activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <div>
                {jobState.activeStep === steps.length ? (
                  <div>
                    <span>All steps completed</span>
                    <JobButton
                      onClick={() => {
                        dispatch(
                          updateJob({
                            ...jobState,
                            ...{
                              activeStep: 0,
                            },
                          })
                        );
                      }}
                    >
                      Reset
                    </JobButton>
                  </div>
                ) : (
                  <div>
                    <StepContent>{getStepContent(jobState.activeStep)}</StepContent>
                    <JobCreationControls>
                      <JobButton
                        disabled={jobState.activeStep === 0}
                        onClick={() => {
                          dispatch(
                            updateJob({
                              ...jobState,
                              ...{
                                activeStep: jobState.activeStep - 1,
                              },
                            })
                          );
                        }}
                      >
                        Back
                      </JobButton>
                      <JobButton
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          const jobStateObj = jobState;
                          if (jobState.activeStep === steps.length - 1) {
                            mapState.gMaps.event.clearListeners(mapState.drawingManager, 'polygoncomplete');
                            const subjectPropertyShowLayer = mapState.subjectPropertyShowLayer;
                            if (subjectPropertyShowLayer) {
                              subjectPropertyShowLayer.forEach((feature: any) => {
                                subjectPropertyShowLayer.remove(feature);
                              });
                            }
                            const parcelSelectionLayer = mapState.parcelSelectionLayer;
                            if (parcelSelectionLayer) {
                              parcelSelectionLayer.forEach((feature: any) => {
                                parcelSelectionLayer.remove(feature);
                              });
                            }
                            mapState.drawingManager.setDrawingMode(null);
                            const mapStateUpdate: IMap = store.map;
                            const mapLayers = mapStateUpdate.layers;
                            if (!mapLayers.includes('Parcel')) {
                              mapLayers.push('Parcel');
                              mapStateUpdate.layers = mapLayers;
                              mapStateUpdate.execute = true;
                              dispatch(updateMap(mapStateUpdate));
                            }
                            if (jobData.selectionType === 'Parcel Select') {
                              jobStateObj.jobCreate = 'create';
                              jobStateObj.jobAction = true;
                            } else if (jobData.selectionType === 'Polygon Draw') {
                              jobStateObj.jobCreate = 'createJob';
                              mapState.drawingManager.setDrawingMode('polygon');
                              mapState.gMaps.event.addListener(
                                mapState.drawingManager,
                                'polygoncomplete',
                                (polyoverlay: any) => {
                                  const targetLayer = mapState.subjectPropertyShowLayer;
                                  targetLayer.forEach((feature: any) => {
                                    targetLayer.remove(feature);
                                  });
                                  targetLayer.add(
                                    new mapState.gMaps.Data.Feature({
                                      geometry: new mapState.gMaps.Data.Polygon([polyoverlay.getPath().getArray()]),
                                    })
                                  );
                                  targetLayer.toGeoJson((geojson: any) => {
                                    setJobJson(geojson.features[0]);
                                  });
                                  polyoverlay.setMap(null);
                                }
                              );
                            } else if (jobData.selectionType === 'Multi Polygon Draw') {
                              jobStateObj.jobCreate = 'createJob';
                              mapState.drawingManager.setDrawingMode('polygon');
                              mapState.gMaps.event.addListener(
                                mapState.drawingManager,
                                'polygoncomplete',
                                (polyoverlay: any) => {
                                  const targetLayer = mapState.subjectPropertyShowLayer;
                                  targetLayer.add(
                                    new mapState.gMaps.Data.Feature({
                                      geometry: new mapState.gMaps.Data.Polygon([polyoverlay.getPath().getArray()]),
                                    })
                                  );
                                  targetLayer.toGeoJson((geojson: any) => {
                                    const multiPolygonCoordinates: any = [];
                                    geojson.features.forEach((feature: any) => {
                                      multiPolygonCoordinates.push(feature.geometry.coordinates);
                                    });
                                    setJobJson(multiPolygon(multiPolygonCoordinates));
                                  });
                                  polyoverlay.setMap(null);
                                }
                              );
                            } else if (jobData.selectionType === 'Corridor') {
                              jobStateObj.jobCreate = 'createJob';
                              mapState.drawingManager.setDrawingMode('polyline');
                              mapState.gMaps.event.addListener(
                                mapState.drawingManager,
                                'polylinecomplete',
                                (polyoverlay: any) => {
                                  const targetLayer = mapState.subjectPropertyShowLayer;
                                  targetLayer.forEach((feature: any) => {
                                    targetLayer.remove(feature);
                                  });
                                  targetLayer.add(
                                    new mapState.gMaps.Data.Feature({
                                      geometry: new mapState.gMaps.Data.MultiLineString([
                                        polyoverlay.getPath().getArray(),
                                      ]),
                                    })
                                  );
                                  targetLayer.toGeoJson((geojson: any) => {
                                    const targetGeoJson: any = buffer(geojson, jobData.corridorWidth / 5280, {
                                      units: 'miles',
                                    });
                                    targetLayer.forEach((feature: any) => {
                                      targetLayer.remove(feature);
                                    });
                                    targetLayer.addGeoJson(targetGeoJson);
                                    setJobJson(targetGeoJson.features[0]);
                                  });
                                  polyoverlay.setMap(null);
                                }
                              );
                            } else if (jobData.selectionType === 'Rectangle Draw') {
                              jobStateObj.jobCreate = 'createJob';
                              mapState.drawingManager.setDrawingMode('rectangle');
                              mapState.gMaps.event.addListener(
                                mapState.drawingManager,
                                'rectanglecomplete',
                                (rectangle: any) => {
                                  const targetLayer = mapState.subjectPropertyShowLayer;
                                  targetLayer.forEach((feature: any) => {
                                    targetLayer.remove(feature);
                                  });
                                  const bounds = rectangle.getBounds();
                                  const polygonRectangle = [
                                    bounds.getSouthWest(),
                                    {
                                      lat: bounds.getSouthWest().lat(),
                                      lng: bounds.getNorthEast().lng(),
                                    },
                                    bounds.getNorthEast(),
                                    {
                                      lng: bounds.getSouthWest().lng(),
                                      lat: bounds.getNorthEast().lat(),
                                    },
                                  ];
                                  targetLayer.add(
                                    new mapState.gMaps.Data.Feature({
                                      geometry: new mapState.gMaps.Data.Polygon([polygonRectangle]),
                                    })
                                  );
                                  targetLayer.toGeoJson((geojson: any) => {
                                    setJobJson(geojson.features[0]);
                                  });
                                  rectangle.setMap(null);
                                }
                              );
                            }
                            jobStateObj.jobInit = true;
                            jobStateObj.wizardOpen = false;
                            jobStateObj.activeJob = jobData;
                            // jobStateObj.activeJob.address = mapState.places
                            //   .description
                            //   ? mapState.places.description
                            //   : '';
                            jobStateObj.activeJob.address = mapState.address ? mapState.address : '';
                            jobStateObj.activeJob.zip = mapState.zip ? mapState.zip : '';
                            jobStateObj.activeJob.city = mapState.city ? mapState.city : '';
                            jobStateObj.activeJob.state = mapState.state ? mapState.state : '';
                          }
                          if (jobData.name === '') {
                            setErrorName(true);
                            return false;
                          } else {
                            setErrorName(false);
                          }
                          dispatch(
                            updateJob({
                              ...jobStateObj,
                              ...{
                                activeStep: jobStateObj.activeStep + 1,
                              },
                            })
                          );
                          dispatch(
                            updateAIEarth({
                              ...aiEarthState,
                              ...{
                                activeTab: 'jobs',
                              },
                            })
                          );
                        }}
                        disabled={errorName}
                      >
                        {jobState.activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                      </JobButton>
                    </JobCreationControls>
                  </div>
                )}
              </div>
            </div>
          </Fade>
        </Modal>
      </WizardContainer>
      {isLoading && <LinearProgress />}
      <Scrollbars
        style={{
          height: 'calc(100vh - 300px)',
        }}
      >
        <Table bordered hover>
          <tbody>
            {jobs.map((job: any, index: number) => {
              return (
                <tr
                  key={job.job_id + index}
                  style={{
                    backgroundColor: job.job_id === jobState.activeJob.jobId ? 'rgba(0, 0, 0, 0.075)' : '#fff',
                  }}
                >
                  <TD>{job.name}</TD>
                  <TD>
                  <OverlayTrigger
                      overlay={<ReactTooltip place="bottom" effect="solid" />}
                    >
                   <PinDropIcon
                      data-tip="View on map"
                      onClick={() => {
                        gMap.setMapTypeId('roadmap');
                        const mapLayers = mapState.layers;
                        if (!mapLayers.includes('Parcel')) {
                          // mapLayers.push('Parcel');
                        }
                        if (mapState.mapOverLay) {
                          mapState.mapOverLay.setMap(null);
                          mapState.mapOverLay = null;
                        }
                        mapState.layers = [];
                        if (typeof job.job_json.geoJson !== 'undefined') {
                          // mapState.layers = mapLayers;
                          mapState.execute = true;
                          mapState.jobLayers = [];
                        } else {
                          // mapState.layers = [];
                          mapState.execute = false;
                          mapState.jobLayers = [];
                          gMap.setZoom(5);
                        }
                        dispatch(updateMap(mapState));
                        if (Object.keys(job.esadata).length > 0) {
                          setSubjectPropertyEsaData(job.esadata);
                        } else {
                          setSubjectProperty(job.job_json);
                        }

                        const activeJob: IActiveJob = job;
                        activeJob.keySiteManagers = job.job_json.keySiteManagers;
                        activeJob.users = job.job_json.users;
                        activeJob.gwGradient = job.job_json.gwGradient;
                        activeJob.jobJson = job.job_json.geoJson;
                        activeJob.facilityName = job.job_json.facilityName;
                        activeJob.jobNotes = job.job_json.jobNotes;
                        activeJob.status = job.job_json.status;
                        activeJob.numberOfDays = job.job_json.numberOfDays;
                        if (job.job_json.jobStartDate) {
                          activeJob.jobStartDate = Moment(job.job_json.jobStartDate, 'MM-DD-YYYY').toDate();
                        }
                        if (job.job_json.jobCompleteDate) {
                          activeJob.jobCompleteDate = Moment(job.job_json.jobCompleteDate, 'MM-DD-YYYY').toDate();
                        }
                        if (job.job_json.jobActualCompleteDate) {
                          activeJob.jobActualCompleteDate = Moment(
                            job.job_json.jobActualCompleteDate,
                            'MM-DD-YYYY'
                          ).toDate();
                        }
                        activeJob.jobId = job.job_id;
                        activeJob.jobType = job.job_type;
                        activeJob.execute = true;
                        const adjoiningProperties = job.job_json.adjoiningProperties;
                        dispatch(
                          updateJob({
                            ...jobState,
                            ...{
                              activeJob,
                              adjoiningProperties,
                              jobInit: true,
                              jobCreate: 'edit',
                              topoModal: false,
                              searchFinding: true,
                              jobFeatureTab: false,
                              esadataFindings: {},
                              mapFindingsTypes: 'Job Map Entities',
                            },
                          })
                        );
                        dispatch(
                          updateAIEarth({
                            ...aiEarthState,
                            ...{
                              activeTab: 'jobs',
                            },
                          })
                        );
                      }}
                      color="primary"
                    />
                  </OverlayTrigger>
                  <OverlayTrigger
                        overlay={<ReactTooltip place="bottom" effect="solid" />}
                      >
                      <DeleteIcon
                      data-tip="Delete"
                      onClick={() => {
                        deleteJob(job.job_id);
                        dispatch(
                          updateAIEarth({
                            ...aiEarthState,
                            ...{ mainDrawer: '' },
                          })
                        );
                        resetJobMap();
                      }}
                      color="secondary"
                    />
                  </OverlayTrigger>
                  </TD>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Scrollbars>
    </>
  );
};

export default Jobs;

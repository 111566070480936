import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

export const TableContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 40px;
`;
export const TD = styled.td`
  font-size: 16px;
  text-align: center;
`;
export const TH = styled.th`
  font-size: 16px;
  text-align: center;
`;
export const TableHeading = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0 20px;
`;
export const Rows = styled(Row)`
  border: 1px solid #000;
  margin: 0 !important;
`;
export const Cols = styled(Col)`
  border: 1px solid #000;
`;

// React and Hooks
import React, { useMemo, useEffect, useState } from 'react';

// Third-party libraries
import { debounce, IconButton } from '@material-ui/core';
import {
  Edit as EditIcon,
  Save as SaveIcon,
  Cached as CachedIcon
} from '@material-ui/icons';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

// UI Components
import { Form, InputGroup, Spinner } from 'react-bootstrap';
import styled from 'styled-components';

// Services and Utilities
import { saveChats } from '../../services/firebase';
import showToastMessage from '../../util/showToastMessage';

const StyledTooltip = styled(ReactTooltip)`
  max-width: 35% !important;
  &.__react_component_tooltip.place-top {
    left: 10px !important;

    &::before, &::after {
      left: 30px;
    }
  }
`;

interface IPropsType {
  savedChats: any;
  editableIndex: number;
  currentChat: number | null;
  totalSavedChats: number;
  showSpinner: string | null;
  setShowSpinner: (flagName: string) => void;
  setTotalSavedChats: (total: number) => void;
  toggleChat: (index: number) => void;
  setEditableIndex: (index: number | null) => void;
  setSavedChats: (chat: any[]) => void;
  getSavedChats: (params: any) => void;
}

const ChatTitleList = ({ savedChats, editableIndex, currentChat, totalSavedChats, setTotalSavedChats, showSpinner, setShowSpinner, setSavedChats, toggleChat, getSavedChats, setEditableIndex }: IPropsType) => {
  const [groupedSavedChat, setGroupedSavedChat] = useState<any[]>([]);
  const [loadMoreSpinner, setLoadMoreSpinner] = useState(false);
  const [searchChatTitle, setSearchChatTitle] = useState<string>('');

  useEffect(() => {
    toggleLoadMoreSpinner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalSavedChats, savedChats]);

  useEffect(() => {
    groupedChatByDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedChats]);

  // Function to handle debounced input change
  const handleDebouncedChange = useMemo(
    () =>
      debounce((value) => {
        scrollHandler('', value);
      }, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /** Toggles the load more spinner based on the number of saved chats */
  const toggleLoadMoreSpinner = () => {
    const allChatsLoaded = totalSavedChats === savedChats.length;
    setLoadMoreSpinner(!allChatsLoaded);
  };

  // Load more chats from the API and update the state
  async function loadMoreChats(searchTerm?: string) {
    try {
      const lastItemIndex = searchTerm ? undefined : savedChats.length;
      const moreData: any = await saveChats({
        type: 'getAll',
        lastItemIndex,
        searchTerm: searchTerm || searchChatTitle,
        pageSize: 15,
      });

      populateSavedChats(moreData, searchTerm);
    } catch (error) {
      if (error instanceof Error) {
        handleErrorMessage(error);
      }
    }
  }

  // Update the saved chats state with the new data
  function populateSavedChats(moreData: any, searchTerm?: string) {
    if (moreData.data.data.length > 0) {
      const savedChatData = moreData.data.data.map((item: any, i: number) => ({
        ...item,
        index: searchTerm ? i : i + savedChats.length,
      }));

      setSavedChats(searchTerm ? savedChatData : savedChats.concat(savedChatData));
      setTotalSavedChats(moreData.data.totalItems);
    }
  }

  // Group saved chat list by date
  const groupedChatByDate = () => {
    const groupedData = savedChats.reduce((acc: any, item: any) => {
      // Convert timestamp to Date object
      const timestamp = new Date(item.timestamp._seconds * 1000);

      // Extract day, month, and year
      const day = timestamp.toLocaleDateString('en-US', { day: 'numeric' });
      const month = timestamp.toLocaleDateString('en-US', { month: 'numeric' });
      const year = timestamp.toLocaleDateString('en-US', { year: 'numeric' });

      // Create a unique key for the day, month, and year
      const key = `${day}-${month}-${year}`;

      // Add the item to the corresponding day, month, and year group
      if (!acc[key]) {
        acc[key] = {
          dateTime: moment.unix(item.timestamp._seconds).format('DD/MM/YYYY'),
          timestamp: item.timestamp._seconds,
          data: []
        };
      }

      acc[key].data.push(item);
      return acc;
    }, {});

    const sortedGroupedData = Object.values(groupedData).sort((a: any, b: any) => b.timestamp - a.timestamp);

    setGroupedSavedChat(sortedGroupedData);
  };

  const saveQuestion = async (questionTitle: string) => {
    try {
      if (!questionTitle) {
        return;
      }

      const currentChatData = savedChats[editableIndex];

      const editedChatTitle = {
        mainTitle: questionTitle,
        chatData: currentChatData.payload.chatData || [],
      };

      savedChats[editableIndex].payload = editedChatTitle;

      setShowSpinner('edit_question');

      const response = await saveChats({
        type: 'update',
        id: currentChatData.id,
        record: editedChatTitle,
      });

      if (response) {
        showToastMessage({ type: 'success', actionType: 'update', title: 'Data updated successfully.', description: `Updated chat title: ${questionTitle}` });
      }

    } catch (error) {
      if (error instanceof Error) {
        handleErrorMessage(error);
      }
    } finally {
      setEditableIndex(null);
      setShowSpinner('');
    }
  };

  // Handle catch error
  function handleErrorMessage(error: Error) {
    const { name, message } = error;
    showToastMessage({ type: 'error', title: name, description: message });
  }

  /** Pagination scroll for saved chat title list */
  async function scrollHandler(e: any, searchTerm?: string) {

    if (searchTerm) {
      setSavedChats([]);
    }

    const chatListElement = document.getElementById('plist');
    if (!chatListElement) { return; }

    if (shouldLoadMoreChats(chatListElement, searchTerm)) {
      await loadMoreChats(searchTerm);
    }
  }

  // Function to handle input change with debounce
  const handleInputWithDebounce = (event: any) => {
    handleChange(event); // Update state immediately
    handleDebouncedChange(event.target.value); // Trigger debounce function
  };

  // Function to handle input change
  const handleChange = (event: any) => {
    setSearchChatTitle(event.target.value);
  };

  const SavedChatList = () => {
    const [updatedQuestion, setUpdatedQuestion] = useState<string>('');
    if (showSpinner && showSpinner === 'load_question_list') {
      return (
        <div className="d-flex justify-content-center m-2">
          <Spinner animation="border" variant="primary" />
        </div>
      );
    }

    return (
      <div>
        {groupedSavedChat.length > 0 && groupedSavedChat.map(item => (<>
          <p className="chat-datetime">{item.dateTime}</p>
          <ul className="list-unstyled chat-list mb-0">
            {item.data.map((question: any, i: number) => (
              <li
                key={i}
                className={`d-flex py-0 align-items-center list-group-item-action clearfix${currentChat === question.index ? ' active' : ''}`}
                onClick={() => toggleChat(question.index)}
              >
                <StyledTooltip id="chat-tooltip" place="top" effect="solid"/>
                <p
                  data-tip={question.payload.mainTitle}
                  data-for="chat-tooltip"
                  className={`my-2 w-100 ${editableIndex === question.index ? 'border border-3 rounded border-dark name' : 'clipped-name'}`}
                  contentEditable={editableIndex === question.index}
                  dangerouslySetInnerHTML={{ __html: question.payload.mainTitle }}
                  onInput={(e: any) => setUpdatedQuestion(e.currentTarget.textContent)}
                />
                {editableIndex !== null && editableIndex === question.index ? (
                    <div className="d-flex align-items-center">
                      {showSpinner && showSpinner === 'edit_question' && <Spinner animation="border" variant="primary" size="sm" className="m-2" />}
                       <IconButton data-tip="Save" data-for="chat-tooltip" onClick={() => saveQuestion(updatedQuestion)} aria-label="fav-icon" component="span">
                        <SaveIcon color="primary" />
                      </IconButton>
                    </div>
                  ) : (
                    <IconButton data-tip="Edit Title" data-for="chat-tooltip" onClick={() => setEditableIndex(question.index)} aria-label="edit-icon" component="span">
                      <EditIcon color="primary"/>
                    </IconButton>
                  )}
              </li>
            ))}
          </ul>
        </>))}
      </div>
    );
  };

  // Check if more chats should be loaded
  function shouldLoadMoreChats(chatListElement: HTMLElement, searchTerm?: string): boolean {
    const { scrollTop, scrollHeight, clientHeight } = chatListElement;
    const shouldLoadMore = scrollTop + 10 >= scrollHeight - clientHeight;
    return shouldLoadMore || searchTerm !== undefined;
  }

  return (
    <div>
      <div id="plist" className="people-list" onScroll={debounce(scrollHandler, 1000)}>
        <div className="saved-chat-btn">
          <button
                id="save-chat-btn"
                className="btn btn-outline-primary d-block mx-auto w-100"
                disabled={showSpinner === 'load_question_list'}
                onClick={() => getSavedChats({ type: 'getAll' })}
              >
                <CachedIcon /> Saved Chats
              </button>
          <InputGroup className="pt-3">
            <Form.Control
              className="rounded"
              placeholder="Search Chat..."
              name="savedChat"
              value={searchChatTitle}
              onChange={handleInputWithDebounce}
            />
          </InputGroup>
        </div>
        <SavedChatList />
        { loadMoreSpinner && (!showSpinner || showSpinner !== 'load_question_list') && (
          <div className="d-flex justify-content-center my-3 w-100">
            <Spinner animation="border" variant="secondary" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatTitleList;

import * as moment from 'moment';

import { ISearchRecord } from '../../types/search';

export interface IAIEarth {
  activeTab: string;
  mainDrawer: string;
  subDrawer: string;
  airListOptions: any;
  isLoading: boolean;
  data: any;
  isCollapse: boolean;
  favorites: any;
  favoritesLoaded: boolean;
}

export interface IMap {
  gMap: any;
  gMaps: any;
  subjectPropertyShowLayer: any;
  oilGasJobMaps: any;
  pipeLinesJobMaps: any;
  parcelSelectionLayer: any;
  adjoiningPropertyLayer: any;
  adjoiningPropertiesBoundaries: any;
  waterWellsPointer: any;
  monitorWellsPointer: any;
  soilsPropertyLayer: any;
  pipelineLayerRef: any;
  places: any;
  layers: any;
  jobLayers: any;
  execute: boolean;
  drawingManager: any;
  address: any;
  zip: any;
  state: any;
  city: any;
  mapOverLay: any;
  marker: any;
}

export interface IDatabase {
  fromReleaseDate: moment.Moment | null;
  toReleaseDate: moment.Moment | null;
  source_type: string;
  rnNumber: string;
  cityName: string;
  entityName: string;
  programId: string;
  program: string;
  zipCode: string;
  category: string;
  street: string;
  state: any;
  county: any;
  mapOptions: string;
  source: string;
  offset: number;
  limit: number;
  sortDirection: string;
  sortColumn: string;
  boundingBox: string;
  allState: {};
}

export interface IJob {
  panelType: string;
  wizardOpen: boolean;
  activeStep: number;
  jobInit: boolean;
  jobAction: boolean;
  jobCreate: string;
  jobJson: any;
  esaData: any;
  isLoadEsaData: boolean;
  adjoiningProperties: any;
  adjoiningPropJson: any;
  jobsData: any;
  activeJob: IActiveJob;
  topoImages: any;
  topoModal: boolean;
  jobFeature: any;
  jobFeatureTab: boolean;
  mapFindings: any;
  mapFindingTab: boolean;
  searchFinding: boolean;
  toolTipPoint: any;
  esadataFindings: any;
  mapFindingsTypes: string;
  adjoiningPropertiesBoundaries: any;
  soilSelect: any;
}

export interface IActiveJob {
  jobId: string;
  name: string;
  facilityName: string;
  address: string;
  city: string;
  state: string;
  status: string;
  zip: string;
  jobType: string;
  active: boolean;
  jobStartDate: Date;
  jobCompleteDate: Date | null;
  jobActualCompleteDate: Date | null;
  numberOfDays: number;
  jobNotes: string;
  jobJson: any;
  esadata?: any;
  userId: string;
  selectionType: string;
  gwGradient: string;
  keySiteManagers: any;
  users: any;
  corridorWidth: number;
  hasdatabasereport?: any;
  execute: boolean;
}

export interface IAir {
  activeRecord: ISearchRecord;
}

export interface ISearch {
  isSearch: boolean;
  type: string;
  customSearchType: string;
}

export const UPDATE_AISTORE = 'UPDATE_AISTORE';
export const UPDATE_JOB = 'UPDATE_JOB';
export const UPDATE_MAP = 'UPDATE_MAP';
export const UPDATE_DATABASE = 'UPDATE_DATABASE';
export const UPDATE_AIR = 'UPDATE_AIR';
export const UPDATE_SEARCH = 'UPDATE_SEARCH';
export const UPDATE_SAVED_CHATS = 'UPDATE_SAVED_CHATS';
export const UPDATE_GLOBAL_FLAGS = 'UPDATE_GLOBAL_FLAGS';
export const REMOVE_GLOBAL_FLAG = 'REMOVE_GLOBAL_FLAG';
export const UPDATE_QUICK_PROMPTS = 'UPDATE_QUICK_PROMPTS';
export const RESET_AISTORE = 'RESET_AISTORE';
export const RESET_JOB = 'RESET_JOB';
export const RESET_MAP = 'RESET_MAP';
export const RESET_DATABASE = 'RESET_DATABASE';
export const RESET_AIR = 'RESET_AIR';
export const RESET_SEARCH = 'RESET_SEARCH';

interface IUpdateAIEarth {
  type: typeof UPDATE_AISTORE;
  payload: IAIEarth;
}

interface IUpdateMapAction {
  type: typeof UPDATE_MAP;
  payload: IMap;
}

interface IUpdateJobAction {
  type: typeof UPDATE_JOB;
  payload: IJob;
}

interface IUpdateDatabaseAction {
  type: typeof UPDATE_DATABASE;
  payload: IDatabase;
}

interface IUpdateAir {
  type: typeof UPDATE_AIR;
  payload: IAir;
}

interface IUpdateSearch {
  type: typeof UPDATE_SEARCH;
  payload: ISearch;
}

interface ISavedChats {
  type: typeof UPDATE_SAVED_CHATS;
  payload: any;
}
interface IGlobalFlags {
  type: typeof UPDATE_GLOBAL_FLAGS;
  payload: any;
}
interface IQuickPrompts {
  type: typeof UPDATE_QUICK_PROMPTS;
  payload: any[];
}

export interface IAiEarthMapProps {
  gMap: any;
  gMaps: any;
  setSubjectPropertyEsaData?: any;
  setSelectOpen: any;
  selectJobOpen: any;
  setSelectJobOpen: any;
}

export interface IHistoricalArea {
  ne_lat: number;
  ne_lng: number;
  sw_lat: number;
  sw_lng: number;
}

export type AIEarthActionTypes = IUpdateAIEarth;
export type JobActionTypes = IUpdateJobAction;
export type MapActionTypes = IUpdateMapAction;
export type DatabaseActionTypes = IUpdateDatabaseAction;
export type AirActionTypes = IUpdateAir;
export type SearchActionTypes = IUpdateSearch;
export type SavedChatsActionTypes = ISavedChats;
export type GlobalFlagsActionTypes = IGlobalFlags;
export type QuickPromptsActionTypes = IQuickPrompts;

import * as moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Button, FormControl, InputGroup, Modal, Form } from 'react-bootstrap';
import DateTime from 'react-datetime';
import { Calendar, X } from 'react-feather';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import axios from 'axios';
import { CeresLogger } from '../../logger';
import makeAnimated from 'react-select/animated';
import Select, { components } from 'react-select';
import { API_BASE_URL, API_AUTH_HEADERS } from '../../util/api.util';

const AdvancedSearchFilterHeader = styled(Modal.Header)`
  && {
    padding: 1rem;
    flex-direction: row;
  }
`;

const AdvancedSearchFilterBody = styled(Modal.Body)``;

const FilterItem = styled.div`
  margin-bottom: 15px;
`;
const DateFilterItem = styled(FilterItem)`
  button {
    line-height: 20px;
  }
  .rdt {
    flex: 1;
  }
  .filter-calender-icon {
    width: 20px;
    height: 20px;
  }
`;

const SingleFilterClearButton = styled.span`
  position: absolute;
  right: 60px;
  top: 5px;
  bottom: 0;
  color: #6c757d;
  color: var(--secondary);
  cursor: pointer;
`;
const SourceType = styled(Select)`
  flex: 1 1 auto;
  font-size: 1rem;
  font-weight: 400;
`;

interface IAdvancedSearchFilterProps {
  show: boolean;
  onClose: () => void;
  onFilterApply: (filter: IAdvancedSearchFilter) => void;
  onFilterClear: () => void;
  disableFields?: boolean;
}

interface IAdvancedSearchFilter {
  fromReleaseDate: moment.Moment | null;
  toReleaseDate: moment.Moment | null;
  rnNumber: string;
  cityName: string;
  entityName: string;
  zipCode: string;
  category: string;
  recordSeries: string;
  recordTitles: string;
  showEmissionEvents: boolean;
  state: string;
}

interface IRecordSeriesInfo {
  pid: string;
  id: string;
  name: string;
}

interface IStateOption {
  value: string | number;
  label: string;
}

interface IDataEntry {
  source_type: string;
  state_code?: string;
  state_name: string;
}

interface IAdvancedSearchFilterState extends IAdvancedSearchFilter {}

const initialFilterState = {
  cityName: '',
  entityName: '',
  fromReleaseDate: null,
  toReleaseDate: null,
  rnNumber: '',
  zipCode: '',
  category: '',
  recordSeries: '',
  recordTitles: '',
  state: '',
  showEmissionEvents: false,
} as IAdvancedSearchFilterState;

const AdvancedSearchFilter: React.FC<IAdvancedSearchFilterProps> = (props) => {
  const [filters, setFilter] =
    useState<IAdvancedSearchFilterState>(initialFilterState);
  const [hasAnyFilter, setHasAnyFilter] = useState(false);
  const [showFromReleaseDate, setFromShowReleaseDate] = useState(false);
  const [showToReleaseDate, setToShowReleaseDate] = useState(false);
  const [recordSeries, setRecordSeries] = useState<any>([]);
  const [seriesRecord, setSeriesRecord] = useState<IRecordSeriesInfo[]>([]);
  const [titleRecord, setTitleRecord] = useState<IStateOption[]>([]);
  const [stateOptions, setStateOptions] = useState<IStateOption[]>([]);

  const hasAnyFilterApplied = (appliedfilters: IAdvancedSearchFilterState) => {
    const hasFilter = (Object.keys(appliedfilters) as Array<keyof IAdvancedSearchFilterState>)
      .some((key) => Boolean(appliedfilters[key]));
    if (!hasFilter) {
      setFilter(initialFilterState);
      props.onFilterClear();
    }
    return hasFilter;
  };

  const animatedComponents = makeAnimated();
  const IndicatorsContainer = (properties: any) => {
    return (
      <div style={{ background: '#005FBC' }}>
        <components.IndicatorsContainer {...properties} />
      </div>
    );
  };

  const constructStateOptions = (data: Record<string, IDataEntry>) => {
    const states: IStateOption[] = Object.keys(data).map((entry) => {
      return {
        value: data[entry].source_type,
        label: data[entry].state_code ? `${data[entry].state_name} (${data[entry].state_code})` : data[entry].state_name
      };
    });
    setStateOptions(states);
  };

  const getRecordSeries = () => {
    try {
      axios
        .post(
          API_BASE_URL + '/api/recordSeries',
          {
            headers: API_AUTH_HEADERS,
          }
        )
        .then((response: any) => {
          setRecordSeries(response.data[0].jsonlistb);
          constructStateOptions(response.data[0].jsonlistb);
        })
        .catch((err) => {
          CeresLogger.error(err);
        })
        .finally();
    } catch (e) {
      CeresLogger.error(e);
    }
  };
  useEffect(() => {
    getRecordSeries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const recordSeriesInfo = (val: string | number, newFilter: IAdvancedSearchFilter) => {
    const records = recordSeries.record_series;
    let jsonArray: IRecordSeriesInfo[] = [];
    if (records && val) {
      for (const info of records) {
        if (info.pid === val) {
          jsonArray = jsonArray.concat([info]);
          // if (jsonArray) {
          // } else {
          //   jsonArray = [info];
          // }
        }
      }
    } else {
    //   const newFilter = {
    //     ...filters,
    //     recordSeries: '',
    //     recordTitles: '',
    //   };
      setFilter(newFilter);
      setHasAnyFilter(hasAnyFilterApplied(newFilter));
    }
    setSeriesRecord(jsonArray);
    setTitleRecord([]);
  };

  const recordTitle = (val: string | number) => {
    const records = recordSeries.titles;
    let jsonArray: IStateOption[] = [];
    if (records && val) {
      for (const info of records) {
        if (info.pid === val) {
          jsonArray = jsonArray.concat([formattedSelectJson(info)]);
          // if (jsonArray) {
          // } else {
          //   jsonArray = [formattedSelectJson(info)];
          // }
        }
      }
    }
    setTitleRecord(jsonArray);
  };
  const formattedSelectJson = (data: IRecordSeriesInfo): IStateOption => {
    const json = {
      value: data.id,
      label: data.name,
    };
    return json;
  };
  const indicatorStyle = (base: any) => ({
    ...base,
    color: 'white',
    padding: '8px',
  });
  const styles = {
    clearIndicator: indicatorStyle,
    dropdownIndicator: indicatorStyle,
    menu: (provided: any) => ({ ...provided, zIndex: 99 }),
  };

  return (
    <Modal
      dialogClassName="ceres--advance-search-filter"
      centered
      show={props.show}
      onHide={props.onClose}
    >
      <AdvancedSearchFilterHeader closeButton>
        <Modal.Title>Advanced Search Filter</Modal.Title>
      </AdvancedSearchFilterHeader>

      <AdvancedSearchFilterBody>
        <FilterItem>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text id="rn-number-filter">Entity Number</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              onChange={(e: any) => {
                const newFilter = { ...filters, rnNumber: e.target.value };
                setFilter(newFilter);
                setHasAnyFilter(hasAnyFilterApplied(newFilter));
              }}
              value={filters.rnNumber}
              placeholder="Entity Number"
              aria-label="Entity Number"
              aria-describedby="rn-number-filter"
            />
          </InputGroup>
        </FilterItem>
        <FilterItem>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text id="entity-name-filter">
                Entity Name
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              onChange={(e: any) => {
                const newFilter = { ...filters, entityName: e.target.value };
                setFilter(newFilter);
                setHasAnyFilter(hasAnyFilterApplied(newFilter));
              }}
              value={filters.entityName}
              placeholder="Entity Name"
              aria-label="Entity Name"
              aria-describedby="entity-name-filter"
              disabled={props.disableFields}
            />
          </InputGroup>
        </FilterItem>
        <FilterItem>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text id="city-name-filter">City</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              onChange={(e: any) => {
                const newFilter = { ...filters, cityName: e.target.value };
                setFilter(newFilter);
                setHasAnyFilter(hasAnyFilterApplied(newFilter));
              }}
              value={filters.cityName}
              placeholder="City"
              aria-label="City"
              aria-describedby="city-name-filter"
            />
          </InputGroup>
        </FilterItem>
        <FilterItem>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text id="state-filter">State</InputGroup.Text>
            </InputGroup.Prepend>
            <SourceType
              styles={styles}
              onChange={(e: any) => {
                const newFilter = { ...filters, state: e };
                setFilter(newFilter);
                setHasAnyFilter(hasAnyFilterApplied(newFilter));
              }}
              value={filters.state}
              options={stateOptions || []}
            />
          </InputGroup>
        </FilterItem>
        <FilterItem>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text id="zip-code-filter">Zip Code</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              onChange={(e: any) => {
                const newFilter = { ...filters, zipCode: e.target.value };
                setFilter(newFilter);
                setHasAnyFilter(hasAnyFilterApplied(newFilter));
              }}
              value={filters.zipCode}
              placeholder="Zip Code"
              aria-label="Zip Code"
              aria-describedby="zip-code-filter"
            />
          </InputGroup>
        </FilterItem>
        <FilterItem>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text id="category-filter">Category</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              as="select"
              onChange={(e: any) => {
                const newFilter = { ...filters, category: e.target.value, recordSeries: '',
                recordTitles: '', };
                setFilter(newFilter);
                setHasAnyFilter(hasAnyFilterApplied(newFilter));
                recordSeriesInfo(
                  e.target[e.target.selectedIndex].getAttribute('data-id'),newFilter
                );
              }}
              value={filters.category}
              placeholder="Category"
              aria-label="Category"
              aria-describedby="category-filter"
              disabled={props.disableFields}
            >
              <option value="">Please Select</option>
              <option value="air" data-id="1001">
                Air
              </option>
              <option value="compliance" data-id="1002">
                Compliance
              </option>
              <option value="water ws wq" data-id="1003">
                Water
              </option>
              <option value="waste wst" data-id="1004">
                Waste
              </option>
              <option value="other" data-id="1005">
                Other
              </option>
              <option value="enforcement" data-id="1006">
                Enforcement
              </option>
            </FormControl>
          </InputGroup>
        </FilterItem>
        {seriesRecord.length > 0 && (
          <FilterItem>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="record-filter">
                  Record Series
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                as="select"
                onChange={(e: any) => {
                  const newFilter = {
                    ...filters,
                    recordSeries: e.target.value,
                    recordTitles: '',
                  };
                  setFilter(newFilter);
                  setHasAnyFilter(hasAnyFilterApplied(newFilter));
                  recordTitle(
                    e.target[e.target.selectedIndex].getAttribute('data-id')
                  );
                }}
                value={filters.recordSeries}
                placeholder="Record Series"
                aria-label="Record Series"
                aria-describedby="record-filter"
                disabled={props.disableFields}
              >
                <option value="">Please Select</option>
                {seriesRecord.map((record: any) => (
                  <option
                    value={record.name}
                    data-id={record.id}
                    key={record.id}
                  >
                    {record.name}
                  </option>
                ))}
              </FormControl>
            </InputGroup>
          </FilterItem>
        )}
        {titleRecord.length > 0 && (
          <FilterItem>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="title-filter">Titles</InputGroup.Text>
              </InputGroup.Prepend>
              <SourceType
                value={filters.recordTitles}
                components={{ IndicatorsContainer, animatedComponents }}
                closeMenuOnSelect={true}
                isMulti={false}
                styles={styles}
                options={titleRecord}
                onChange={(e: any) => {
                  const newFilter = {
                    ...filters,
                    recordTitles: e,
                  };
                  setFilter(newFilter);
                  setHasAnyFilter(hasAnyFilterApplied(newFilter));
                }}
              />
            </InputGroup>
          </FilterItem>
        )}
        <DateFilterItem>
          <InputGroup>
            <DateTime
              value={filters.fromReleaseDate || undefined}
              open={showFromReleaseDate}
              dateFormat={'DD/MM/YYYY'}
              timeFormat={false}
              closeOnSelect={true}
              onBlur={() => setFromShowReleaseDate(false)}
              onChange={(e) => {
                const newFilter = {
                  ...filters,
                  fromReleaseDate: e as moment.Moment,
                };
                setFilter(newFilter);
                return setHasAnyFilter(hasAnyFilterApplied(newFilter));
              }}
              inputProps={{
                placeholder: 'From Release Date',
                readOnly: true,
                disabled: props.disableFields,
              }}
            />
            {!!filters.fromReleaseDate && (
              <SingleFilterClearButton
                onClick={() => {
                  const newFilter = {
                    ...filters,
                    fromReleaseDate: null,
                  };
                  setFilter(newFilter);
                  return setHasAnyFilter(hasAnyFilterApplied(newFilter));
                }}
              >
                <X />
              </SingleFilterClearButton>
            )}
            <InputGroup.Append>
              <Button
                onClick={() => setFromShowReleaseDate(true)}
                variant="outline-secondary"
              >
                <Calendar className={'filter-calender-icon'} />
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </DateFilterItem>
        <DateFilterItem>
          <InputGroup>
            <DateTime
              value={filters.toReleaseDate || undefined}
              open={showToReleaseDate}
              dateFormat={'DD/MM/YYYY'}
              timeFormat={false}
              closeOnSelect={true}
              onBlur={() => setToShowReleaseDate(false)}
              onChange={(e) => {
                const newFilter = {
                  ...filters,
                  toReleaseDate: e as moment.Moment,
                };
                setFilter(newFilter);
                return setHasAnyFilter(hasAnyFilterApplied(newFilter));
              }}
              inputProps={{
                placeholder: 'To Release Date',
                readOnly: true,
                disabled: props.disableFields,
              }}
            />
            {!!filters.toReleaseDate && (
              <SingleFilterClearButton
                onClick={() => {
                  const newFilter = {
                    ...filters,
                    toReleaseDate: null,
                  };
                  setFilter(newFilter);
                  return setHasAnyFilter(hasAnyFilterApplied(newFilter));
                }}
              >
                <X />
              </SingleFilterClearButton>
            )}
            <InputGroup.Append>
              <Button
                onClick={() => setToShowReleaseDate(true)}
                variant="outline-secondary"
              >
                <Calendar className={'filter-calender-icon'} />
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </DateFilterItem>
        <FilterItem>
          <InputGroup>
            <Form.Check
              type="checkbox"
              label="Show Emission Events"
              name="emissionEvents"
              checked={filters.showEmissionEvents}
              onChange={(e: any) => {
                const newFilter = {
                  ...filters,
                  showEmissionEvents: e.target.checked,
                };
                setFilter(newFilter);
                setHasAnyFilter(hasAnyFilterApplied(newFilter));
              }}
            />
          </InputGroup>
        </FilterItem>
      </AdvancedSearchFilterBody>

      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            toast.info('Cleared advanced search filters.');
            setFilter(initialFilterState);
            setHasAnyFilter(false);
            return props.onFilterClear();
          }}
        >
          Clear Filter
        </Button>
        <Button
          disabled={!hasAnyFilter}
          variant="primary"
          onClick={() => {
            toast.info('Applied advanced search filters.');
            props.onFilterApply(filters);
          }}
        >
          Apply Filter
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AdvancedSearchFilter;

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import AnnualAmissionChart from './AnnualAmissionChart';
import { ResultContainer } from '../custom-styled-components/CommonStyledComponents';
import { IEmissions } from '../../types/emissionEvent';
import { Cols, Rows, TableContainer, TableHeading, TD, TH } from '../custom-styled-components/AirEmissionStyledComponents';
import { getEmissionOptions, tableHeadings } from '../../util/airemission.util';

const AirEmissions: React.FC<IEmissions> = (props) => {
  const statsResult = props.result.Graphs;
  const tableData = props.result.Tables.facility_pollutant;
  const entity = props.entity;

  const emissionOptions = getEmissionOptions(statsResult);

  return (
    <ResultContainer>
      <TableContainer>
        <Row>
            <Col md={12}>
              <TableHeading>
                <h4>Facility Pollutant Ranking - Annual Emissions</h4>
              </TableHeading>
              <Table bordered hover>
                <thead>
                  <tr>
                  {tableHeadings.map((heading, index) => (
                    <TH key={index}>{heading.header}</TH>
                  ))}
                  </tr>
                </thead>
                <tbody>
                {tableData.data.map((ele: any, key: string) => {
                  return (
                    <tr key={'Air' + key} className={ele.amount ? '' : 'hide'}>
                      {tableHeadings.map((heading, index) => (
                        <TD key={index}>
                        {entity.value === ele.rn ? (
                          <b>
                              {/* Use the 'key' field to access the correct value */}
                              {heading.key === 'amount'
                                ? ele[heading.key].toLocaleString(navigator.language, { minimumFractionDigits: 0 })
                                : ele[heading.key]}
                          </b>
                        ) : (
                            heading.key === 'amount'
                              ? ele[heading.key].toLocaleString(navigator.language, { minimumFractionDigits: 0 })
                              : ele[heading.key]
                          )}
                      </TD>
                      ))}
                    </tr>
                  );
                })}
                </tbody>
              </Table>
            </Col>
        </Row>
      </TableContainer>
      <Rows>
      {Object.keys(emissionOptions).map((key) => (
          <Cols md={6} key={key}>
            <AnnualAmissionChart options={emissionOptions[key]} />
          </Cols>
        ))}
      </Rows>
    </ResultContainer>
  );
};

export default AirEmissions;

import React, { useState, useEffect } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import { toast } from 'react-toastify';

import { CeresLogger } from '../../logger';
import { ICompetitorData, ICompetitorPopupProps } from '../../types/competitor-selection';
import { API_BASE_URL, API_AUTH_HEADERS } from '../../util/api.util';

const CompetitorPopup: React.FC<ICompetitorPopupProps> = ({ isOpen, onClose, onResult, selectedLabel }) => {
  const [selectedCompetitors, setSelectedCompetitors] = useState<ICompetitorData[]>([]);
  const [competitorOptions, setCompetitorOptions] = useState<ICompetitorData[]>([]);
  const [isCompetitorOptionsLoading, setCompetitorOptionsIsLoading] = useState(true);
  const [cachedCompetitorRecords, setCachedCompetitorRecords] = useState<ICompetitorData[]>([]);
  const [isAddButtonLoading, setIsAddButtonLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      getCorporates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const getCorporates = async () => {
    if (cachedCompetitorRecords.length > 0) {
      setCompetitorOptions(cachedCompetitorRecords);
      setCompetitorOptionsIsLoading(false);
      return;
    }

    try {
      const { data } = await axios.get(
        `${API_BASE_URL}/api/eventEmissions/corporates`,
        {
          headers: API_AUTH_HEADERS,
        }
      );
      const jsonlistb = data[0].jsonlistb || [];
      setCompetitorOptions(jsonlistb);
      setCachedCompetitorRecords(jsonlistb);
      if (selectedLabel) {
        const preselectedCompetitors = jsonlistb.filter(
          (competitor: ICompetitorData) => competitor.label === selectedLabel
        );
        setSelectedCompetitors(preselectedCompetitors);
      }
    } catch (err) {
      CeresLogger.error(err);
    } finally {
      setCompetitorOptionsIsLoading(false);
    }
  };

  const handleAdd = async () => {
    setIsAddButtonLoading(true);
    try {
      const responses = await Promise.all(
        selectedCompetitors.map(async (competitor) => {
          try {
            const rnDetailsUrl = `${API_BASE_URL}/BenchmarkingEnforcement?action=rn_details&rn=${competitor.value}`;
            const rnResponse = await axios.get(rnDetailsUrl);
            if (rnResponse && rnResponse.data && rnResponse.data.data && rnResponse.data.data.length > 0) {
              const cn = rnResponse.data.data[0]['CN Number'];
              if (!cn) { return null; }

              const cnDetailsUrl = `${API_BASE_URL}/BenchmarkingEnforcement?action=cn_details&rn=${competitor.value}&cn=${cn}`;
              const cnResponse = await axios.get(cnDetailsUrl);

              if (cnResponse && cnResponse.data) {
                return {
                  rn: competitor.value,
                  cn,
                  response: cnResponse.data,
                };
              }
            }
            return null;
          } catch (err) {
            CeresLogger.error(err);
            return null; // Continue processing other competitors even if one fails
          }
        })
      );

      onResult(responses.filter((res) => res !== null)); // Remove null results before sending
    } catch (error) {
      CeresLogger.error(error);
    } finally {
      setIsAddButtonLoading(false);
      onClose();
    }
  };

  const getUrlDetails = async (newValue: ICompetitorData[]) => {
    try {
      setIsAddButtonLoading(true);

      const responses = await Promise.all(
        newValue.map(async (competitor) => {
          try {
            const rnDetailsUrl = `${API_BASE_URL}/BenchmarkingEnforcement?action=rn_details&rn=${competitor.value}`;
            const rnResponse = await axios.get(rnDetailsUrl);

            if (rnResponse && rnResponse.data && rnResponse.data.error) {
              toast.error(`No records found for ${competitor.value}`);

              // Remove the competitor from the selected list
              setSelectedCompetitors((prev) =>
                prev.filter((item) => item.value !== competitor.value)
              );

              return null; // Skip adding this response to results
            }

            return rnResponse.data; // Return valid response
          } catch (err) {
            CeresLogger.error(err);
            return null; // Skip failed requests
          }
        })
      );

      // Filter out null responses before sending results
      const validResponses = responses.filter((response) => response !== null);
      if (validResponses.length > 0) {
        onResult(validResponses); // Send valid responses to the parent
      }
    } catch (error) {
      CeresLogger.error(error);
    } finally {
      setIsAddButtonLoading(false);
    }
  };
  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm" PaperProps={{
      style: {
        overflowY: 'visible', // Apply custom style here
      },
    }}
    >
      <DialogTitle>Select Competitors</DialogTitle>
      <DialogContent>
        {isCompetitorOptionsLoading ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <Autocomplete
            multiple
            disablePortal
            options={competitorOptions}
            getOptionLabel={(option) => option.label}
            value={selectedCompetitors}
            onChange={(event, newValue) => {
              getUrlDetails(newValue);
              setSelectedCompetitors(newValue);
            }}
            renderInput={(params) => <TextField {...params} label="Select Competitors" />}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleAdd}
          color="primary"
          disabled={selectedCompetitors.length === 0 || isCompetitorOptionsLoading}
        >
          {isAddButtonLoading ? (
            <CircularProgress size={24} />
          ) : (
            'Add'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CompetitorPopup;

export const tableColumns = [
    { key: 'rank', heading: 'Rank' },
    { key: 'facility', heading: 'Facility' },
    { key: 'events', heading: 'Events' },
];

export const tableEmissionColumns = [
    { key: 'rank', heading: 'Rank' },
    { key: 'facility', heading: 'Facility' },
    { key: 'pounds', heading: 'Pounds' },
];
